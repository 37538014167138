// src/components/StickyNotesBoard.jsx

import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Box,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Tooltip,
  Fade,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
  Chip,
  Card,
  CardContent,
  CardActions,
  Button as MUIButton,
  IconButton as MUIIconButton,
  Collapse,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import FlagIcon from "@mui/icons-material/Flag";
import EventIcon from "@mui/icons-material/Event";
import MicIcon from "@mui/icons-material/Mic";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ImageIcon from "@mui/icons-material/Image";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArchiveIcon from "@mui/icons-material/Archive";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RestoreIcon from "@mui/icons-material/Restore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { motion } from "framer-motion";

// Firebase imports
import { db, storage, auth } from "../firebase"; // Adjust the path as needed
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  where,
  Timestamp,
  orderBy,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Updated color palette with neon colors
const colorPalette = {
  dark1: "#222831",
  dark2: "#393E46",
  primary: "#00ADB5",
  light: "#EEEEEE",
  neonPink: "#FF6EC7",
  neonGreen: "#39FF14",
  neonBlue: "#00FFFF",
  neonRed: "#FF073A",
  neonViolet: "#9400D3",
  neonYellow: "#FFFF00",
};

// Define priority levels with colors
const priorities = [
  { value: "low", label: "Low", color: "green" },
  { value: "medium", label: "Medium", color: "orange" },
  { value: "high", label: "High", color: "red" },
];

// Define supported languages for transcription
const languages = [
  { code: "en-US", label: "English" },
  { code: "ar-SA", label: "Arabic" },
  { code: "es-ES", label: "Spanish" },
  { code: "fr-FR", label: "French" },
];

// Neon color options for note customization
const predefinedNeonColors = [
  colorPalette.neonPink,
  colorPalette.neonGreen,
  colorPalette.neonBlue,
  colorPalette.neonRed,
  colorPalette.neonViolet,
  colorPalette.neonYellow,
  colorPalette.primary,
  colorPalette.light,
];

// Icon mapping for categories
const iconMapping = {
  MoreHoriz: MoreHorizIcon,
  // Add more mappings as needed
};

// Helper function to extract file name from download URL
const extractFileNameFromURL = (url) => {
  try {
    const urlObj = new URL(url);
    const decodedPath = decodeURIComponent(urlObj.pathname);
    const segments = decodedPath.split("/");
    return segments[segments.length - 1];
  } catch (error) {
    console.error("Invalid URL:", url);
    return "Unknown File";
  }
};

// Sub-component representing an individual sticky note
const StickyNote = ({
  note,
  onView,
  onEdit,
  onDelete,
  onMarkDone,
  onArchive,
  onRestore,
  categoriesList,
}) => {
  // Determine the category
  const category = categoriesList.find((cat) => cat.id === note.category) || {
    name: "Other",
    color: colorPalette.dark2,
  };

  // Priority color
  const priorityColor =
    priorities.find((p) => p.value === note.priority)?.color || "gray";

  // Text color
  const textColor = note.textColor || "#000000";

  // Visual indication for done and archived notes
  const isDone = note.status === "done";
  const isArchived = note.status === "archived";

  return (
    <motion.div whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}>
      <Card
        elevation={3}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          cursor: isArchived ? "default" : "pointer",
          position: "relative",
          overflow: "hidden",
          backgroundColor: note.customColor || "#FFFFFF",
          color: textColor,
          opacity: isArchived ? 0.6 : 1,
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            backgroundColor: category.color,
          },
        }}
        onClick={!isArchived ? onView : null}
      >
        <CardContent sx={{ flexGrow: 1, pb: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Box
              sx={{
                backgroundColor: category.color,
                borderRadius: "4px",
                padding: "4px 8px",
                color: textColor,
                mr: 1,
              }}
            >
              <Typography variant="caption">{category.name}</Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  color: textColor,
                  textDecoration: isDone ? "line-through" : "none",
                }}
              >
                {note.title}
              </Typography>
            </Box>
          </Box>

          <Typography
            variant="body2"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              mb: 1,
              fontSize: "0.9rem",
              color: textColor,
            }}
          >
            {note.description}
          </Typography>
          {note.dueDate && (
            <Typography
              variant="caption"
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 0.5,
                color: textColor,
              }}
            >
              <EventIcon fontSize="small" sx={{ mr: 0.5 }} />
              Due: {note.dueDate.toDate().toLocaleDateString()}
            </Typography>
          )}
          {note.reminderDate && (
            <Typography
              variant="caption"
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 0.5,
                color: textColor,
              }}
            >
              <NotificationsActiveIcon fontSize="small" sx={{ mr: 0.5 }} />
              Reminder:{" "}
              {typeof note.reminderDate.toDate === "function"
                ? note.reminderDate.toDate().toLocaleString()
                : "Invalid Date"}
            </Typography>
          )}
          {note.audioUrl && (
            <Box sx={{ mt: 1 }}>
              <Typography
                variant="caption"
                sx={{
                  fontWeight: "bold",
                  color: colorPalette.primary,
                  display: "flex",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <MicIcon fontSize="small" sx={{ mr: 0.5 }} />
                Voice Note
              </Typography>
              <audio
                controls
                src={note.audioUrl}
                style={{
                  width: "100%",
                }}
              />
            </Box>
          )}
          {note.transcription && (
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                fontSize: "0.8rem",
                direction: /[\u0600-\u06FF]/.test(note.transcription)
                  ? "rtl"
                  : "ltr",
                color: textColor,
              }}
            >
              Transcription:{" "}
              {note.transcription.length > 50
                ? note.transcription.substring(0, 50) + "..."
                : note.transcription}
            </Typography>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: "space-between", pt: 0 }}>
          <Box>
            {note.image && (
              <ImageIcon fontSize="small" sx={{ mr: 0.5, color: textColor }} />
            )}
            {note.file && (
              <AttachFileIcon
                fontSize="small"
                sx={{ mr: 0.5, color: textColor }}
              />
            )}
          </Box>
          <Box>
            {isArchived && (
              <Tooltip
                title="Restore"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
              >
                <MUIIconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onRestore(note);
                  }}
                  aria-label="Restore Note"
                  size="small"
                >
                  <RestoreIcon fontSize="small" />
                </MUIIconButton>
              </Tooltip>
            )}

            {!isDone && !isArchived && (
              <Tooltip
                title="Mark as Done"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
              >
                <MUIIconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onMarkDone(note);
                  }}
                  aria-label="Mark as Done"
                  size="small"
                >
                  <CheckCircleIcon fontSize="small" />
                </MUIIconButton>
              </Tooltip>
            )}

            {!isArchived && (
              <Tooltip
                title="Archive"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
              >
                <MUIIconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onArchive(note);
                  }}
                  aria-label="Archive Note"
                  size="small"
                >
                  <ArchiveIcon fontSize="small" />
                </MUIIconButton>
              </Tooltip>
            )}

            {!isArchived && (
              <Tooltip
                title="Edit"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
              >
                <MUIIconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit(note);
                  }}
                  aria-label="Edit Note"
                  size="small"
                >
                  <EditIcon fontSize="small" />
                </MUIIconButton>
              </Tooltip>
            )}

            {!isArchived && (
              <Tooltip
                title="Delete"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
              >
                <MUIIconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(note);
                  }}
                  aria-label="Delete Note"
                  size="small"
                >
                  <DeleteIcon fontSize="small" />
                </MUIIconButton>
              </Tooltip>
            )}
          </Box>
        </CardActions>
      </Card>
    </motion.div>
  );
};

// Main component for Sticky Notes Board
const StickyNotesBoard = ({ projectId }) => {
  const [activeNotes, setActiveNotes] = useState([]);
  const [archivedNotes, setArchivedNotes] = useState([]);
  const [isArchivedOpen, setIsArchivedOpen] = useState(false);
  const [open, setOpen] = useState(false); // For add/edit dialog
  const [viewOpen, setViewOpen] = useState(false); // For view dialog
  const [noteTitle, setNoteTitle] = useState("");
  const [noteDescription, setNoteDescription] = useState("");
  const [noteCategory, setNoteCategory] = useState("");
  const [noteCategoryCustom, setNoteCategoryCustom] = useState("");
  const [notePriority, setNotePriority] = useState("medium");
  const [noteDueDate, setNoteDueDate] = useState("");
  const [noteReminderDate, setNoteReminderDate] = useState("");
  const [noteImageUrl, setNoteImageUrl] = useState(null);
  const [noteFileUrl, setNoteFileUrl] = useState(null);
  const [editingNote, setEditingNote] = useState(null);
  const [viewingNote, setViewingNote] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [transcription, setTranscription] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("en-US");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [noteColor, setNoteColor] = useState("#FFFFFF"); // Default color
  const [textColor, setTextColor] = useState("#000000"); // Default text color
  const [customCategory, setCustomCategory] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const recognitionRef = useRef(null);

  const user = auth.currentUser;

  // References to user's sticky notes collections under the specific project
  const activeNotesQuery = user
    ? query(
        collection(db, "users", user.uid, "projects", projectId, "stickyNotes"),
        where("status", "!=", "archived"),
        orderBy("date", "asc"),
      )
    : null;

  const archivedNotesQuery = user
    ? query(
        collection(db, "users", user.uid, "projects", projectId, "stickyNotes"),
        where("status", "==", "archived"),
        orderBy("date", "asc"),
      )
    : null;

  const categoriesQuery = user
    ? query(
        collection(db, "users", user.uid, "projects", projectId, "categories"),
      )
    : null;

  useEffect(() => {
    if (!user || !projectId) return;

    const unsubscribeActive = onSnapshot(activeNotesQuery, (snapshot) => {
      const fetchedActiveNotes = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setActiveNotes(fetchedActiveNotes);
    });

    const unsubscribeArchived = onSnapshot(archivedNotesQuery, (snapshot) => {
      const fetchedArchivedNotes = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setArchivedNotes(fetchedArchivedNotes);
    });

    const unsubscribeCategories = onSnapshot(categoriesQuery, (snapshot) => {
      const fetchedCategories = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategoriesList(fetchedCategories);
    });

    return () => {
      unsubscribeActive();
      unsubscribeArchived();
      unsubscribeCategories();
    };
  }, [user, projectId, activeNotesQuery, archivedNotesQuery, categoriesQuery]);

  useEffect(() => {
    const checkReminders = () => {
      const now = new Date();
      activeNotes.forEach((note) => {
        if (
          note.reminderDate &&
          typeof note.reminderDate.toDate === "function" &&
          note.reminderDate.toDate() <= now &&
          note.reminderDate.toDate() > new Date(now - 60000) &&
          note.status !== "archived"
        ) {
          setSnackbarMessage(`Reminder: ${note.title}\n${note.description}`);
          setSnackbarOpen(true);
          if (Notification.permission === "granted") {
            const notification = new Notification(
              `Reminder for "${note.title}"`,
              {
                body: note.description,
                icon: "/icon.png", // Ensure this path is correct
                requireInteraction: true,
              },
            );
            notification.onclick = () => {
              setViewingNote(note);
              setViewOpen(true);
            };
          }
        }
      });
    };

    const requestNotificationPermission = () => {
      if ("Notification" in window && Notification.permission !== "granted") {
        Notification.requestPermission();
      }
    };

    requestNotificationPermission();
    const intervalId = setInterval(checkReminders, 60000); // Check every minute

    return () => clearInterval(intervalId);
  }, [activeNotes]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        setAudioBlob(audioBlob);
        stream.getTracks().forEach((track) => track.stop());
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);

      // Initialize Speech Recognition
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      if (!SpeechRecognition) {
        setSnackbarMessage("Speech Recognition not supported in this browser.");
        setSnackbarOpen(true);
        return;
      }

      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;
      recognitionRef.current.lang = selectedLanguage;

      recognitionRef.current.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0].transcript)
          .join("");
        setTranscription(transcript);
      };

      recognitionRef.current.onend = () => {
        if (isRecording) {
          recognitionRef.current.start();
        }
      };

      recognitionRef.current.start();
    } catch (error) {
      console.error("Error accessing microphone:", error);
      setSnackbarMessage(
        "Error accessing microphone. Please check your permissions.",
      );
      setSnackbarOpen(true);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
    }
  };

  const handleSaveNote = async () => {
    if (isRecording) {
      stopRecording();
    }

    try {
      let imageUrl = noteImageUrl;
      let fileUrl = noteFileUrl;
      let uploadedAudioUrl = null;

      if (audioBlob) {
        const audioRef = ref(
          storage,
          `users/${user.uid}/projects/${projectId}/audio/${Date.now()}.wav`,
        );
        await uploadBytes(audioRef, audioBlob, {
          contentType: "audio/wav",
        });
        uploadedAudioUrl = await getDownloadURL(audioRef);
      }

      if (editingNote) {
        const noteDocRef = doc(
          db,
          "users",
          user.uid,
          "projects",
          projectId,
          "stickyNotes",
          editingNote.id,
        );
        await updateDoc(noteDocRef, {
          title: noteTitle,
          description: noteDescription,
          category: noteCategory,
          priority: notePriority,
          dueDate: noteDueDate
            ? Timestamp.fromDate(new Date(noteDueDate))
            : null,
          reminderDate: noteReminderDate
            ? Timestamp.fromDate(new Date(noteReminderDate))
            : null,
          customColor: noteColor,
          textColor: textColor,
          customCategory: noteCategoryCustom ? noteCategoryCustom : null,
          date: Timestamp.now(),
          image: imageUrl || editingNote.image,
          file: fileUrl || editingNote.file,
          audioUrl: uploadedAudioUrl || editingNote.audioUrl,
          transcription: transcription,
        });
      } else {
        const newNote = {
          title: noteTitle,
          description: noteDescription,
          category: noteCategory,
          priority: notePriority,
          dueDate: noteDueDate
            ? Timestamp.fromDate(new Date(noteDueDate))
            : null,
          reminderDate: noteReminderDate
            ? Timestamp.fromDate(new Date(noteReminderDate))
            : null,
          customColor: noteColor,
          textColor: textColor,
          customCategory: noteCategoryCustom ? noteCategoryCustom : null,
          date: Timestamp.now(),
          image: imageUrl || null,
          file: fileUrl || null,
          audioUrl: uploadedAudioUrl || null,
          transcription: transcription,
          status: "active", // Default status
        };
        await addDoc(
          collection(
            db,
            "users",
            user.uid,
            "projects",
            projectId,
            "stickyNotes",
          ),
          newNote,
        );
      }

      handleCloseDialog();
      setSnackbarMessage("Note saved successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage(
        "An error occurred while saving the note. Please check the console for details.",
      );
      setSnackbarOpen(true);
      console.error("Error saving note:", error);
    }
  };

  const handleCloseDialog = () => {
    if (isRecording) {
      stopRecording();
    }
    setOpen(false);
    setEditingNote(null);
    setNoteTitle("");
    setNoteDescription("");
    setNoteCategory("");
    setNotePriority("medium");
    setNoteDueDate("");
    setNoteReminderDate("");
    setNoteImageUrl(null);
    setNoteFileUrl(null);
    setAudioBlob(null);
    setTranscription("");
    setNoteColor("#FFFFFF");
    setTextColor("#000000");
    setCustomCategory("");
  };

  const handleEditNote = (note) => {
    setEditingNote(note);
    setNoteTitle(note.title);
    setNoteDescription(note.description);
    setNoteCategory(note.category);
    setNotePriority(note.priority);
    setNoteDueDate(
      note.dueDate ? note.dueDate.toDate().toISOString().substring(0, 10) : "",
    );
    setNoteReminderDate(
      note.reminderDate
        ? note.reminderDate.toDate().toISOString().substring(0, 16)
        : "",
    );
    setNoteImageUrl(note.image || null);
    setNoteFileUrl(note.file || null);
    setAudioBlob(null);
    setTranscription(note.transcription || "");
    setNoteColor(note.customColor || "#FFFFFF");
    setTextColor(note.textColor || "#000000");
    setNoteCategoryCustom(note.customCategory || "");
    setOpen(true);
  };

  const handleViewNote = (note) => {
    setViewingNote(note);
    setViewOpen(true);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file && user && projectId) {
      const sanitizedFileName = file.name.replace(/[/\\?%*:|"<>]/g, "-");
      const imageRef = ref(
        storage,
        `users/${user.uid}/projects/${projectId}/images/${sanitizedFileName}`,
      );
      try {
        await uploadBytes(imageRef, file, {
          contentType: file.type,
        });
        const imageUrl = await getDownloadURL(imageRef);
        setNoteImageUrl(imageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
        setSnackbarMessage("Error uploading image.");
        setSnackbarOpen(true);
      }
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file && user && projectId) {
      const sanitizedFileName = file.name.replace(/[/\\?%*:|"<>]/g, "-");
      const fileRef = ref(
        storage,
        `users/${user.uid}/projects/${projectId}/files/${sanitizedFileName}`,
      );
      try {
        await uploadBytes(fileRef, file, {
          contentType: file.type,
        });
        const fileUrl = await getDownloadURL(fileRef);
        setNoteFileUrl(fileUrl);
      } catch (error) {
        console.error("Error uploading file:", error);
        setSnackbarMessage("Error uploading file.");
        setSnackbarOpen(true);
      }
    }
  };

  const handleDelete = (note) => {
    setNoteToDelete(note);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (noteToDelete) {
      try {
        const noteDocRef = doc(
          db,
          "users",
          user.uid,
          "projects",
          projectId,
          "stickyNotes",
          noteToDelete.id,
        );
        await deleteDoc(noteDocRef);
        setSnackbarMessage(`Deleted: ${noteToDelete.title}`);
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage("An error occurred while deleting the note.");
        setSnackbarOpen(true);
        console.error("Error deleting note:", error);
      }
    }
    setConfirmDeleteOpen(false);
    setNoteToDelete(null);
  };

  const handleCancelDelete = () => {
    setConfirmDeleteOpen(false);
    setNoteToDelete(null);
  };

  const handleMarkDone = async (note) => {
    try {
      const noteDocRef = doc(
        db,
        "users",
        user.uid,
        "projects",
        projectId,
        "stickyNotes",
        note.id,
      );
      await updateDoc(noteDocRef, {
        status: "done",
      });
      setSnackbarMessage(`Marked "${note.title}" as done.`);
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("An error occurred while marking the note as done.");
      setSnackbarOpen(true);
      console.error("Error marking note as done:", error);
    }
  };

  const handleArchive = async (note) => {
    try {
      const noteDocRef = doc(
        db,
        "users",
        user.uid,
        "projects",
        projectId,
        "stickyNotes",
        note.id,
      );
      await updateDoc(noteDocRef, {
        status: "archived",
      });
      setSnackbarMessage(`Archived "${note.title}".`);
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("An error occurred while archiving the note.");
      setSnackbarOpen(true);
      console.error("Error archiving note:", error);
    }
  };

  const handleRestore = async (note) => {
    try {
      const noteDocRef = doc(
        db,
        "users",
        user.uid,
        "projects",
        projectId,
        "stickyNotes",
        note.id,
      );
      await updateDoc(noteDocRef, {
        status: "active",
      });
      setSnackbarMessage(`Restored "${note.title}" to active notes.`);
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("An error occurred while restoring the note.");
      setSnackbarOpen(true);
      console.error("Error restoring note:", error);
    }
  };

  // Handle adding a new custom category
  const handleAddCustomCategory = async () => {
    if (customCategory.trim() === "") return;
    try {
      // Check if category already exists
      const existingCategory = categoriesList.find(
        (cat) => cat.name.toLowerCase() === customCategory.trim().toLowerCase(),
      );
      if (existingCategory) {
        setSnackbarMessage("Category already exists.");
        setSnackbarOpen(true);
        return;
      }

      const newCategoryRef = await addDoc(
        collection(db, "users", user.uid, "projects", projectId, "categories"),
        {
          name: customCategory.trim(),
          icon: "MoreHoriz", // Store as string
          color: colorPalette.dark2, // Default color
        },
      );
      setCustomCategory("");
      setNoteCategory(newCategoryRef.id); // Select the new category automatically
      setSnackbarMessage("Custom category added successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("An error occurred while adding the custom category.");
      setSnackbarOpen(true);
      console.error("Error adding custom category:", error);
    }
  };

  // Filter notes based on search term (active and archived)
  const filteredActiveNotes = useMemo(() => {
    return activeNotes.filter(
      (note) =>
        note.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        note.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        categoriesList
          .find((cat) => cat.id === note.category)
          ?.name.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        false,
    );
  }, [activeNotes, searchTerm, categoriesList]);

  const filteredArchivedNotes = useMemo(() => {
    return archivedNotes.filter(
      (note) =>
        note.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        note.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        categoriesList
          .find((cat) => cat.id === note.category)
          ?.name.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        false,
    );
  }, [archivedNotes, searchTerm, categoriesList]);

  return (
    <Paper
      sx={{
        padding: 3,
        borderRadius: "12px",
        boxShadow: 3,
        backgroundColor: "#FFFFFF",
      }}
    >
      {/* Header with title, search bar, and add button */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", mr: 2, color: "#000000" }}
        >
          Sticky Notes
        </Typography>
        <TextField
          size="small"
          placeholder="Search Notes..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <SearchIcon sx={{ color: "action.active", mr: 1 }} />
            ),
          }}
          sx={{
            width: 200,
            mr: 2,
            backgroundColor: "#FFFFFF",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colorPalette.dark2,
            },
            color: "#000000",
          }}
        />
        <Box ml="auto">
          <MUIButton
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
            startIcon={<AddIcon />}
            sx={{ textTransform: "none" }}
          >
            Add Note
          </MUIButton>
        </Box>
      </Box>

      {/* Grid of Active Sticky Notes */}
      <Grid container spacing={3}>
        {filteredActiveNotes.map((note) => (
          <Grid item xs={12} sm={6} md={4} key={note.id}>
            <StickyNote
              note={note}
              onView={() => handleViewNote(note)}
              onEdit={handleEditNote}
              onDelete={() => handleDelete(note)}
              onMarkDone={handleMarkDone}
              onArchive={handleArchive}
              onRestore={handleRestore}
              categoriesList={categoriesList}
            />
          </Grid>
        ))}
      </Grid>

      {/* Collapsible Archived Notes Section */}
      <Box sx={{ mt: 4 }}>
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => setIsArchivedOpen(!isArchivedOpen)}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", mr: 1 }}>
            Archived Notes
          </Typography>
          {isArchivedOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
        <Collapse in={isArchivedOpen} timeout="auto" unmountOnExit>
          {filteredArchivedNotes.length > 0 ? (
            <Grid container spacing={3} sx={{ mt: 1 }}>
              {filteredArchivedNotes.map((note) => (
                <Grid item xs={12} sm={6} md={4} key={note.id}>
                  <StickyNote
                    note={note}
                    onView={() => handleViewNote(note)}
                    onEdit={handleEditNote}
                    onDelete={() => handleDelete(note)}
                    onMarkDone={handleMarkDone}
                    onArchive={handleArchive}
                    onRestore={handleRestore}
                    categoriesList={categoriesList}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body2" sx={{ mt: 2 }}>
              No archived notes.
            </Typography>
          )}
        </Collapse>
      </Box>

      {/* Sticky Note Editor Dialog */}
      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>
          {editingNote ? "Edit Sticky Note" : "Add Sticky Note"}
        </DialogTitle>
        <DialogContent>
          {/* Title Input */}
          <TextField
            fullWidth
            label="Title"
            value={noteTitle}
            onChange={(e) => setNoteTitle(e.target.value)}
            variant="outlined"
            sx={{ mt: 2, mb: 2 }}
            InputLabelProps={{
              style: { color: "#000000" },
            }}
            InputProps={{
              style: { color: "#000000" },
            }}
          />

          {/* Description Input */}
          <TextField
            fullWidth
            label="Description"
            value={noteDescription}
            onChange={(e) => setNoteDescription(e.target.value)}
            variant="outlined"
            multiline
            rows={4}
            sx={{ mb: 2 }}
            InputLabelProps={{
              style: { color: "#000000" },
            }}
            InputProps={{
              style: { color: "#000000" },
            }}
          />

          {/* Language Selection and Recording Buttons */}
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <FormControl sx={{ minWidth: 120, mr: 2 }}>
              <InputLabel style={{ color: "#000000" }}>Language</InputLabel>
              <Select
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.target.value)}
                label="Language"
                sx={{
                  "& .MuiSelect-icon": {
                    color: "#000000",
                  },
                }}
              >
                {languages.map((lang) => (
                  <MenuItem key={lang.code} value={lang.code}>
                    {lang.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {isRecording ? (
              <MUIButton
                variant="contained"
                color="secondary"
                onClick={stopRecording}
                startIcon={<MicIcon />}
                sx={{ textTransform: "none" }}
              >
                Stop Recording
              </MUIButton>
            ) : (
              <MUIButton
                variant="contained"
                color="primary"
                onClick={startRecording}
                startIcon={<MicIcon />}
                sx={{ textTransform: "none" }}
              >
                Start Recording
              </MUIButton>
            )}
          </Box>

          {/* Category Selection */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel style={{ color: "#000000" }}>Category</InputLabel>
            <Select
              value={noteCategory}
              onChange={(e) => setNoteCategory(e.target.value)}
              label="Category"
              sx={{
                "& .MuiSelect-icon": {
                  color: "#000000",
                },
              }}
            >
              {categoriesList.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {React.createElement(
                      iconMapping[category.icon] || MoreHorizIcon,
                      {
                        style: { marginRight: "8px", color: "#000000" },
                      },
                    )}
                    {category.name}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Add Custom Category */}
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <TextField
              label="Add Custom Category"
              value={customCategory}
              onChange={(e) => setCustomCategory(e.target.value)}
              variant="outlined"
              sx={{ flexGrow: 1, mr: 2 }}
              InputLabelProps={{
                style: { color: "#000000" },
              }}
              InputProps={{
                style: { color: "#000000" },
              }}
            />
            <MUIButton
              variant="contained"
              color="secondary"
              onClick={handleAddCustomCategory}
              sx={{ textTransform: "none" }}
            >
              Add
            </MUIButton>
          </Box>

          {/* Priority Selection */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel style={{ color: "#000000" }}>Priority</InputLabel>
            <Select
              value={notePriority}
              onChange={(e) => setNotePriority(e.target.value)}
              label="Priority"
              sx={{
                "& .MuiSelect-icon": {
                  color: "#000000",
                },
              }}
            >
              {priorities.map((priority) => (
                <MenuItem key={priority.value} value={priority.value}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <FlagIcon
                      sx={{ color: priority.color, marginRight: "8px" }}
                    />
                    {priority.label}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Due Date Input */}
          <TextField
            fullWidth
            label="Due Date"
            type="date"
            value={noteDueDate}
            onChange={(e) => setNoteDueDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: { color: "#000000" },
            }}
            sx={{ mb: 2 }}
            InputProps={{
              style: { color: "#000000" },
            }}
          />

          {/* Reminder Date and Time Input */}
          <TextField
            fullWidth
            label="Reminder Date and Time"
            type="datetime-local"
            value={noteReminderDate}
            onChange={(e) => setNoteReminderDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: { color: "#000000" },
            }}
            sx={{ mb: 2 }}
            InputProps={{
              style: { color: "#000000" },
            }}
          />

          {/* Note Color Text */}
          <Typography variant="subtitle1" sx={{ mb: 1, color: "#000000" }}>
            Note Color
          </Typography>

          {/* Note Color Selection */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 1,
              mb: 2,
            }}
          >
            {predefinedNeonColors.map((color) => (
              <Box
                key={color}
                onClick={() => setNoteColor(color)}
                sx={{
                  width: 30,
                  height: 30,
                  borderRadius: "50%",
                  backgroundColor: color,
                  border:
                    noteColor === color ? "2px solid #000" : "1px solid #ccc",
                  cursor: "pointer",
                }}
                aria-label={`Select color ${color}`}
              />
            ))}
            {/* Color Picker */}
            <TextField
              type="color"
              value={noteColor}
              onChange={(e) => setNoteColor(e.target.value)}
              sx={{
                width: 40,
                height: 40,
                padding: 0,
                border: "none",
                cursor: "pointer",
              }}
              aria-label="Select Note Color"
            />
          </Box>

          {/* Text Color Selection */}
          <Typography variant="subtitle1" sx={{ mb: 1, color: "#000000" }}>
            Text Color
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 1,
              mb: 2,
            }}
          >
            {predefinedNeonColors.map((color) => (
              <Box
                key={color}
                onClick={() => setTextColor(color)}
                sx={{
                  width: 30,
                  height: 30,
                  borderRadius: "50%",
                  backgroundColor: color,
                  border:
                    textColor === color ? "2px solid #000" : "1px solid #ccc",
                  cursor: "pointer",
                }}
                aria-label={`Select text color ${color}`}
              />
            ))}
            {/* Text Color Picker */}
            <TextField
              type="color"
              value={textColor}
              onChange={(e) => setTextColor(e.target.value)}
              sx={{
                width: 40,
                height: 40,
                padding: 0,
                border: "none",
                cursor: "pointer",
              }}
              aria-label="Select Text Color"
            />
          </Box>

          {/* Upload Image and File Buttons */}
          <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
            <MUIButton
              variant="outlined"
              component="label"
              startIcon={<UploadFileIcon />}
              sx={{ textTransform: "none" }}
            >
              Upload Image
              <input
                type="file"
                hidden
                onChange={handleImageUpload}
                accept="image/*"
              />
            </MUIButton>
            <MUIButton
              variant="outlined"
              component="label"
              startIcon={<AttachFileIcon />}
              sx={{ textTransform: "none" }}
            >
              Upload File
              <input
                type="file"
                hidden
                onChange={handleFileUpload}
                accept=".pdf,.doc,.docx,.xls,.xlsx"
              />
            </MUIButton>
          </Box>

          {/* Display Uploaded Image */}
          {noteImageUrl && (
            <Box sx={{ mb: 2 }}>
              <img
                src={noteImageUrl}
                alt="Note"
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  maxHeight: "400px",
                  objectFit: "contain",
                }}
              />
            </Box>
          )}

          {/* Display Uploaded File */}
          {noteFileUrl && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2">
                {extractFileNameFromURL(noteFileUrl)}
              </Typography>
              <MUIButton
                variant="contained"
                color="primary"
                onClick={() => window.open(noteFileUrl, "_blank")}
                sx={{ mt: 1, textTransform: "none" }}
              >
                View File
              </MUIButton>
            </Box>
          )}

          {/* Display Transcription */}
          {transcription && (
            <TextField
              fullWidth
              label="Transcription"
              value={transcription}
              multiline
              rows={4}
              variant="outlined"
              sx={{
                mt: 2,
                direction: /[\u0600-\u06FF]/.test(transcription)
                  ? "rtl"
                  : "ltr",
              }}
              InputLabelProps={{
                style: { color: "#000000" },
              }}
              InputProps={{
                style: { color: "#000000" },
                readOnly: true,
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <MUIButton
            onClick={handleCloseDialog}
            variant="outlined"
            color="secondary"
            sx={{ textTransform: "none" }}
          >
            Cancel
          </MUIButton>
          <MUIButton
            onClick={handleSaveNote}
            variant="contained"
            color="primary"
            sx={{ textTransform: "none" }}
          >
            Save
          </MUIButton>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={confirmDeleteOpen} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this note?</Typography>
        </DialogContent>
        <DialogActions>
          <MUIButton
            onClick={handleCancelDelete}
            variant="outlined"
            color="secondary"
            sx={{ textTransform: "none" }}
          >
            Cancel
          </MUIButton>
          <MUIButton
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            sx={{ textTransform: "none" }}
          >
            Confirm
          </MUIButton>
        </DialogActions>
      </Dialog>

      {/* Sticky Note Viewer Dialog */}
      <Dialog
        open={viewOpen}
        onClose={() => setViewOpen(false)}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          style: {
            borderRadius: "12px",
            backgroundColor: "#FFFFFF",
            color: "#000000",
            padding: "16px",
          },
        }}
      >
        {viewingNote && (
          <>
            <DialogTitle>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    backgroundColor:
                      viewingNote.category &&
                      categoriesList.find(
                        (cat) => cat.id === viewingNote.category,
                      )?.color
                        ? categoriesList.find(
                            (cat) => cat.id === viewingNote.category,
                          )?.color
                        : colorPalette.dark2,
                    borderRadius: "4px",
                    padding: "4px 8px",
                    color: "#FFFFFF",
                    mr: 2,
                  }}
                >
                  <Typography variant="caption">
                    {viewingNote.category
                      ? categoriesList.find(
                          (cat) => cat.id === viewingNote.category,
                        )?.name
                      : "Other"}
                  </Typography>
                </Box>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", flexGrow: 1 }}
                >
                  {viewingNote.title}
                </Typography>
                <Chip
                  icon={
                    <FlagIcon
                      sx={{
                        color: priorities.find(
                          (p) => p.value === viewingNote.priority,
                        )?.color,
                      }}
                    />
                  }
                  label={viewingNote.priority}
                  sx={{
                    backgroundColor: `${
                      priorities.find((p) => p.value === viewingNote.priority)
                        ?.color
                    }20`,
                  }}
                />
              </Box>
            </DialogTitle>
            <DialogContent dividers>
              {/* Description */}
              <Typography
                variant="body1"
                sx={{
                  mb: 2,
                  whiteSpace: "pre-wrap",
                  color: viewingNote.textColor || "#000000",
                }}
              >
                {viewingNote.description}
              </Typography>

              {/* Due Date */}
              {viewingNote.dueDate && (
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <EventIcon sx={{ mr: 1 }} />
                  Due Date: {viewingNote.dueDate.toDate().toLocaleDateString()}
                </Typography>
              )}

              {/* Reminder Date */}
              {viewingNote.reminderDate && (
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <NotificationsActiveIcon sx={{ mr: 1 }} />
                  Reminder:{" "}
                  {typeof viewingNote.reminderDate.toDate === "function"
                    ? viewingNote.reminderDate.toDate().toLocaleString()
                    : "Invalid Date"}
                </Typography>
              )}

              {/* Uploaded Image */}
              {viewingNote.image && (
                <Box sx={{ mb: 2 }}>
                  <img
                    src={viewingNote.image}
                    alt="Note"
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      maxHeight: "600px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              )}

              {/* Uploaded File */}
              {viewingNote.file && (
                <Box sx={{ mb: 2 }}>
                  <Typography
                    variant="body2"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <AttachFileIcon sx={{ mr: 1 }} />
                    Attached file: {extractFileNameFromURL(viewingNote.file)}
                  </Typography>
                  <MUIButton
                    variant="contained"
                    color="primary"
                    onClick={() => window.open(viewingNote.file, "_blank")}
                    sx={{ mt: 1, textTransform: "none" }}
                  >
                    View File
                  </MUIButton>
                </Box>
              )}

              {/* Voice Note Audio */}
              {viewingNote.audioUrl && (
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "bold",
                      color: colorPalette.primary,
                      display: "flex",
                      alignItems: "center",
                      mb: 0.5,
                    }}
                  >
                    <MicIcon fontSize="small" sx={{ mr: 0.5 }} />
                    Voice Note
                  </Typography>
                  <audio
                    controls
                    src={viewingNote.audioUrl}
                    style={{ width: "100%" }}
                  />
                </Box>
              )}

              {/* Transcription */}
              {viewingNote.transcription && (
                <TextField
                  fullWidth
                  label="Transcription"
                  value={viewingNote.transcription}
                  multiline
                  rows={4}
                  variant="outlined"
                  sx={{
                    mt: 2,
                    direction: /[\u0600-\u06FF]/.test(viewingNote.transcription)
                      ? "rtl"
                      : "ltr",
                    color: viewingNote.textColor || "#000000",
                  }}
                  InputLabelProps={{
                    style: { color: "#000000" },
                  }}
                  InputProps={{
                    style: { color: viewingNote.textColor || "#000000" },
                    readOnly: true,
                  }}
                />
              )}

              {/* Last Updated */}
              <Typography
                variant="caption"
                sx={{ display: "block", color: "text.secondary", mt: 2 }}
              >
                Last updated:{" "}
                {viewingNote.date
                  ? typeof viewingNote.date.toDate === "function"
                    ? viewingNote.date.toDate().toLocaleString()
                    : "Invalid Date"
                  : "N/A"}
              </Typography>
            </DialogContent>
            <DialogActions>
              <MUIButton
                onClick={() => setViewOpen(false)}
                variant="outlined"
                color="secondary"
                sx={{ textTransform: "none" }}
              >
                Close
              </MUIButton>
              <MUIButton
                onClick={() => {
                  setViewOpen(false);
                  handleEditNote(viewingNote);
                }}
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                sx={{ textTransform: "none" }}
              >
                Edit
              </MUIButton>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="info"
          sx={{
            width: "100%",
            backgroundColor: colorPalette.primary,
            color: "#000000",
          }}
        >
          {snackbarMessage.split("\n").map((str, idx) => (
            <span key={idx}>
              {str}
              <br />
            </span>
          ))}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default StickyNotesBoard;
