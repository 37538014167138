// src/components/Sidebar.jsx

import React, { useState, useContext, useMemo, useCallback } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Typography,
  Badge,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Collapse,
  Divider,
  useMediaQuery,
  Avatar,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  AddCircle as AddCircleIcon,
  Dashboard as DashboardIcon,
  Settings as SettingsIcon,
  ExpandMore,
  ExpandLess,
  Notifications as NotificationsIcon,
  DarkMode as DarkModeIcon,
  LightMode as LightModeIcon,
  Translate as TranslateIcon,
  Folder as ActiveProjectsIcon,
  Archive as ArchivedProjectsIcon,
  Restore as RestoreIcon,
  Logout as LogoutIcon,
  Chat as ChatIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../contexts/NotificationContext";
import { useProjects } from "../contexts/ProjectsContext";
import ErrorBoundary from "./ErrorBoundary";

const Sidebar = ({
  isOpen,
  isDarkMode,
  toggleDarkMode,
  isArabic,
  toggleLanguage,
  setShowDashboard,
  toggleSidebar,
  handleLogout,
  currentUser,
  showDashboard,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  // State variables
  const [activeItem, setActiveItem] = useState("dashboard");
  const [expandedProjects, setExpandedProjects] = useState({
    activeProjects: false,
    archivedProjects: false,
  });
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [newProjectName, setNewProjectName] = useState("");

  // Contexts
  const { notifications, notificationPrefs } = useContext(NotificationContext);
  const { projects, archivedProjects, addProject, restoreProject } =
    useProjects();

  // Memoized values
  const pendingNotificationsCount = useMemo(
    () => notifications.filter((notif) => notificationPrefs[notif.type]).length,
    [notifications, notificationPrefs],
  );

  const uniqueProjects = useMemo(() => {
    const projectMap = new Map();
    projects.forEach((project) => projectMap.set(project.id, project));
    return Array.from(projectMap.values());
  }, [projects]);

  // Handlers
  const handleItemClick = useCallback(
    (item) => {
      switch (item) {
        case "settings":
          setSettingsOpen(true);
          break;
        case "dashboard":
          setShowDashboard(true);
          navigate("/app");
          break;
        case "ai-assistant":
          setShowDashboard(false);
          navigate("/app/ai-assistant");
          break;
        case "notifications":
          navigate("/notifications");
          break;
        default:
          break;
      }
      if (isMobile) toggleSidebar();
      setActiveItem(item);
    },
    [isMobile, navigate, setShowDashboard, toggleSidebar],
  );

  const handleProjectClick = useCallback(
    (project) => {
      navigate(`/app/projects/${project.id}`);
      setShowDashboard(false);
      if (isMobile) toggleSidebar();
    },
    [isMobile, navigate, toggleSidebar, setShowDashboard],
  );

  const handleAddProject = useCallback(() => {
    if (newProjectName.trim()) {
      addProject({ name: newProjectName.trim() });
      setNewProjectName("");
      setShowDashboard(false);
      if (isMobile) toggleSidebar();
    }
  }, [addProject, isMobile, newProjectName, setShowDashboard, toggleSidebar]);

  const toggleProjectExpansion = (projectType) => {
    setExpandedProjects((prev) => ({
      ...prev,
      [projectType]: !prev[projectType],
    }));
  };

  const translate = (englishText, arabicText) =>
    isArabic ? arabicText : englishText;

  const sharedStyles = {
    primaryText: { color: "#000000" },
    drawerBackground: { backgroundColor: "#F0F0F0" },
    hoverStyles: { "&:hover": { backgroundColor: "#C0C0C0" } },
  };

  const listItemStyle = (isSelected) => ({
    borderRadius: "8px",
    margin: "6px 0",
    padding: "8px 12px",
    backgroundColor: isSelected ? "#D0D0D0" : "transparent",
    color: "#000000",
    transition: "background-color 0.3s ease, color 0.3s ease",
    ...sharedStyles.hoverStyles,
  });

  const renderProjectsList = (projectsList, isArchived = false) =>
    projectsList.length > 0 ? (
      projectsList.map((project) => (
        <ListItem
          key={`${isArchived ? "archived" : "active"}-${project.id}`}
          button={!isArchived}
          onClick={() => !isArchived && handleProjectClick(project)}
          sx={{
            pl: isArchived ? 4 : 2,
            ...listItemStyle(false),
          }}
        >
          <ListItemIcon sx={sharedStyles.primaryText}>
            {isArchived ? <ArchivedProjectsIcon /> : <ActiveProjectsIcon />}
          </ListItemIcon>
          <ListItemText primary={project.name || "Unnamed Project"} />
          {isArchived && (
            <IconButton
              edge="end"
              aria-label={translate("Restore project", "استعادة المشروع")}
              onClick={(e) => {
                e.stopPropagation();
                restoreProject(project.id);
              }}
            >
              <RestoreIcon sx={sharedStyles.primaryText} />
            </IconButton>
          )}
        </ListItem>
      ))
    ) : (
      <ListItem>
        <ListItemText
          primary={
            isArchived
              ? translate("No archived projects", "لا توجد مشاريع مؤرشفة")
              : translate("No active projects", "لا توجد مشاريع نشطة")
          }
        />
      </ListItem>
    );

  const renderSettingsDialog = () => (
    <Dialog open={settingsOpen} onClose={() => setSettingsOpen(false)}>
      <DialogTitle sx={sharedStyles.drawerBackground}>
        {translate("Settings", "الإعدادات")}
      </DialogTitle>
      <DialogContent sx={sharedStyles.drawerBackground}>
        <Button
          onClick={toggleDarkMode}
          startIcon={isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
          sx={{ color: "#000000", marginBottom: 1 }}
        >
          {isDarkMode
            ? translate("Light Mode", "الوضع النهاري")
            : translate("Dark Mode", "الوضع المظلم")}
        </Button>
        <Button
          onClick={toggleLanguage}
          startIcon={<TranslateIcon />}
          sx={{ color: "#000000" }}
        >
          {translate("العربية", "English")}
        </Button>
      </DialogContent>
      <DialogActions sx={sharedStyles.drawerBackground}>
        <Button onClick={() => setSettingsOpen(false)} color="primary">
          {translate("Close", "إغلاق")}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const drawerStyles = {
    width: 280,
    flexShrink: 0,
    zIndex: 1300,
    "& .MuiDrawer-paper": {
      width: 280,
      boxSizing: "border-box",
      bgcolor: "#F0F0F0",
      color: "#000000",
      zIndex: 1200,
    },
  };

  const drawerContent = (
    <Box sx={{ position: "relative", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 3,
          backgroundColor: "#E0E0E0",
          color: "#000000",
          borderBottomLeftRadius: "12px",
          borderBottomRightRadius: "12px",
          textAlign: "center",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
          CtrlPM
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontSize: "14px", color: "#606060" }}
        >
          Platform
        </Typography>
      </Box>
      {currentUser && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2,
            backgroundColor: "#F0F0F0",
            color: "#000000",
            borderBottom: "1px solid #D0D0D0",
          }}
        >
          <Avatar
            alt={currentUser.displayName || currentUser.email}
            src={currentUser.photoURL || ""}
            sx={{ width: 64, height: 64, mb: 1 }}
          />
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {currentUser.displayName || currentUser.email}
          </Typography>
          <Typography variant="caption" sx={{ color: "#606060" }}>
            {currentUser.email}
          </Typography>
        </Box>
      )}
      <List sx={{ px: 2 }}>
        <ListItem
          button
          onClick={() => handleItemClick("ai-assistant")}
          sx={listItemStyle(activeItem === "ai-assistant")}
        >
          <ListItemIcon sx={sharedStyles.primaryText}>
            <ChatIcon />
          </ListItemIcon>
          <ListItemText
            primary={translate("AI Project Assistant", "مساعد المشروع الذكي")}
          />
        </ListItem>
      </List>
      <List sx={{ px: 2 }}>
        <ListItem
          button
          onClick={() => handleItemClick("notifications")}
          sx={listItemStyle(activeItem === "notifications")}
        >
          <ListItemIcon sx={sharedStyles.primaryText}>
            <Badge
              badgeContent={pendingNotificationsCount}
              color="secondary"
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "orange",
                  color: "white",
                },
              }}
            >
              <NotificationsIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={translate("Notifications", "الإشعارات")} />
        </ListItem>
      </List>
      <Divider sx={{ my: 1, backgroundColor: "#C0C0C0" }} />
      <List sx={{ px: 2 }}>
        <ListItem
          button
          onClick={() => handleItemClick("dashboard")}
          sx={listItemStyle(activeItem === "dashboard" && showDashboard)}
        >
          <ListItemIcon sx={sharedStyles.primaryText}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={translate("Dashboard", "لوحة القيادة")} />
        </ListItem>
      </List>
      <List sx={{ px: 2 }}>
        <ListItem
          button
          onClick={() => toggleProjectExpansion("activeProjects")}
          sx={listItemStyle(activeItem === "active-projects")}
        >
          <ListItemIcon sx={sharedStyles.primaryText}>
            <ActiveProjectsIcon />
          </ListItemIcon>
          <ListItemText
            primary={translate("Active Projects", "المشاريع النشطة")}
          />
          {expandedProjects.activeProjects ? (
            <ExpandLess sx={sharedStyles.primaryText} />
          ) : (
            <ExpandMore sx={sharedStyles.primaryText} />
          )}
        </ListItem>
        <Collapse
          in={expandedProjects.activeProjects}
          timeout="auto"
          unmountOnExit
        >
          {renderProjectsList(uniqueProjects)}
        </Collapse>
      </List>
      <List sx={{ px: 2 }}>
        <ListItem
          button
          onClick={() => toggleProjectExpansion("archivedProjects")}
          sx={listItemStyle(activeItem === "archived-projects")}
        >
          <ListItemIcon sx={sharedStyles.primaryText}>
            <ArchivedProjectsIcon />
          </ListItemIcon>
          <ListItemText
            primary={translate("Archived Projects", "المشاريع المؤرشفة")}
          />
          {expandedProjects.archivedProjects ? (
            <ExpandLess sx={sharedStyles.primaryText} />
          ) : (
            <ExpandMore sx={sharedStyles.primaryText} />
          )}
        </ListItem>
        <Collapse
          in={expandedProjects.archivedProjects}
          timeout="auto"
          unmountOnExit
        >
          {renderProjectsList(archivedProjects, true)}
        </Collapse>
      </List>
      <Box
        sx={{
          p: 2,
          mt: 2,
          borderRadius: "8px",
          backgroundColor: "#E0E0E0",
        }}
      >
        <Stack spacing={2}>
          <TextField
            fullWidth
            size="small"
            label={translate("New Project Name", "اسم المشروع الجديد")}
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
            variant="outlined"
            sx={{
              mb: 1,
              "& .MuiInputBase-input": { color: "#000000" },
              "& .MuiInputLabel-root": { color: "#000000" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#A0A0A0" },
                "&:hover fieldset": { borderColor: "#000000" },
                "&.Mui-focused fieldset": { borderColor: "#000000" },
              },
            }}
          />
          <Tooltip
            title={translate(
              "Enter the new project name and click Add",
              "أدخل اسم المشروع الجديد ثم انقر على إضافة",
            )}
          >
            <Button
              fullWidth
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={handleAddProject}
              sx={{
                backgroundColor: "#000000",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#303030",
                },
              }}
            >
              {translate("Add Project", "إضافة مشروع")}
            </Button>
          </Tooltip>
        </Stack>
      </Box>
      <Divider sx={{ my: 2, backgroundColor: "#C0C0C0" }} />
      <List sx={{ px: 2 }}>
        <ListItem
          button
          onClick={() => handleItemClick("settings")}
          sx={listItemStyle(activeItem === "settings")}
        >
          <ListItemIcon sx={sharedStyles.primaryText}>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={translate("Settings", "الإعدادات")} />
        </ListItem>
        <ListItem
          button
          onClick={handleLogout}
          sx={listItemStyle(activeItem === "sign-out")}
        >
          <ListItemIcon sx={sharedStyles.primaryText}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={translate("Sign Out", "تسجيل الخروج")} />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <ErrorBoundary>
      {isMobile && (
        <IconButton
          aria-label={translate("Open Sidebar", "فتح الشريط الجانبي")}
          onClick={toggleSidebar}
          sx={{
            position: "fixed",
            top: 16,
            left: isArabic ? "auto" : 16,
            right: isArabic ? 16 : "auto",
            zIndex: 1500,
            backgroundColor: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#F0F0F0",
            },
          }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Drawer
        variant={isMobile ? "temporary" : "persistent"}
        anchor={isArabic ? "right" : "left"}
        open={isOpen}
        onClose={toggleSidebar}
        ModalProps={{ keepMounted: true }}
        sx={drawerStyles}
      >
        {drawerContent}
      </Drawer>
      {renderSettingsDialog()}
    </ErrorBoundary>
  );
};

export default Sidebar;
