// ProjectHeader.jsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  LinearProgress,
  Paper,
  Avatar,
  IconButton,
  TextField,
  Button,
  Tooltip,
  Chip,
  Grid,
  Divider,
  Select,
  MenuItem,
  InputAdornment,
  FormControl,
  InputLabel,
  Link as MuiLink,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupIcon from '@mui/icons-material/Group';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  fontFamily: 'Arial, sans-serif',
}));

const ProjectHeader = ({
  selectedProject,
  updateProject,
  calculateProgress,
  currency,
  customCurrency,
  setCurrency,
  setCustomCurrency,
  onDelete,
  onArchive,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedProject, setEditedProject] = useState(selectedProject);
  const [bookmarks, setBookmarks] = useState(selectedProject.bookmarks || []);
  const [addBookmarkDialogOpen, setAddBookmarkDialogOpen] = useState(false);
  const [newBookmark, setNewBookmark] = useState({ name: '', url: '' });
  const progress = calculateProgress(selectedProject.scope || []);
  const theme = useTheme();

  // Update state when selectedProject changes
  useEffect(() => {
    setEditedProject(selectedProject);
    setBookmarks(selectedProject.bookmarks || []);
  }, [selectedProject]);

  const handleSave = () => {
    updateProject({ ...editedProject, bookmarks });
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedProject(selectedProject);
    setBookmarks(selectedProject.bookmarks || []);
    setIsEditing(false);
  };

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEditedProject({ ...editedProject, logo: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    setEditedProject({ ...editedProject, [e.target.name]: e.target.value });
  };

  const handleAddBookmark = () => {
    setAddBookmarkDialogOpen(true);
  };

  const handleConfirmAddBookmark = () => {
    if (newBookmark.name && newBookmark.url) {
      setBookmarks([...bookmarks, { id: Date.now(), ...newBookmark }]);
      setNewBookmark({ name: '', url: '' });
      setAddBookmarkDialogOpen(false);
    }
  };

  const handleDeleteBookmark = (id) => {
    setBookmarks(bookmarks.filter((bookmark) => bookmark.id !== id));
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'On Track':
        return 'success';
      case 'At Risk':
        return 'warning';
      case 'Delayed':
        return 'error';
      default:
        return 'default';
    }
  };

  const calculateDuration = () => {
    if (!selectedProject.startDate) return 0;
    const start = new Date(selectedProject.startDate);
    const today = new Date();
    const diffTime = Math.abs(today - start);
    const diffWeeks = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7));
    return diffWeeks;
  };

  const calculateCollectedAmount = () => {
    return selectedProject.invoices
      ? selectedProject.invoices
          .filter((invoice) => invoice.status === 'paid')
          .reduce((sum, invoice) => sum + invoice.amount, 0)
      : 0;
  };

  const getCurrencySymbol = () => {
    return currency === 'custom' ? customCurrency : currency;
  };

  const formatCurrency = (amount) => {
    const symbol = getCurrencySymbol();
    return `${symbol} ${amount.toLocaleString()}`;
  };

  return (
    <StyledPaper elevation={3}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={8}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            {isEditing ? (
              <TextField
                name="name"
                label="Project Name"
                value={editedProject.name || ''}
                onChange={handleChange}
                variant="outlined"
                size="small"
                sx={{ mr: 1, flexGrow: 1, fontSize: '1.5rem' }} // Increased font size
                helperText="Enter the project name"
              />
            ) : (
              <Typography variant="h4" sx={{ mr: 1, fontSize: '2.5rem' }}> {/* Increased font size */}
                {selectedProject.name || 'Untitled Project'}
              </Typography>
            )}
            {isEditing ? (
              <>
                <Tooltip title="Save Changes">
                  <IconButton onClick={handleSave} size="large">
                    <SaveIcon fontSize="large" sx={{ fontSize: '2rem' }} /> {/* Increased icon size */}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel Editing">
                  <IconButton onClick={handleCancel} size="large">
                    <CancelIcon fontSize="large" sx={{ fontSize: '2rem' }} /> {/* Increased icon size */}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Archive Project">
                  <IconButton onClick={() => onArchive(selectedProject.id)} size="large">
                    <ArchiveOutlinedIcon fontSize="large" sx={{ fontSize: '2rem' }} /> {/* Increased icon size */}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Project">
                  <IconButton onClick={() => onDelete(selectedProject.id)} color="error" size="large">
                    <DeleteForeverIcon fontSize="large" sx={{ fontSize: '2rem' }} /> {/* Increased icon size */}
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <Tooltip title="Edit Project">
                <IconButton onClick={() => setIsEditing(true)} size="large">
                  <EditIcon fontSize="large" sx={{ fontSize: '2rem' }} /> {/* Increased icon size */}
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box sx={{ width: '100%', mb: 2 }}>
            <Tooltip title={`${Math.round(progress)}% Complete`}>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  height: 15, // Increased height
                  borderRadius: 5,
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 5,
                    backgroundColor:
                      progress >= 75 ? '#4caf50' : progress >= 50 ? '#ff9800' : '#f44336',
                  },
                }}
              />
            </Tooltip>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1, fontSize: '1.2rem' }}>
              Project Progress {/* Increased font size */}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1.2rem' }}>
              {`${Math.round(progress)}% Complete`} {/* Increased font size */}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              {isEditing ? (
                <TextField
                  name="startDate"
                  type="date"
                  label="Start Date"
                  value={editedProject.startDate || ''}
                  onChange={handleChange}
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  helperText="Select the project start date"
                  sx={{ fontSize: '1.2rem' }} // Increased font size
                />
              ) : (
                <Chip
                  icon={<CalendarMonthIcon color="inherit" sx={{ fontSize: '1.8rem' }} />} // Increased icon size
                  label={
                    selectedProject.startDate
                      ? `Start: ${new Date(selectedProject.startDate).toLocaleDateString()}`
                      : 'Start Date: Not set'
                  }
                  color="default"
                  variant="outlined"
                  sx={{ fontSize: '1.2rem' }} // Increased font size
                />
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {isEditing ? (
                <TextField
                  name="teamMembers"
                  type="number"
                  label="Team Members"
                  value={editedProject.teamMembers || ''}
                  onChange={handleChange}
                  size="small"
                  fullWidth
                  helperText="Enter the number of team members"
                  sx={{ fontSize: '1.2rem' }} // Increased font size
                />
              ) : (
                <Chip
                  icon={<GroupIcon color="inherit" sx={{ fontSize: '1.8rem' }} />} // Increased icon size
                  label={`Team: ${selectedProject.teamMembers || 0} members`}
                  color="default"
                  variant="outlined"
                  sx={{ fontSize: '1.2rem' }} // Increased font size
                />
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {isEditing ? (
                <TextField
                  name="projectValue"
                  type="number"
                  label="Project Value"
                  value={editedProject.projectValue || ''}
                  onChange={handleChange}
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {getCurrencySymbol()}
                      </InputAdornment>
                    ),
                  }}
                  helperText="Enter the total project value"
                  sx={{ fontSize: '1.2rem' }} // Increased font size
                />
              ) : (
                <Chip
                  icon={<LocalAtmIcon color="inherit" sx={{ fontSize: '1.8rem' }} />} // Increased icon size
                  label={`Project Value: ${formatCurrency(selectedProject.projectValue || 0)}`}
                  color="default"
                  variant="outlined"
                  sx={{ fontSize: '1.2rem' }} // Increased font size
                />
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {isEditing ? (
                <FormControl fullWidth size="small">
                  <InputLabel>Status</InputLabel>
                  <Select
                    name="status"
                    value={editedProject.status || 'Not Started'}
                    onChange={handleChange}
                    label="Status"
                    sx={{ fontSize: '1.2rem' }} // Increased font size
                  >
                    <MenuItem value="Not Started">Not Started</MenuItem>
                    <MenuItem value="On Track">On Track</MenuItem>
                    <MenuItem value="At Risk">At Risk</MenuItem>
                    <MenuItem value="Delayed">Delayed</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <Chip
                  icon={<FlagCircleIcon sx={{ fontSize: '1.8rem' }} />} // Increased icon size
                  label={`Status: ${selectedProject.status || 'Not Started'}`}
                  color={getStatusColor(selectedProject.status)}
                  variant="outlined"
                  sx={{ fontSize: '1.2rem' }} // Increased font size
                />
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <Chip
                icon={<ScheduleIcon color="inherit" sx={{ fontSize: '1.8rem' }} />} // Increased icon size
                label={`Duration: ${calculateDuration()} weeks`}
                color="default"
                variant="outlined"
                sx={{ fontSize: '1.2rem' }} // Increased font size
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Chip
                icon={<AccountBalanceWalletIcon color="inherit" sx={{ fontSize: '1.8rem' }} />} // Increased icon size
                label={`Collected: ${formatCurrency(calculateCollectedAmount())}`}
                color="default"
                variant="outlined"
                sx={{ fontSize: '1.2rem' }} // Increased font size
              />
            </Grid>
            {isEditing && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel>Currency</InputLabel>
                    <Select
                      value={currency}
                      onChange={(e) => {
                        setCurrency(e.target.value);
                        if (e.target.value !== 'custom') {
                          setCustomCurrency('');
                        }
                      }}
                      label="Currency"
                      sx={{ fontSize: '1.2rem' }} // Increased font size
                    >
                      {[
                        { value: 'USD', label: 'USD - United States Dollar' },
                        { value: 'EUR', label: 'EUR - Euro' },
                        { value: 'GBP', label: 'GBP - British Pound' },
                        { value: 'JPY', label: 'JPY - Japanese Yen' },
                        { value: 'SAR', label: 'SAR - Saudi Riyal' },
                        { value: 'CAD', label: 'CAD - Canadian Dollar' },
                        { value: 'AUD', label: 'AUD - Australian Dollar' },
                        { value: 'INR', label: 'INR - Indian Rupee' },
                        { value: 'CNY', label: 'CNY - Chinese Yuan' },
                        { value: 'AED', label: 'AED - UAE Dirham' },
                        { value: 'CHF', label: 'CHF - Swiss Franc' },
                        { value: 'SGD', label: 'SGD - Singapore Dollar' },
                        { value: 'HKD', label: 'HKD - Hong Kong Dollar' },
                        { value: 'KRW', label: 'KRW - South Korean Won' },
                        { value: 'ZAR', label: 'ZAR - South African Rand' },
                        { value: 'BRL', label: 'BRL - Brazilian Real' },
                        { value: 'RUB', label: 'RUB - Russian Ruble' },
                        { value: 'custom', label: 'Custom...' },
                      ].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {currency === 'custom' && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Custom Currency"
                      value={customCurrency}
                      onChange={(e) => setCustomCurrency(e.target.value)}
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ fontSize: '1.2rem' }} // Increased font size
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar
              src={editedProject.logo || ''}
              alt={editedProject.name || 'Project Logo'}
              sx={{ width: 180, height: 180, mb: 2 }} // Increased logo size
            />
            <Tooltip title="Upload new logo">
              <label htmlFor="logo-upload">
                <input
                  id="logo-upload"
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleLogoUpload}
                />
                <Button
                  component="span"
                  startIcon={<UploadFileIcon fontSize="large" sx={{ fontSize: '2rem' }} />} // Increased icon size
                  size="large"
                  variant="outlined"
                  sx={{ fontSize: '1.2rem' }} // Increased button text size
                >
                  Update Logo
                </Button>
              </label>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      <Box>
        {isEditing ? (
          <TextField
            name="description"
            label="Project Description"
            value={editedProject.description || ''}
            onChange={handleChange}
            multiline
            rows={3}
            fullWidth
            variant="outlined"
            helperText="Enter a brief description of the project"
            sx={{ fontSize: '1.2rem' }} // Increased font size
          />
        ) : (
          <Typography variant="body1" sx={{ mb: 2, fontSize: '1.5rem' }}> {/* Increased font size */}
            {selectedProject.description || 'No description provided'}
          </Typography>
        )}
        {/* Bookmarks Section */}
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" sx={{ mb: 1, fontSize: '1.8rem' }}> {/* Increased font size */}
            Bookmarks
          </Typography>
          {/* Changed flexDirection to 'row' and added flexWrap for horizontal layout */}
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap' }}>
            {bookmarks.map((bookmark) => (
              <Box
                key={bookmark.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: theme.palette.grey[100],
                  padding: 1,
                  borderRadius: 1,
                }}
              >
                <BookmarkIcon sx={{ mr: 1, color: theme.palette.primary.main, fontSize: '1.5rem' }} /> {/* Increased icon size */}
                <MuiLink
                  href={bookmark.url}
                  target="_blank"
                  rel="noopener"
                  sx={{ fontWeight: 'bold', fontSize: '1.2rem' }} // Increased font size
                >
                  {bookmark.name}
                </MuiLink>
                {isEditing && (
                  <Tooltip title="Delete Bookmark"> {/* Added tooltip to delete icon */}
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteBookmark(bookmark.id)}
                      sx={{ ml: 'auto' }}
                    >
                      <DeleteIcon fontSize="small" sx={{ fontSize: '1.2rem' }} /> {/* Increased icon size */}
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            ))}
          </Box>
          {isEditing && (
            <>
              <Button
                variant="text"
                startIcon={<BookmarkIcon fontSize="large" sx={{ fontSize: '2rem' }} />} // Increased icon size
                onClick={handleAddBookmark}
                sx={{ mt: 1, fontSize: '1.2rem' }} // Increased font size
              >
                Add Bookmark
              </Button>
              {/* Add Bookmark Dialog */}
              <Dialog
                open={addBookmarkDialogOpen}
                onClose={() => setAddBookmarkDialogOpen(false)}
              >
                <DialogTitle sx={{ fontSize: '1.5rem' }}> {/* Increased font size */}
                  Add New Bookmark
                </DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Name"
                    fullWidth
                    value={newBookmark.name}
                    onChange={(e) => setNewBookmark({ ...newBookmark, name: e.target.value })}
                    variant="outlined"
                    sx={{ mb: 2, fontSize: '1.2rem' }} // Increased font size
                  />
                  <TextField
                    margin="dense"
                    label="URL"
                    fullWidth
                    value={newBookmark.url}
                    onChange={(e) => setNewBookmark({ ...newBookmark, url: e.target.value })}
                    variant="outlined"
                    sx={{ fontSize: '1.2rem' }} // Increased font size
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setAddBookmarkDialogOpen(false)}
                    color="primary"
                    sx={{ fontSize: '1.2rem' }} // Increased font size
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirmAddBookmark}
                    color="primary"
                    sx={{ fontSize: '1.2rem' }} // Increased font size
                  >
                    Add
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </Box>
      </Box>
    </StyledPaper>
  );
};

export default ProjectHeader;
