import React, { useState } from "react";
import { auth } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  Container,
  Typography,
  TextField,
  Button,
  Tabs,
  Tab,
  Paper,
  Alert,
  IconButton,
  InputAdornment,
  Box,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import GoogleIcon from "@mui/icons-material/Google";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Custom styles to match the images
const customStyles = {
  container: {
    maxWidth: "600px",
    marginTop: "100px",
  },
  paper: {
    padding: "40px",
    backgroundColor: "#f9f9f9", // Match background color
  },
  heading: {
    fontSize: "36px",
    fontWeight: "bold",
    fontFamily: "'Poppins', sans-serif", // Use exact font seen in images
    color: "#333333",
    textAlign: "center",
  },
  tabs: {
    marginBottom: "24px",
    fontFamily: "'Poppins', sans-serif",
    color: "#333333",
  },
  textField: {
    marginBottom: "16px",
    fontFamily: "'Poppins', sans-serif",
  },
  button: {
    marginTop: "20px",
    backgroundColor: "#99cc33", // Match green button color in images
    "&:hover": {
      backgroundColor: "#88bb33", // Slightly darker hover effect
    },
    color: "white",
    fontWeight: "bold",
  },
  googleButton: {
    borderColor: "#99cc33",
    color: "#333333",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "bold",
    marginTop: "10px",
  },
  alert: {
    marginBottom: "16px",
    fontFamily: "'Poppins', sans-serif",
  },
  orText: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "10px",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "bold",
  },
};

const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get("tab") === "signup" ? 1 : 0;

  const [tabValue, setTabValue] = useState(initialTab); // 0 for Login, 1 for Sign Up
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setError("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setError("");
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/app");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    setError("");
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate("/app");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate("/app");
    } catch (error) {
      setError(error.message);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Container maxWidth="sm" sx={customStyles.container}>
      <Paper elevation={3} sx={customStyles.paper}>
        <Typography variant="h4" align="center" gutterBottom sx={customStyles.heading}>
          CtrlPM
        </Typography>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          sx={customStyles.tabs}
        >
          <Tab label="Login" />
          <Tab label="Sign Up" />
        </Tabs>
        {error && (
          <Alert severity="error" sx={customStyles.alert}>
            {error}
          </Alert>
        )}
        {tabValue === 0 && (
          <form onSubmit={handleLogin}>
            <TextField
              fullWidth
              label="Email"
              type="email"
              margin="normal"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={customStyles.textField}
            />
            <TextField
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              margin="normal"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleShowPassword}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={customStyles.textField}
            />
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              sx={customStyles.button}
            >
              Login
            </Button>
            <Typography variant="body2" sx={customStyles.orText}>
              OR
            </Typography>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleSignIn}
              sx={customStyles.googleButton}
            >
              Continue with Google
            </Button>
          </form>
        )}
        {tabValue === 1 && (
          <form onSubmit={handleSignUp}>
            <TextField
              fullWidth
              label="Email"
              type="email"
              margin="normal"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={customStyles.textField}
            />
            <TextField
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              margin="normal"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleShowPassword}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={customStyles.textField}
            />
            <TextField
              fullWidth
              label="Confirm Password"
              type={showPassword ? "text" : "password"}
              margin="normal"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleShowPassword}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={customStyles.textField}
            />
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              sx={customStyles.button}
            >
              Sign Up
            </Button>
            <Typography variant="body2" sx={customStyles.orText}>
              OR
            </Typography>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleSignIn}
              sx={customStyles.googleButton}
            >
              Sign Up with Google
            </Button>
          </form>
        )}
      </Paper>
    </Container>
  );
};

export default LoginPage;
