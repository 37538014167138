import React from "react";
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  IconButton,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";

const FileUpload = ({ files, updateFiles }) => {
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        updateFiles([
          ...files,
          { name: file.name, content: content },
        ]);
      };
      reader.readAsText(file);
    }
  };

  const deleteFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    updateFiles(newFiles);
  };

  return (
    <Paper sx={{ padding: 3, marginBottom: 3, borderRadius: "8px" }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ color: "#00796b", fontWeight: "bold" }}
      >
        Project Files
      </Typography>
      <List>
        {files.map((file, index) => (
          <ListItem key={index} sx={{ display: "flex", alignItems: "center" }} button={true}>
            <ListItemText primary={file.name} />
            <Button
              variant="outlined"
              onClick={() => {
                const blob = new Blob([file.content], { type: "text/plain" });
                const url = URL.createObjectURL(blob);
                window.open(url, "_blank");
                URL.revokeObjectURL(url);
              }}
              sx={{
                marginRight: 2,
                borderRadius: "8px",
                color: "#00796b",
                borderColor: "#00796b",
                "&:hover": {
                  backgroundColor: "#e0f2f1",
                },
              }}
            >
              View
            </Button>
            <IconButton
              onClick={() => {
                const blob = new Blob([file.content], { type: "text/plain" });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = file.name;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
              }}
              sx={{ color: "#00796b" }}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton
              onClick={() => deleteFile(index)}
              sx={{ color: "#d32f2f" }}
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        component="label"
        startIcon={<UploadFileIcon />}
        sx={{
          marginTop: 2,
          backgroundColor: "#00796b",
          color: "#fff",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "#004d40",
          },
        }}
      >
        Upload File
        <input type="file" hidden onChange={handleFileUpload} />
      </Button>
      <Typography variant="caption" sx={{ marginTop: 1, display: "block" }}>
        Upload and manage files related to the project here.
      </Typography>
    </Paper>
  );
};

export default FileUpload;
