// src/components/Invoices.jsx

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  IconButton,
  Modal, // Correct import
  Snackbar,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHeader,
  TableHead,
  TableRow,
} from "./shadcn-ui"; // Ensure Modal is exported correctly
import {
  MdAddCircle,
  MdEdit,
  MdDelete,
  MdSave,
  MdCancel,
} from "react-icons/md";

// Custom TextInput Component
const TextInput = ({ label, value, onChange, type = "text", ...props }) => {
  return (
    <div className="flex flex-col">
      {label && (
        <label className="mb-1 text-sm font-medium text-[#222831]">
          {label}
        </label>
      )}
      <input
        type={type}
        value={value}
        onChange={onChange}
        className="px-3 py-2 border border-[#393E46] rounded-md focus:outline-none focus:ring-2 focus:ring-[#00ADB5]"
        {...props}
      />
    </div>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
};

// Custom SelectInput Component
const SelectInput = ({ label, value, onChange, options, ...props }) => {
  return (
    <div className="flex flex-col">
      {label && (
        <label className="mb-1 text-sm font-medium text-[#222831]">
          {label}
        </label>
      )}
      <select
        value={value}
        onChange={onChange}
        className="px-3 py-2 border border-[#393E46] rounded-md focus:outline-none focus:ring-2 focus:ring-[#00ADB5]"
        {...props}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

SelectInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ).isRequired,
};

const Invoices = ({ invoices, updateInvoices, currency, customCurrency }) => {
  // Add Invoice State
  const [newInvoiceNumber, setNewInvoiceNumber] = useState("");
  const [newInvoiceDescription, setNewInvoiceDescription] = useState("");
  const [newInvoiceAmount, setNewInvoiceAmount] = useState("");
  const [newInvoiceCategory, setNewInvoiceCategory] = useState("Client");
  const [newSharingMethod, setNewSharingMethod] = useState("email");
  const [newCustomSharingName, setNewCustomSharingName] = useState("");

  // Edit Invoice State
  const [editingInvoiceId, setEditingInvoiceId] = useState(null);
  const [editInvoiceNumber, setEditInvoiceNumber] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editAmount, setEditAmount] = useState("");
  const [editCategory, setEditCategory] = useState("Client");
  const [editSharingMethod, setEditSharingMethod] = useState("email");
  const [editCustomSharingName, setEditCustomSharingName] = useState("");

  // Modal State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  // Add Stage State
  const [newStageName, setNewStageName] = useState("");

  // Confirmation Modal State
  const [confirmDelete, setConfirmDelete] = useState({
    open: false,
    invoiceId: null,
  });

  // Snackbar State
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  // Sharing Method Options
  const sharingOptions = [
    { value: "email", label: "Email" },
    { value: "platform", label: "Platform" },
    { value: "etimad", label: "Etimad" },
    { value: "custom", label: "Custom" },
  ];

  // Initial Stages for Etimad and Custom
  const initialStages = [
    { stage: "Sent", date: new Date(), status: "completed" },
    { stage: "Awaiting Confirmation", date: null, status: "pending" },
    { stage: "Processing Payment", date: null, status: "pending" },
    { stage: "Collected", date: null, status: "pending" },
  ];

  // Function to open modal with selected invoice
  const openModal = (invoiceId) => {
    setSelectedInvoiceId(invoiceId);
    setIsModalOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setSelectedInvoiceId(null);
    setIsModalOpen(false);
    setNewStageName("");
    setEditingStageIndex(null); // Reset editing stage index when closing modal
    setEditStageName("");
  };

  // Function to add a new invoice
  const addInvoice = () => {
    if (
      newInvoiceNumber.trim() &&
      newInvoiceDescription.trim() &&
      newInvoiceAmount.trim()
    ) {
      if (newSharingMethod === "custom" && !newCustomSharingName.trim()) {
        setSnackbar({
          open: true,
          message: "Please enter a custom sharing method name.",
          severity: "error",
        });
        return;
      }

      const newInvoice = {
        id: Date.now(),
        invoiceNumber: newInvoiceNumber,
        description: newInvoiceDescription,
        amount: parseFloat(newInvoiceAmount),
        category: newInvoiceCategory,
        status: "pending",
        issueDate: new Date(),
        collectedDate: null, // For Client
        dateWithPaid: null, // For Vendor
        sharingMethod: newSharingMethod,
        sharingName:
          newSharingMethod === "custom" ? newCustomSharingName.trim() : null,
        stages:
          newSharingMethod === "etimad" || newSharingMethod === "custom"
            ? initialStages.map((stage) => ({ ...stage }))
            : null,
      };

      updateInvoices([...invoices, newInvoice]);

      // Reset fields
      setNewInvoiceNumber("");
      setNewInvoiceDescription("");
      setNewInvoiceAmount("");
      setNewInvoiceCategory("Client");
      setNewSharingMethod("email");
      setNewCustomSharingName("");

      setSnackbar({
        open: true,
        message: "Invoice added successfully!",
        severity: "success",
      });
    } else {
      setSnackbar({
        open: true,
        message: "Please enter invoice number, description, and amount.",
        severity: "error",
      });
    }
  };

  // Function to start editing an invoice
  const editInvoice = (invoice) => {
    setEditingInvoiceId(invoice.id);
    setEditInvoiceNumber(invoice.invoiceNumber);
    setEditDescription(invoice.description);
    setEditAmount(invoice.amount.toString());
    setEditCategory(invoice.category);
    setEditSharingMethod(invoice.sharingMethod);
    setEditCustomSharingName(
      invoice.sharingMethod === "custom" ? invoice.sharingName : ""
    );
  };

  // Function to save edited invoice
  const saveInvoiceEdit = (id) => {
    if (
      editInvoiceNumber.trim() &&
      editDescription.trim() &&
      editAmount.trim()
    ) {
      if (editSharingMethod === "custom" && !editCustomSharingName.trim()) {
        setSnackbar({
          open: true,
          message: "Please enter a custom sharing method name.",
          severity: "error",
        });
        return;
      }

      const updatedInvoices = invoices.map((invoice) => {
        if (invoice.id === id) {
          // Reset dates if category changes
          let updatedCollectedDate = invoice.collectedDate;
          let updatedDateWithPaid = invoice.dateWithPaid;

          if (invoice.category !== editCategory) {
            updatedCollectedDate =
              editCategory === "Client" ? invoice.collectedDate : null;
            updatedDateWithPaid =
              editCategory === "Vendor" ? invoice.dateWithPaid : null;
          }

          return {
            ...invoice,
            invoiceNumber: editInvoiceNumber,
            description: editDescription,
            amount: parseFloat(editAmount),
            category: editCategory,
            sharingMethod: editSharingMethod,
            sharingName:
              editSharingMethod === "custom"
                ? editCustomSharingName.trim()
                : null,
            collectedDate: updatedCollectedDate,
            dateWithPaid: updatedDateWithPaid,
            stages:
              editSharingMethod === "etimad" || editSharingMethod === "custom"
                ? invoice.stages || initialStages.map((stage) => ({ ...stage }))
                : null,
          };
        }
        return invoice;
      });

      updateInvoices(updatedInvoices);

      // Reset edit states
      setEditingInvoiceId(null);
      setEditInvoiceNumber("");
      setEditDescription("");
      setEditAmount("");
      setEditCategory("Client");
      setEditSharingMethod("email");
      setEditCustomSharingName("");

      setSnackbar({
        open: true,
        message: "Invoice updated successfully!",
        severity: "success",
      });
    } else {
      setSnackbar({
        open: true,
        message: "Please enter invoice number, description, and amount.",
        severity: "error",
      });
    }
  };

  // Function to mark invoice as paid
  const markAsPaid = (id) => {
    const updatedInvoices = invoices.map((invoice) => {
      if (invoice.id === id) {
        const updatedInvoice = { ...invoice, status: "paid" };
        if (invoice.category === "Client") {
          updatedInvoice.collectedDate = new Date();
        } else if (invoice.category === "Vendor") {
          updatedInvoice.dateWithPaid = new Date();
        }
        return updatedInvoice;
      }
      return invoice;
    });
    updateInvoices(updatedInvoices);

    setSnackbar({
      open: true,
      message: "Invoice marked as paid.",
      severity: "success",
    });
  };

  // Function to delete an invoice
  const deleteInvoice = (id) => {
    const updatedInvoices = invoices.filter((invoice) => invoice.id !== id);
    updateInvoices(updatedInvoices);

    setSnackbar({
      open: true,
      message: "Invoice deleted successfully.",
      severity: "info",
    });
  };

  // Function to update a stage's status to completed
  const updateStageStatus = (id, stageIndex) => {
    const updatedInvoices = invoices.map((invoice) => {
      if (
        invoice.id === id &&
        (invoice.sharingMethod === "etimad" || invoice.sharingMethod === "custom")
      ) {
        const updatedStages = invoice.stages.map((stage, index) => {
          if (index === stageIndex) {
            return { ...stage, status: "completed", date: new Date() };
          }
          return stage;
        });

        // Check if all stages are completed
        const allStagesCompleted = updatedStages.every(
          (stage) => stage.status === "completed"
        );

        const updatedInvoice = { ...invoice, stages: updatedStages };
        if (allStagesCompleted) {
          updatedInvoice.status = "paid";
          if (invoice.category === "Client") {
            updatedInvoice.collectedDate = new Date();
          } else if (invoice.category === "Vendor") {
            updatedInvoice.dateWithPaid = new Date();
          }
        }

        return updatedInvoice;
      }
      return invoice;
    });
    updateInvoices(updatedInvoices);

    setSnackbar({
      open: true,
      message: "Stage marked as completed.",
      severity: "success",
    });
  };

  // Function to add a new stage
  const addStage = (id, stageName) => {
    const updatedInvoices = invoices.map((invoice) => {
      if (
        invoice.id === id &&
        (invoice.sharingMethod === "etimad" || invoice.sharingMethod === "custom")
      ) {
        return {
          ...invoice,
          stages: [
            ...(invoice.stages || []),
            { stage: stageName, date: null, status: "pending" },
          ],
        };
      }
      return invoice;
    });
    updateInvoices(updatedInvoices);

    setSnackbar({
      open: true,
      message: "New stage added.",
      severity: "success",
    });
  };

  // Function to delete a stage
  const deleteStage = (id, stageIndex) => {
    const updatedInvoices = invoices.map((invoice) => {
      if (
        invoice.id === id &&
        (invoice.sharingMethod === "etimad" || invoice.sharingMethod === "custom")
      ) {
        const updatedStages = invoice.stages.filter((_, index) => index !== stageIndex);
        return { ...invoice, stages: updatedStages };
      }
      return invoice;
    });
    updateInvoices(updatedInvoices);

    setSnackbar({
      open: true,
      message: "Stage deleted successfully.",
      severity: "info",
    });
  };

  // Function to handle Delete Confirmation
  const handleDeleteConfirmation = (id) => {
    setConfirmDelete({ open: true, invoiceId: id });
  };

  const confirmDeleteInvoice = () => {
    const { invoiceId } = confirmDelete;
    if (invoiceId !== null) {
      deleteInvoice(invoiceId);
      setConfirmDelete({ open: false, invoiceId: null });
    }
  };

  const cancelDeleteConfirmation = () => {
    setConfirmDelete({ open: false, invoiceId: null });
  };

  // Function to handle adding a new stage via modal
  const handleAddStage = () => {
    if (newStageName.trim()) {
      addStage(selectedInvoiceId, newStageName.trim());
      setNewStageName("");
    } else {
      setSnackbar({
        open: true,
        message: "Stage name cannot be empty.",
        severity: "error",
      });
    }
  };

  // Function to handle editing a stage
  const [editingStageIndex, setEditingStageIndex] = useState(null);
  const [editStageName, setEditStageName] = useState("");

  const handleEditStage = (index, currentName) => {
    setEditingStageIndex(index);
    setEditStageName(currentName);
  };

  const handleSaveStage = (id, index) => {
    if (editStageName.trim()) {
      const updatedInvoices = invoices.map((invoice) => {
        if (invoice.id === id) {
          const updatedStages = invoice.stages.map((stage, idx) => {
            if (idx === index) {
              return { ...stage, stage: editStageName.trim() };
            }
            return stage;
          });
          return { ...invoice, stages: updatedStages };
        }
        return invoice;
      });
      updateInvoices(updatedInvoices);
      setEditingStageIndex(null);
      setEditStageName("");

      setSnackbar({
        open: true,
        message: "Stage updated successfully!",
        severity: "success",
      });
    } else {
      setSnackbar({
        open: true,
        message: "Stage name cannot be empty.",
        severity: "error",
      });
    }
  };

  const handleCancelEditStage = () => {
    setEditingStageIndex(null);
    setEditStageName("");
  };

  // Effect to reset editing state if the invoice is deleted
  useEffect(() => {
    if (!invoices.find((inv) => inv.id === editingInvoiceId)) {
      setEditingInvoiceId(null);
    }
  }, [invoices, editingInvoiceId]);

  return (
    <div className="invoice-container bg-[#EEEEEE] min-h-screen p-6">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold mb-4 text-[#222831]">Invoices</h1>

        {/* Invoices Table */}
        <div className="overflow-x-auto">
          <Table className="min-w-full divide-y divide-[#393E46]">
            <TableCaption className="text-[#222831]">
              Manage your project invoices below.
            </TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="px-6 py-3 text-left text-sm font-medium text-[#222831] uppercase tracking-wider">
                  Invoice #
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-sm font-medium text-[#222831] uppercase tracking-wider">
                  Description
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-sm font-medium text-[#222831] uppercase tracking-wider">
                  Amount
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-sm font-medium text-[#222831] uppercase tracking-wider">
                  Category
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-sm font-medium text-[#222831] uppercase tracking-wider">
                  Issued
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-sm font-medium text-[#222831] uppercase tracking-wider">
                  Shared Via
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-sm font-medium text-[#222831] uppercase tracking-wider">
                  Status
                </TableHead>
                {/* New Column: Collected / Paid Date */}
                <TableHead className="px-6 py-3 text-left text-sm font-medium text-[#222831] uppercase tracking-wider">
                  Collected / Paid Date
                </TableHead>
                {/* New Column: View Stages */}
                <TableHead className="px-6 py-3 text-center text-sm font-medium text-[#222831] uppercase tracking-wider">
                  View Stages
                </TableHead>
                {/* Existing Column: Actions */}
                <TableHead className="px-6 py-3 text-center text-sm font-medium text-[#222831] uppercase tracking-wider">
                  Actions
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {invoices.map((invoice) => (
                <React.Fragment key={invoice.id}>
                  <TableRow className="bg-white hover:bg-[#f0f4f8]">
                    <TableCell className="px-6 py-4 whitespace-nowrap text-[#222831]">
                      # {invoice.invoiceNumber}
                    </TableCell>
                    <TableCell className="px-6 py-4 whitespace-nowrap text-[#222831]">
                      {invoice.description}
                    </TableCell>
                    <TableCell className="px-6 py-4 whitespace-nowrap text-[#222831]">
                      {currency === "custom" ? customCurrency : currency}{" "}
                      {invoice.amount ? invoice.amount.toFixed(2) : "0.00"}
                    </TableCell>
                    <TableCell className="px-6 py-4 whitespace-nowrap text-[#222831]">
                      {invoice.category}
                    </TableCell>
                    <TableCell className="px-6 py-4 whitespace-nowrap text-[#222831]">
                      {new Date(invoice.issueDate).toLocaleDateString()}
                    </TableCell>
                    <TableCell className="px-6 py-4 whitespace-nowrap text-[#222831]">
                      {invoice.sharingMethod === "custom"
                        ? invoice.sharingName
                        : invoice.sharingMethod
                        ? invoice.sharingMethod.charAt(0).toUpperCase() +
                          invoice.sharingMethod.slice(1)
                        : "N/A"}
                    </TableCell>
                    <TableCell className="px-6 py-4 whitespace-nowrap text-[#222831]">
                      {invoice.status === "paid" ? "Paid" : "Pending"}
                    </TableCell>
                    {/* New Column: Collected / Paid Date */}
                    <TableCell className="px-6 py-4 whitespace-nowrap text-[#222831]">
                      {invoice.status === "paid" ? (
                        invoice.category === "Client" && invoice.collectedDate ? (
                          new Date(invoice.collectedDate).toLocaleDateString()
                        ) : invoice.category === "Vendor" && invoice.dateWithPaid ? (
                          new Date(invoice.dateWithPaid).toLocaleDateString()
                        ) : (
                          "N/A"
                        )
                      ) : (
                        <Button
                          variant="primary"
                          onClick={() => markAsPaid(invoice.id)}
                          className="bg-[#00ADB5] hover:bg-[#009BA8] text-white px-3 py-1 rounded-md w-full flex items-center justify-center"
                        >
                          Mark as Paid
                        </Button>
                      )}
                    </TableCell>
                    {/* New Column: View Stages */}
                    <TableCell className="px-6 py-4 whitespace-nowrap text-center">
                      {(invoice.sharingMethod === "etimad" ||
                        invoice.sharingMethod === "custom") && (
                        <Button
                          variant="secondary"
                          onClick={() => openModal(invoice.id)}
                          className="bg-[#00ADB5] hover:bg-[#009BA8] text-white px-3 py-1 rounded-md flex items-center justify-center"
                          aria-label={`View stages for invoice ${invoice.invoiceNumber}`}
                        >
                          View Stages
                        </Button>
                      )}
                    </TableCell>
                    {/* Existing Column: Actions */}
                    <TableCell className="px-6 py-4 whitespace-nowrap text-center">
                      <div className="flex justify-center space-x-2">
                        {/* Edit Button */}
                        <IconButton
                          ariaLabel={`Edit invoice ${invoice.invoiceNumber}`}
                          onClick={() => editInvoice(invoice)}
                          className="text-[#222831] hover:text-[#00ADB5]"
                        >
                          <MdEdit size={20} />
                        </IconButton>

                        {/* Delete Button */}
                        <IconButton
                          ariaLabel={`Delete invoice ${invoice.invoiceNumber}`}
                          onClick={() => handleDeleteConfirmation(invoice.id)}
                          className="text-[#222831] hover:text-red-600"
                        >
                          <MdDelete size={20} />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </div>

        {/* Modal for Viewing Stages */}
        {selectedInvoiceId && (
          <Modal
            open={isModalOpen}
            onOpenChange={setIsModalOpen}
            title={`Stages for Invoice #${invoices.find(inv => inv.id === selectedInvoiceId)?.invoiceNumber}`}
            className="w-auto max-w-full"
          >
            <div className="p-6 bg-[#EEEEEE] rounded-lg shadow-lg overflow-y-auto max-h-screen">
              {/* Display Collected Date or Date With Paid */}
              {(() => {
                const invoice = invoices.find((inv) => inv.id === selectedInvoiceId);
                if (invoice && invoice.status === "paid") {
                  if (invoice.category === "Client" && invoice.collectedDate) {
                    return (
                      <p className="mb-4 text-[#222831]">
                        <strong>Collected Date:</strong>{" "}
                        {new Date(invoice.collectedDate).toLocaleDateString()}
                      </p>
                    );
                  } else if (invoice.category === "Vendor" && invoice.dateWithPaid) {
                    return (
                      <p className="mb-4 text-[#222831]">
                        <strong>Date With Paid:</strong>{" "}
                        {new Date(invoice.dateWithPaid).toLocaleDateString()}
                      </p>
                    );
                  }
                }
                return null;
              })()}

              {/* Stages Table */}
              <Table className="min-w-full divide-y divide-[#393E46] mb-6">
                <TableHeader>
                  <TableRow>
                    <TableHead className="px-6 py-3 text-left text-sm font-medium text-[#222831] uppercase tracking-wider">
                      Stage
                    </TableHead>
                    <TableHead className="px-6 py-3 text-left text-sm font-medium text-[#222831] uppercase tracking-wider">
                      Date
                    </TableHead>
                    <TableHead className="px-6 py-3 text-left text-sm font-medium text-[#222831] uppercase tracking-wider">
                      Status
                    </TableHead>
                    <TableHead className="px-6 py-3 text-center text-sm font-medium text-[#222831] uppercase tracking-wider">
                      Actions
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {(() => {
                    const invoice = invoices.find((inv) => inv.id === selectedInvoiceId);
                    return invoice.stages?.map((stage, index) => (
                      <TableRow
                        key={index}
                        className="bg-white hover:bg-[#f0f4f8]"
                      >
                        <TableCell className="px-6 py-4 whitespace-nowrap text-[#222831]">
                          {editingStageIndex === index ? (
                            <TextInput
                              label="Stage Name"
                              value={editStageName}
                              onChange={(e) =>
                                setEditStageName(e.target.value)
                              }
                            />
                          ) : (
                            stage.stage
                          )}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-[#222831]">
                          {stage.date
                            ? new Date(stage.date).toLocaleDateString()
                            : "Pending"}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-[#222831]">
                          {stage.status === "completed"
                            ? "Completed"
                            : "Pending"}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-center">
                          <div className="flex justify-center space-x-2">
                            {/* Mark as Completed Button */}
                            {stage.status !== "completed" && (
                              <Button
                                variant="primary"
                                onClick={() =>
                                  updateStageStatus(selectedInvoiceId, index)
                                }
                                className="bg-[#00ADB5] hover:bg-[#009BA8] text-white px-3 py-1 rounded-md"
                              >
                                Mark as Completed
                              </Button>
                            )}

                            {/* Edit Stage */}
                            {editingStageIndex === index ? (
                              <>
                                {/* Save Button */}
                                <IconButton
                                  ariaLabel="Save Stage"
                                  onClick={() =>
                                    handleSaveStage(selectedInvoiceId, index)
                                  }
                                  className="text-[#222831] hover:text-[#00ADB5]"
                                >
                                  <MdSave size={20} />
                                </IconButton>
                                {/* Cancel Button */}
                                <IconButton
                                  ariaLabel="Cancel Editing"
                                  onClick={() =>
                                    handleCancelEditStage()
                                  }
                                  className="text-[#222831] hover:text-red-600"
                                >
                                  <MdCancel size={20} />
                                </IconButton>
                              </>
                            ) : (
                              <IconButton
                                ariaLabel="Edit Stage"
                                onClick={() => handleEditStage(index, stage.stage)}
                                className="text-[#222831] hover:text-[#00ADB5]"
                              >
                                <MdEdit size={20} />
                              </IconButton>
                            )}

                            {/* Delete Stage */}
                            <IconButton
                              ariaLabel="Delete Stage"
                              onClick={() => deleteStage(selectedInvoiceId, index)}
                              className="text-[#222831] hover:text-red-600"
                            >
                              <MdDelete size={20} />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ));
                  })()}
                </TableBody>
              </Table>
              {/* Add Stage Form */}
              <div className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-4">
                <TextInput
                  label="New Stage Name"
                  value={newStageName}
                  onChange={(e) => setNewStageName(e.target.value)}
                />
                <Button
                  variant="primary"
                  onClick={handleAddStage}
                  className="bg-[#00ADB5] hover:bg-[#009BA8] text-white px-4 py-2 rounded-md flex items-center justify-center"
                >
                  <MdAddCircle size={20} className="mr-2" />
                  Add Stage
                </Button>
              </div>
            </div>
          </Modal>
        )}

        {/* Add Invoice Form */}
        <div className="mt-8 p-6 bg-white rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold mb-4 text-[#222831]">
            Add New Invoice
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <TextInput
              label="Invoice Number"
              value={newInvoiceNumber}
              onChange={(e) => setNewInvoiceNumber(e.target.value)}
            />
            <TextInput
              label="Description"
              value={newInvoiceDescription}
              onChange={(e) => setNewInvoiceDescription(e.target.value)}
            />
            <TextInput
              label="Amount"
              type="number"
              value={newInvoiceAmount}
              onChange={(e) => setNewInvoiceAmount(e.target.value)}
            />
            <SelectInput
              label="Category"
              value={newInvoiceCategory}
              onChange={(e) => setNewInvoiceCategory(e.target.value)}
              options={[
                { value: "Client", label: "Client" },
                { value: "Vendor", label: "Vendor" },
              ]}
            />
            <SelectInput
              label="Sharing Method"
              value={newSharingMethod}
              onChange={(e) => setNewSharingMethod(e.target.value)}
              options={sharingOptions}
            />
            {newSharingMethod === "custom" && (
              <TextInput
                label="Custom Sharing Name"
                value={newCustomSharingName}
                onChange={(e) => setNewCustomSharingName(e.target.value)}
              />
            )}
          </div>
          <div className="mt-6">
            <Button
              variant="primary"
              onClick={addInvoice}
              className="flex items-center space-x-1 bg-[#00ADB5] hover:bg-[#009BA8] text-white px-4 py-2 rounded-md"
            >
              <MdAddCircle size={20} />
              <span>Add Invoice</span>
            </Button>
          </div>
        </div>

        {/* Confirmation Modal for Deleting Invoice */}
        {confirmDelete.open && (
          <Modal
            open={confirmDelete.open}
            onOpenChange={(open) =>
              setConfirmDelete((prev) => ({ ...prev, open }))
            }
            title="Confirm Deletion"
            className="w-auto max-w-md"
          >
            <div className="p-6 bg-[#EEEEEE] rounded-lg shadow-lg">
              <p className="mb-4 text-[#222831]">
                Are you sure you want to delete this invoice? This action cannot be
                undone.
              </p>
              <div className="flex justify-end space-x-4">
                <Button
                  variant="secondary"
                  onClick={cancelDeleteConfirmation}
                  className="bg-[#393E46] hover:bg-[#222831] text-white px-4 py-2 rounded-md"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={confirmDeleteInvoice}
                  className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md"
                >
                  Delete
                </Button>
              </div>
            </div>
          </Modal>
        )}

        {/* Snackbar for Notifications */}
        <Snackbar
          open={snackbar.open}
          message={snackbar.message}
          severity={snackbar.severity}
          onClose={() =>
            setSnackbar({
              ...snackbar,
              open: false,
              message: "",
              severity: "info",
            })
          }
        />
      </div>
    </div>
  );
};

// Define propTypes outside the component function
Invoices.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      invoiceNumber: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      category: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      issueDate: PropTypes.instanceOf(Date).isRequired,
      collectedDate: PropTypes.instanceOf(Date),
      dateWithPaid: PropTypes.instanceOf(Date),
      sharingMethod: PropTypes.string,
      sharingName: PropTypes.string,
      stages: PropTypes.arrayOf(
        PropTypes.shape({
          stage: PropTypes.string.isRequired,
          date: PropTypes.instanceOf(Date),
          status: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  updateInvoices: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  customCurrency: PropTypes.string.isRequired,
};

export default Invoices;
