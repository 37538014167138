// src/components/Notification.jsx

import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  Button,
  Tabs,
  Tab,
  Badge,
  FormControlLabel,
  Switch,
  TextField,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Event as EventIcon,
  Message as MessageIcon,
  Flag as FlagIcon,
  Notifications as NotificationsIcon,
  MarkEmailRead as MarkEmailReadIcon,
  MarkEmailUnread as MarkEmailUnreadIcon,
  AccessTime as AccessTimeIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../contexts/NotificationContext"; // Correct import

/**
 * NotificationComponent
 * 
 * The main component that displays notifications and manages notification settings.
 */
const NotificationComponent = () => {
  const {
    notifications,
    removeNotification,
    clearNotifications,
    notificationPrefs,
    toggleNotificationReadStatus,
    weeklyReportSchedule,
    updateWeeklyReportSchedule,
    toggleNotificationPref,
  } = useContext(NotificationContext);

  const navigate = useNavigate();

  // State for current tab
  const [currentTab, setCurrentTab] = useState("all");

  // Local state for weekly report time
  const [day, setDay] = useState(weeklyReportSchedule.day || "Monday");
  const [time, setTime] = useState(weeklyReportSchedule.time || "12:00");

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  // Handle weekly report schedule submission
  const handleScheduleSubmit = (e) => {
    e.preventDefault();
    updateWeeklyReportSchedule({ day, time });
    alert("Weekly report schedule updated!");
  };

  // Function to get icon based on notification type
  const getIcon = (type) => {
    switch (type) {
      case "pendingInvoices":
        return <EventIcon color="primary" />;
      case "weeklyReportReminders":
        return <MessageIcon color="secondary" />;
      case "riskAdded":
        return <FlagIcon color="error" />;
      case "delayedTasks":
        return <NotificationsIcon color="warning" />;
      default:
        return <NotificationsIcon />;
    }
  };

  // Filter notifications based on current tab and user preferences
  const filteredNotifications = notifications.filter((notification) => {
    if (currentTab === "all") return notificationPrefs[notification.type];
    return (
      notification.type === currentTab && notificationPrefs[notification.type]
    );
  });

  return (
    <Box sx={{ p: 2, backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
      <Paper elevation={3} sx={{ p: 4, maxWidth: 900, margin: "0 auto" }}>
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Typography variant="h4">Notifications</Typography>
          <IconButton onClick={() => navigate(-1)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider sx={{ mb: 3 }} />

        {/* Tabs for Notification Types */}
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ mb: 3 }}
        >
          <Tab label="All" value="all" />
          <Tab
            label={
              <Badge
                badgeContent={
                  notifications.filter(
                    (n) =>
                      n.type === "pendingInvoices" &&
                      !n.read &&
                      notificationPrefs.pendingInvoices,
                  ).length
                }
                color="primary"
              >
                Pending Invoices
              </Badge>
            }
            value="pendingInvoices"
          />
          <Tab
            label={
              <Badge
                badgeContent={
                  notifications.filter(
                    (n) =>
                      n.type === "weeklyReportReminders" &&
                      !n.read &&
                      notificationPrefs.weeklyReportReminders,
                  ).length
                }
                color="secondary"
              >
                Weekly Reports
              </Badge>
            }
            value="weeklyReportReminders"
          />
          <Tab
            label={
              <Badge
                badgeContent={
                  notifications.filter(
                    (n) =>
                      n.type === "riskAdded" &&
                      !n.read &&
                      notificationPrefs.riskAdded,
                  ).length
                }
                color="error"
              >
                Risks Added
              </Badge>
            }
            value="riskAdded"
          />
          <Tab
            label={
              <Badge
                badgeContent={
                  notifications.filter(
                    (n) =>
                      n.type === "delayedTasks" &&
                      !n.read &&
                      notificationPrefs.delayedTasks,
                  ).length
                }
                color="warning"
              >
                Delayed Tasks
              </Badge>
            }
            value="delayedTasks"
          />
        </Tabs>

        {/* Notification List */}
        {filteredNotifications.length > 0 ? (
          <List>
            {filteredNotifications.map((notification) => (
              <ListItem
                key={notification.id}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => removeNotification(notification.id)}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                sx={{
                  backgroundColor: notification.read ? "#fff" : "#e3f2fd",
                  mb: 1,
                  borderRadius: 1,
                }}
              >
                <ListItemIcon>{getIcon(notification.type)}</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: notification.read ? "normal" : "bold" }}
                    >
                      {notification.title}
                    </Typography>
                  }
                  secondary={notification.message}
                />
                {/* Mark as Read/Unread */}
                <IconButton
                  edge="end"
                  aria-label="mark"
                  onClick={() => toggleNotificationReadStatus(notification.id, notification.read)}
                >
                  {notification.read ? (
                    <MarkEmailUnreadIcon />
                  ) : (
                    <MarkEmailReadIcon />
                  )}
                </IconButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="h6" align="center">
            No notifications to display.
          </Typography>
        )}

        {/* Clear All Button */}
        {notifications.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="error"
              onClick={clearNotifications}
            >
              Clear All Notifications
            </Button>
          </Box>
        )}

        {/* Divider */}
        <Divider sx={{ my: 3 }} />

        {/* Notification Settings */}
        <Typography variant="h5" sx={{ mb: 2 }}>
          Notification Settings
        </Typography>
        <Box sx={{ mb: 4 }}>
          <FormControlLabel
            control={
              <Switch
                checked={notificationPrefs.pendingInvoices}
                onChange={() => toggleNotificationPref("pendingInvoices")}
                color="primary"
              />
            }
            label="Pending Invoices"
          />
          <FormControlLabel
            control={
              <Switch
                checked={notificationPrefs.weeklyReportReminders}
                onChange={() => toggleNotificationPref("weeklyReportReminders")}
                color="primary"
              />
            }
            label="Weekly Report Reminders"
          />
          <FormControlLabel
            control={
              <Switch
                checked={notificationPrefs.riskAdded}
                onChange={() => toggleNotificationPref("riskAdded")}
                color="primary"
              />
            }
            label="Risks Added"
          />
          <FormControlLabel
            control={
              <Switch
                checked={notificationPrefs.delayedTasks}
                onChange={() => toggleNotificationPref("delayedTasks")}
                color="primary"
              />
            }
            label="Delayed Tasks"
          />
        </Box>

        {/* Weekly Report Schedule */}
        {notificationPrefs.weeklyReportReminders && (
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Weekly Report Schedule
            </Typography>
            <form onSubmit={handleScheduleSubmit}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    label="Day of the Week"
                    value={day}
                    onChange={(e) => setDay(e.target.value)}
                    SelectProps={{
                      native: true,
                    }}
                    fullWidth
                    InputProps={{
                      startAdornment: <AccessTimeIcon sx={{ mr: 1 }} />,
                    }}
                  >
                    {[
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday",
                      "Sunday",
                    ].map((dayOption) => (
                      <option key={dayOption} value={dayOption}>
                        {dayOption}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Time"
                    type="time"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    fullWidth
                    InputProps={{
                      startAdornment: <AccessTimeIcon sx={{ mr: 1 }} />,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Save Schedule
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

/**
 * Notification Component
 * 
 * This component is a wrapper to render the NotificationComponent.
 */
const Notification = () => <NotificationComponent />;

export default Notification;
