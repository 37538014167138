// src/components/ErrorBoundary.jsx

import React from "react";
import { Typography, Box, Button } from "@mui/material";
// Optional: Uncomment the next lines if you decide to integrate Sentry
// import * as Sentry from "@sentry/react";
// import { withRouter } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state to display the fallback UI on the next render
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error details to the console
    console.error("ErrorBoundary caught an error:", error, errorInfo);
    this.setState({ errorInfo });

    // Optional: Report error to Sentry
    // Sentry.captureException(error, { extra: errorInfo });
  }

  handleReload = () => {
    window.location.reload();
  };

  handleRetry = () => {
    this.setState({ hasError: false, error: null, errorInfo: null });
  };

  handleGoHome = () => {
    window.location.href = "/"; // Navigate to the home page
  };

  render() {
    if (this.state.hasError) {
      // Fallback UI when an error is caught
      return (
        <Box
          sx={{
            padding: 4,
            textAlign: "center",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
          }}
        >
          <Typography variant="h4" color="error" gutterBottom>
            Oops! Something went wrong.
          </Typography>
          <Typography variant="body1" sx={{ maxWidth: 600 }}>
            An unexpected error has occurred. Please try the following options:
          </Typography>
          <Box sx={{ mt: 3, display: "flex", gap: 2, flexWrap: "wrap", justifyContent: "center" }}>
            <Button variant="contained" color="primary" onClick={this.handleReload}>
              Reload Page
            </Button>
            <Button variant="outlined" color="secondary" onClick={this.handleRetry}>
              Try Again
            </Button>
            <Button variant="text" color="secondary" onClick={this.handleGoHome}>
              Go to Home
            </Button>
          </Box>
          {this.state.error && (
            <Box sx={{ mt: 4, textAlign: "left", maxWidth: 600 }}>
              <Typography variant="subtitle1">Error Details:</Typography>
              <Typography variant="body2" color="textSecondary">
                {this.state.error.toString()}
              </Typography>
              {this.state.errorInfo && (
                <Typography variant="body2" color="textSecondary" sx={{ whiteSpace: "pre-wrap" }}>
                  {this.state.errorInfo.componentStack}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      );
    }

    // Render children components when no error is present
    return this.props.children;
  }
}

// Optional: Wrap withRouter if you need access to routing props
// export default withRouter(ErrorBoundary);
export default ErrorBoundary;
