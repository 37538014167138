import React, { useState, useEffect } from 'react';
import {
  Button, Typography, Box, TextField,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Checkbox, FormControlLabel, Select, MenuItem, InputLabel, FormControl,
  IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Grid
} from '@mui/material';
import * as XLSX from 'xlsx';
import { parseISO, parse, isValid, format, differenceInDays, isAfter, isEqual } from 'date-fns';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Info as InfoIcon
} from '@mui/icons-material';

const TimelineImport = () => {
  const [importError, setImportError] = useState(null);
  const [importedTasks, setImportedTasks] = useState([]);
  const [debugInfo, setDebugInfo] = useState(null);
  const [debugDialogOpen, setDebugDialogOpen] = useState(false);
  const [rawData, setRawData] = useState(null);
  const [columnMapping, setColumnMapping] = useState({});
  const [headers, setHeaders] = useState([]);
  const [headerSamples, setHeaderSamples] = useState({});
  const [newTask, setNewTask] = useState({
    id: '',
    name: '',
    start: '',
    end: '',
    responsible: '',
    completionPercentage: '',
    delayed: false,
    delayReason: '',
    delayedDate: null,
  });
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileURL, setUploadedFileURL] = useState(null);
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [addTaskDialogOpen, setAddTaskDialogOpen] = useState(false);
  const [insertIndex, setInsertIndex] = useState(null);
  const [showMapping, setShowMapping] = useState(false);
  const [showTasks, setShowTasks] = useState(true);
  const [upcomingMilestones, setUpcomingMilestones] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setUploadedFile(file);
    setUploadedFileURL(URL.createObjectURL(file));

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false, defval: null });
        setRawData(jsonData);
        const headers = Object.keys(jsonData[0] || {});
        setHeaders(headers);

        const samples = {};
        headers.forEach(header => {
          samples[header] = jsonData
            .map(row => row[header])
            .filter(value => value != null && value !== '')
            .slice(0, 4);
        });
        setHeaderSamples(samples);

      } catch (error) {
        console.error('Error parsing Excel file:', error);
        setImportError('Error parsing Excel file. Please check the file format.');
      }
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    const requiredFields = ['id', 'name', 'start', 'end'];
    const missingMappings = requiredFields.filter(field => !columnMapping[field]);
    if (rawData && Object.keys(columnMapping).length >= requiredFields.length && missingMappings.length === 0) {
      processImportedData(rawData);
    } else if (missingMappings.length > 0) {
      setImportError(`Please map the following required fields: ${missingMappings.join(', ')}`);
    }
  }, [rawData, columnMapping]);

  const getColumnValue = (row, field) => {
    const columnKey = columnMapping[field];
    return columnKey ? row[columnKey] : null;
  };

  const parseDate = (dateString) => {
    if (!dateString) return null;
    if (typeof dateString === 'number') {
      const date = new Date(1899, 11, 30);
      date.setDate(date.getDate() + dateString);
      return date;
    }
    if (dateString instanceof Date) return dateString;
    let date = parseISO(dateString);
    if (isValid(date)) return date;
    const formats = [
      'yyyy-MM-dd', 'MM/dd/yyyy', 'dd/MM/yyyy', 'yyyy/MM/dd',
      'dd.MM.yyyy', 'MM-dd-yyyy', 'MM/dd/yy', 'dd/MM/yy',
      'dd-MM-yyyy', 'dd MMM yyyy', 'MMM dd, yyyy',
    ];
    for (let fmt of formats) {
      date = parse(dateString, fmt, new Date());
      if (isValid(date)) return date;
    }
    const timestamp = Date.parse(dateString);
    if (!isNaN(timestamp)) {
      return new Date(timestamp);
    }
    console.warn(`Unable to parse date: ${dateString}`);
    return null;
  };

  const formatDate = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      return format(date, 'yyyy-MM-dd');
    }
    return '';
  };

  const formatSampleValues = (values) => {
    return values.map(value => {
      if (typeof value === 'string') {
        return value.length > 15 ? value.substring(0, 12) + '...' : value;
      } else if (typeof value === 'number') {
        return value.toString();
      } else {
        return String(value);
      }
    }).join(', ');
  };

  const processImportedData = (data) => {
    try {
      console.log('Raw imported data:', data);

      const formattedTasks = data.map((row, index) => {
        const startDate = parseDate(getColumnValue(row, 'start'));
        let endDate = parseDate(getColumnValue(row, 'end'));

        if (!endDate && startDate) {
          endDate = startDate;
        }

        const task = {
          id: getColumnValue(row, 'id') || `task-${index + 1}`,
          name: getColumnValue(row, 'name') || `Task ${index + 1}`,
          start: startDate,
          end: endDate,
          responsible: getColumnValue(row, 'responsible'),
          completionPercentage: parseFloat(getColumnValue(row, 'completion')) || 0,
          delayed: false,
          delayReason: '',
          delayedDate: null,
          level: (getColumnValue(row, 'id') || '').split('.').length - 1,
          rawStart: getColumnValue(row, 'start'),
          rawEnd: getColumnValue(row, 'end'),
          rawCompletionPercentage: getColumnValue(row, 'completion'),
          isValid: !!startDate && !!endDate,
        };

        return task;
      });

      formattedTasks.forEach(task => {
        if ((!task.start || !task.end)) {
          const childTasks = formattedTasks.filter(t =>
            t.id.startsWith(task.id + '.') && t.start && t.end
          );
          if (childTasks.length > 0) {
            task.start = min(childTasks.map(t => t.start));
            task.end = max(childTasks.map(t => t.end));
            task.isValid = true;
          }
        }
      });

      console.log('Formatted tasks:', formattedTasks);

      const validTasks = formattedTasks.filter(task => task.isValid);
      const invalidTasks = formattedTasks.filter(task => !task.isValid);

      setImportedTasks(formattedTasks);

      setDebugInfo({
        totalTasks: formattedTasks.length,
        validTasks: validTasks.length,
        invalidTasks: invalidTasks.length,
        headers: headers,
        sampleInvalidTasks: invalidTasks.slice(0, 5).map(task => ({
          name: task.name,
          rawStart: task.rawStart,
          rawEnd: task.rawEnd
        }))
      });

      if (invalidTasks.length > 0) {
        setImportError(`Warning: ${invalidTasks.length} tasks were found with invalid dates.`);
      } else {
        setImportError(null);
      }

      calculateUpcomingMilestones(formattedTasks);

      setDebugDialogOpen(true);

    } catch (error) {
      console.error('Error processing imported data:', error);
      setImportError('Error processing imported data. Please check the file format.');
    }
  };

  const calculateUpcomingMilestones = (tasks) => {
    const today = new Date();
    const milestones = tasks.filter(task => task.start && task.end && isEqual(task.start, task.end));
    const upcoming = milestones.filter(task => isAfter(task.start, today));
    const sortedMilestones = upcoming.sort((a, b) => isAfter(a.start, b.start) ? 1 : -1);
    const nextThreeMilestones = sortedMilestones.slice(0, 3);
    setUpcomingMilestones(nextThreeMilestones);
  };

  const handleAddTaskChange = (e, field) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setNewTask(prev => ({ ...prev, [field]: value }));
  };

  const handleAddTask = () => {
    const newTaskData = {
      ...newTask,
      start: parseDate(newTask.start),
      end: parseDate(newTask.end),
      isValid: !!parseDate(newTask.start) && !!parseDate(newTask.end),
      completionPercentage: parseFloat(newTask.completionPercentage) || 0,
      level: (newTask.id || '').split('.').length - 1,
      delayedDate: newTask.delayed ? new Date() : null,
    };

    setImportedTasks(tasks => {
      const updatedTasks = [...tasks];
      if (insertIndex != null) {
        updatedTasks.splice(insertIndex, 0, newTaskData);
      } else {
        updatedTasks.push(newTaskData);
      }
      calculateUpcomingMilestones(updatedTasks);
      return updatedTasks;
    });

    setAddTaskDialogOpen(false);
    setNewTask({
      id: '',
      name: '',
      start: '',
      end: '',
      responsible: '',
      completionPercentage: '',
      delayed: false,
      delayReason: '',
      delayedDate: null,
    });
    setInsertIndex(null);
  };

  const handleTaskChange = (e, taskId, field) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setImportedTasks(tasks => {
      const updatedTasks = tasks.map(task => {
        if (task.id === taskId) {
          let updatedField = value;
          if (field === 'start' || field === 'end') {
            updatedField = parseDate(value);
          } else if (field === 'completionPercentage') {
            updatedField = parseFloat(value) || 0;
          } else if (field === 'delayed') {
            if (value && !task.delayed) {
              task.delayedDate = new Date();
            } else if (!value && task.delayed) {
              task.delayedDate = null;
              task.delayReason = '';
            }
          }
          return { ...task, [field]: updatedField };
        }
        return task;
      });
      calculateUpcomingMilestones(updatedTasks);
      return updatedTasks;
    });
  };

  const handleDeleteTask = (taskId) => {
    setImportedTasks(tasks => {
      const updatedTasks = tasks.filter(task => task.id !== taskId);
      calculateUpcomingMilestones(updatedTasks);
      return updatedTasks;
    });
  };

  const handleColumnMappingChange = (column, value) => {
    setColumnMapping(prev => ({ ...prev, [column]: value }));
    setImportError(null);
  };

  const handleCloseDebugDialog = () => {
    setDebugDialogOpen(false);
  };

  const handleEditTask = (taskId) => {
    setEditingTaskId(taskId);
  };

  const handleSaveTask = () => {
    setEditingTaskId(null);
  };

  const handleOpenAddTaskDialog = (index) => {
    setInsertIndex(index);
    setAddTaskDialogOpen(true);
  };

  const handleCloseAddTaskDialog = () => {
    setAddTaskDialogOpen(false);
    setNewTask({
      id: '',
      name: '',
      start: '',
      end: '',
      responsible: '',
      completionPercentage: '',
      delayed: false,
      delayReason: '',
      delayedDate: null,
    });
    setInsertIndex(null);
  };

  const requiredFields = ['id', 'name', 'start', 'end'];

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Time Line
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mb: 2, mt: 2 }}>
        <Button variant="contained" onClick={() => handleOpenAddTaskDialog(null)} startIcon={<AddIcon />}>
          Add Task
        </Button>
        <Button variant="contained" component="label" startIcon={<InfoIcon />}>
          Upload File
          <input type="file" hidden onChange={handleFileUpload} accept=".xlsx,.xls,.csv" />
        </Button>
      </Box>
      {uploadedFile && (
        <Box mt={2}>
          <Typography variant="h6">Uploaded File:</Typography>
          <Typography>{uploadedFile.name}</Typography>
          <Button variant="outlined" href={uploadedFileURL} target="_blank">
            View / Download
          </Button>
        </Box>
      )}
      {upcomingMilestones.length > 0 && (
        <Box mt={4}>
          <Typography variant="h6">Upcoming 3 Milestones</Typography>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Responsible</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {upcomingMilestones.map((task) => (
                  <TableRow key={task.id}>
                    <TableCell>{task.id}</TableCell>
                    <TableCell>{task.name}</TableCell>
                    <TableCell>{formatDate(task.start)}</TableCell>
                    <TableCell>{task.responsible || ''}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {headers.length > 0 && (
        <Box mt={2}>
          <Button variant="outlined" onClick={() => setShowMapping(!showMapping)}>
            {showMapping ? 'Hide' : 'Show'} Column Mapping
          </Button>
          {showMapping && (
            <Box mt={2}>
              <Typography variant="h6">Column Mapping</Typography>
              {requiredFields.map(column => (
                <FormControl key={column} fullWidth sx={{ mt: 1 }}>
                  <InputLabel>{column.charAt(0).toUpperCase() + column.slice(1)} *</InputLabel>
                  <Select
                    value={columnMapping[column] || ''}
                    onChange={(e) => handleColumnMappingChange(column, e.target.value)}
                    label={column.charAt(0).toUpperCase() + column.slice(1)}
                  >
                    {headers.map(header => (
                      <MenuItem key={header} value={header}>
                        {header} {headerSamples[header] && headerSamples[header].length > 0 ? ` (e.g., ${formatSampleValues(headerSamples[header])})` : ''}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ))}
              {['responsible', 'completion'].map(column => (
                <FormControl key={column} fullWidth sx={{ mt: 1 }}>
                  <InputLabel>{column.charAt(0).toUpperCase() + column.slice(1)}</InputLabel>
                  <Select
                    value={columnMapping[column] || ''}
                    onChange={(e) => handleColumnMappingChange(column, e.target.value)}
                    label={column.charAt(0).toUpperCase() + column.slice(1)}
                  >
                    <MenuItem value=""><em>None</em></MenuItem>
                    {headers.map(header => (
                      <MenuItem key={header} value={header}>
                        {header} {headerSamples[header] && headerSamples[header].length > 0 ? ` (e.g., ${formatSampleValues(headerSamples[header])})` : ''}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ))}
            </Box>
          )}
        </Box>
      )}
      {importError && (
        <Typography color="error" mt={2}>
          {importError}
        </Typography>
      )}
      {importedTasks.length > 0 && (
        <Box mt={4}>
          <Button variant="outlined" onClick={() => setShowTasks(!showTasks)}>
            {showTasks ? 'Hide' : 'Show'} Tasks
          </Button>
          {showTasks && (
            <>
              <Typography variant="h6" mt={2}>Tasks</Typography>
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell sx={{ minWidth: 200 }}>Name</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Responsible</TableCell>
                      <TableCell>Completion %</TableCell>
                      <TableCell>Delayed</TableCell>
                      <TableCell>Delay Reason</TableCell>
                      <TableCell>Days Delayed</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {importedTasks.map((task, index) => (
                      <TableRow key={task.id}>
                        {editingTaskId === task.id ? (
                          <>
                            <TableCell sx={{ width: '10%' }}>
                              <TextField
                                value={task.id}
                                onChange={(e) => handleTaskChange(e, task.id, 'id')}
                                fullWidth
                              />
                            </TableCell>
                            <TableCell sx={{ width: '20%' }}>
                              <TextField
                                value={task.name}
                                onChange={(e) => handleTaskChange(e, task.id, 'name')}
                                fullWidth
                              />
                            </TableCell>
                            <TableCell sx={{ width: '10%' }}>
                              <TextField
                                value={formatDate(task.start) || ''}
                                onChange={(e) => handleTaskChange(e, task.id, 'start')}
                                placeholder="YYYY-MM-DD"
                                fullWidth
                              />
                            </TableCell>
                            <TableCell sx={{ width: '10%' }}>
                              <TextField
                                value={formatDate(task.end) || ''}
                                onChange={(e) => handleTaskChange(e, task.id, 'end')}
                                placeholder="YYYY-MM-DD"
                                fullWidth
                              />
                            </TableCell>
                            <TableCell sx={{ width: '10%' }}>
                              <TextField
                                value={task.responsible || ''}
                                onChange={(e) => handleTaskChange(e, task.id, 'responsible')}
                                fullWidth
                              />
                            </TableCell>
                            <TableCell sx={{ width: '10%' }}>
                              <TextField
                                type="number"
                                value={task.completionPercentage}
                                onChange={(e) => handleTaskChange(e, task.id, 'completionPercentage')}
                                fullWidth
                              />
                            </TableCell>
                            <TableCell sx={{ width: '5%' }}>
                              <Checkbox
                                checked={task.delayed || false}
                                onChange={(e) => handleTaskChange(e, task.id, 'delayed')}
                              />
                            </TableCell>
                            <TableCell sx={{ width: '15%' }}>
                              <TextField
                                value={task.delayReason || ''}
                                onChange={(e) => handleTaskChange(e, task.id, 'delayReason')}
                                disabled={!task.delayed}
                                fullWidth
                              />
                            </TableCell>
                            <TableCell sx={{ width: '5%' }}>
                              {task.delayed && task.delayedDate ? differenceInDays(new Date(), task.delayedDate) : 0}
                            </TableCell>
                            <TableCell sx={{ width: '5%' }}>
                              <IconButton onClick={handleSaveTask} size="small">
                                <Typography variant="button">Save</Typography>
                              </IconButton>
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>{task.id}</TableCell>
                            <TableCell>{task.name}</TableCell>
                            <TableCell>{formatDate(task.start) || ''}</TableCell>
                            <TableCell>{formatDate(task.end) || ''}</TableCell>
                            <TableCell>{task.responsible || ''}</TableCell>
                            <TableCell>{task.completionPercentage}</TableCell>
                            <TableCell>
                              {task.delayed ? 'Yes' : 'No'}
                            </TableCell>
                            <TableCell>{task.delayReason || ''}</TableCell>
                            <TableCell>
                              {task.delayed && task.delayedDate ? differenceInDays(new Date(), task.delayedDate) : 0}
                            </TableCell>
                            <TableCell>
                              <IconButton onClick={() => handleEditTask(task.id)} size="small">
                                <EditIcon />
                              </IconButton>
                              <IconButton onClick={() => handleDeleteTask(task.id)} size="small">
                                <DeleteIcon />
                              </IconButton>
                              <IconButton onClick={() => handleOpenAddTaskDialog(index + 1)} size="small">
                                <AddIcon />
                              </IconButton>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      )}
      <Dialog open={addTaskDialogOpen} onClose={handleCloseAddTaskDialog} maxWidth="md" fullWidth>
        <DialogTitle>Add New Task</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="ID"
                value={newTask.id}
                onChange={(e) => handleAddTaskChange(e, 'id')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                value={newTask.name}
                onChange={(e) => handleAddTaskChange(e, 'name')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Start Date"
                value={newTask.start}
                onChange={(e) => handleAddTaskChange(e, 'start')}
                placeholder="YYYY-MM-DD"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="End Date"
                value={newTask.end}
                onChange={(e) => handleAddTaskChange(e, 'end')}
                placeholder="YYYY-MM-DD"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Responsible"
                value={newTask.responsible}
                onChange={(e) => handleAddTaskChange(e, 'responsible')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Completion %"
                type="number"
                value={newTask.completionPercentage}
                onChange={(e) => handleAddTaskChange(e, 'completionPercentage')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newTask.delayed}
                    onChange={(e) => handleAddTaskChange(e, 'delayed')}
                  />
                }
                label="Delayed"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Delay Reason"
                value={newTask.delayReason}
                onChange={(e) => handleAddTaskChange(e, 'delayReason')}
                disabled={!newTask.delayed}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddTaskDialog}>Cancel</Button>
          <Button onClick={handleAddTask} variant="contained">Add Task</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={debugDialogOpen} onClose={handleCloseDebugDialog}>
        <DialogTitle>Debug Information</DialogTitle>
        <DialogContent dividers>
          <Typography>Total tasks: {debugInfo?.totalTasks}</Typography>
          <Typography>Valid tasks: {debugInfo?.validTasks}</Typography>
          <Typography>Invalid tasks: {debugInfo?.invalidTasks}</Typography>
          <Typography>Headers: {debugInfo?.headers.join(', ')}</Typography>
          {debugInfo?.sampleInvalidTasks.length > 0 && (
            <>
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                Sample Invalid Tasks:
              </Typography>
              <ul>
                {debugInfo.sampleInvalidTasks.map((task, index) => (
                  <li key={index}>
                    {task.name}: Start - {task.rawStart || 'N/A'}, End - {task.rawEnd || 'N/A'}
                  </li>
                ))}
              </ul>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDebugDialog} variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TimelineImport;
