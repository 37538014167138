import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Chip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Fade,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8],
  },
}));

const RiskChip = styled(Chip)(({ theme, severity }) => ({
  backgroundColor: 
    severity === 'High' ? theme.palette.error.main :
    severity === 'Medium' ? theme.palette.warning.main :
    theme.palette.success.main,
  color: '#fff',
  fontWeight: 'bold',
}));

const RiskManagement = ({ risks, updateRisks }) => {
  const [newRisk, setNewRisk] = useState({ description: '', impact: 'low', probability: 'low', mitigation: '' });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingRiskId, setEditingRiskId] = useState(null);

  const handleAddOrUpdateRisk = () => {
    if (newRisk.description) {
      let updatedRisks;
      if (editingRiskId) {
        updatedRisks = risks.map(risk => risk.id === editingRiskId ? { ...newRisk, id: editingRiskId } : risk);
      } else {
        updatedRisks = [...risks, { ...newRisk, id: Date.now() }];
      }
      updateRisks(updatedRisks);
      setNewRisk({ description: '', impact: 'low', probability: 'low', mitigation: '' });
      setDialogOpen(false);
      setEditingRiskId(null);
    }
  };

  const handleEditRisk = (risk) => {
    setNewRisk(risk);
    setEditingRiskId(risk.id);
    setDialogOpen(true);
  };

  const handleDeleteRisk = (id) => {
    const updatedRisks = risks.filter(risk => risk.id !== id);
    updateRisks(updatedRisks);
  };

  const getRiskSeverity = (impact, probability) => {
    const severityMatrix = {
      'low-low': 'Low',
      'low-medium': 'Low',
      'low-high': 'Medium',
      'medium-low': 'Low',
      'medium-medium': 'Medium',
      'medium-high': 'High',
      'high-low': 'Medium',
      'high-medium': 'High',
      'high-high': 'High',
    };
    return severityMatrix[`${impact}-${probability}`] || 'Unknown';
  };

  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 2, bgcolor: 'background.default' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: "bold", display: 'flex', alignItems: 'center' }}>
          <ErrorOutlineIcon sx={{ mr: 1, color: theme => theme.palette.warning.main }} />
          Risk Assessment
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setNewRisk({ description: '', impact: 'low', probability: 'low', mitigation: '' });
            setEditingRiskId(null);
            setDialogOpen(true);
          }}
          sx={{
            bgcolor: theme => theme.palette.primary.main,
            '&:hover': {
              bgcolor: theme => theme.palette.primary.dark,
            },
          }}
        >
          Add Risk
        </Button>
      </Box>

      <Grid container spacing={3}>
        {risks.map((risk) => (
          <Grid item xs={12} sm={6} md={4} key={risk.id}>
            <Fade in={true}>
              <StyledCard>
                <CardContent>
                  <Typography variant="h6" gutterBottom>{risk.description}</Typography>
                  <Box sx={{ mt: 2, mb: 2 }}>
                    <RiskChip
                      label={getRiskSeverity(risk.impact, risk.probability)}
                      severity={getRiskSeverity(risk.impact, risk.probability)}
                    />
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    Impact: {risk.impact.charAt(0).toUpperCase() + risk.impact.slice(1)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Probability: {risk.probability.charAt(0).toUpperCase() + risk.probability.slice(1)}
                  </Typography>
                  {risk.mitigation && (
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      Mitigation: {risk.mitigation}
                    </Typography>
                  )}
                </CardContent>
                <CardActions sx={{ mt: 'auto', justifyContent: 'flex-end' }}>
                  <Tooltip title="Edit Risk">
                    <IconButton onClick={() => handleEditRisk(risk)} size="small">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Risk">
                    <IconButton onClick={() => handleDeleteRisk(risk.id)} size="small">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </CardActions>
              </StyledCard>
            </Fade>
          </Grid>
        ))}
      </Grid>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>{editingRiskId ? 'Edit Risk' : 'Add New Risk'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Risk Description"
            type="text"
            fullWidth
            value={newRisk.description}
            onChange={(e) => setNewRisk({ ...newRisk, description: e.target.value })}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Impact</InputLabel>
            <Select
              value={newRisk.impact}
              onChange={(e) => setNewRisk({ ...newRisk, impact: e.target.value })}
            >
              <MenuItem value="low">Low</MenuItem>
              <MenuItem value="medium">Medium</MenuItem>
              <MenuItem value="high">High</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Probability</InputLabel>
            <Select
              value={newRisk.probability}
              onChange={(e) => setNewRisk({ ...newRisk, probability: e.target.value })}
            >
              <MenuItem value="low">Low</MenuItem>
              <MenuItem value="medium">Medium</MenuItem>
              <MenuItem value="high">High</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Mitigation Strategy (Optional)"
            type="text"
            fullWidth
            multiline
            rows={3}
            value={newRisk.mitigation}
            onChange={(e) => setNewRisk({ ...newRisk, mitigation: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddOrUpdateRisk} variant="contained" color="primary">
            {editingRiskId ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default RiskManagement;