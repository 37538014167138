// src/components/shadcn-ui/Modal.jsx
import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import PropTypes from 'prop-types';

const Modal = ({ open, onOpenChange, title, children, className }) => {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
      <Dialog.Content
        className={`fixed top-0 right-0 h-full bg-white p-6 rounded-l-lg shadow-lg overflow-auto ${className}`}
      >
        <Dialog.Title className="text-xl font-semibold mb-4">{title}</Dialog.Title>
        <div className="mb-4">{children}</div>
        <Dialog.Close asChild>
          <button className="mt-4 px-4 py-2 bg-primary text-white rounded hover:bg-secondary">
            Close
          </button>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Root>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string, // For additional styling
};

Modal.defaultProps = {
  className: '',
};

export default Modal;
