// src/components/DashboardOverview.jsx

import React, { useMemo, useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Switch,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import FlagIcon from '@mui/icons-material/Flag';
import EventIcon from '@mui/icons-material/Event';
import LinkIcon from '@mui/icons-material/Link';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Helper functions to calculate delay duration
const calculateDelayDuration = (items) => {
  let delayDuration = 0;
  const today = new Date();

  items.forEach((item) => {
    const itemEndDate = item.end ? new Date(item.end) : null;
    if (!item.isComplete && itemEndDate && itemEndDate < today) {
      const diffTime = today - itemEndDate;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      delayDuration += diffDays;
    }
  });

  return delayDuration;
};

// Reusable PropTypes definitions
const taskPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  isComplete: PropTypes.bool,
  end: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.object,
  ]),
});

const ProjectRow = ({ project, currency, navigate }) => (
  <TableRow key={project.id}>
    <TableCell>{project.name}</TableCell>
    <TableCell align="center">{project.progressPercentage.toFixed(2)}</TableCell>
    <TableCell align="center">{currency} {project.value.toFixed(2)}</TableCell>
    <TableCell align="center">{currency} {project.collected.toFixed(2)}</TableCell>
    <TableCell align="center">{currency} {project.pendingCollected.toFixed(2)}</TableCell>
    <TableCell align="center">{currency} {project.remaining.toFixed(2)}</TableCell>
    <TableCell align="center">{project.totalTasks}</TableCell>
    <TableCell align="center">{project.completedTasks}</TableCell>
    <TableCell align="center">{project.overdueTasks}</TableCell>
    <TableCell align="center">
      {project.upcomingMilestones.length > 0 ? (
        project.upcomingMilestones.map((milestone, idx) => (
          <Typography key={idx}>{milestone.name} - Due: {new Date(milestone.end).toLocaleDateString()}</Typography>
        ))
      ) : project.upcomingScopeMilestones.length > 0 ? (
        project.upcomingScopeMilestones.map((scope, idx) => (
          <Typography key={idx}>{scope.name} - Due: {new Date(scope.end).toLocaleDateString()}</Typography>
        ))
      ) : (
        <Typography>No upcoming milestones</Typography>
      )}
    </TableCell>
    <TableCell align="center">
      {project.changeRequests.length > 0 ? (
        <Typography>{project.changeRequests.length} Change Request(s)</Typography>
      ) : (
        <Typography>No change requests</Typography>
      )}
    </TableCell>
    <TableCell align="center">
      {project.risks.length > 0 ? (
        <Typography>{project.risks.length} Risk(s)</Typography>
      ) : (
        <Typography>No risks</Typography>
      )}
    </TableCell>
    <TableCell align="center">
      {project.delayDuration > 0 ? (
        <Typography color="error">{project.delayDuration} day(s)</Typography>
      ) : (
        <Typography>On Time</Typography>
      )}
    </TableCell>
    <TableCell align="center">
      <FlagIcon sx={{ color: project.healthFlagColor, fontSize: '30px' }} />
    </TableCell>
    <TableCell align="center">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography>{project.inProgressTasks}</Typography>
        <Button
          size="small"
          startIcon={<LinkIcon />}
          onClick={() => navigate(`/app/projects/${project.id}/kanban`)}
          sx={{ ml: 1 }}
        >
          View
        </Button>
      </Box>
    </TableCell>
    <TableCell align="center">{project.currentScopeItem}</TableCell>
    <TableCell align="center">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography>{project.collectedInvoices}</Typography>
        <Button
          size="small"
          startIcon={<LinkIcon />}
          onClick={() => navigate(`/app/projects/${project.id}/invoices`)}
          sx={{ ml: 1 }}
        >
          View
        </Button>
      </Box>
    </TableCell>
    <TableCell align="center">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography>{project.pendingInvoices}</Typography>
        <Button
          size="small"
          startIcon={<LinkIcon />}
          onClick={() => navigate(`/app/projects/${project.id}/invoices`)}
          sx={{ ml: 1 }}
        >
          View
        </Button>
      </Box>
    </TableCell>
  </TableRow>
);

const DashboardOverview = ({
  projects = [],
  currency = 'USD',
  userName = 'User',
  upcomingDeadlines = [],
  recentActivities = [],
}) => {
  const navigate = useNavigate();

  // State for customizable dashboard sections
  const [showFinancialOverview, setShowFinancialOverview] = useState(true);
  const [showUpcomingDeadlines, setShowUpcomingDeadlines] = useState(true);
  const [showRecentActivities, setShowRecentActivities] = useState(true);
  const [showTaskOverview, setShowTaskOverview] = useState(true);
  const [showOverallProgressPie, setShowOverallProgressPie] = useState(true);

  // Memoized project details calculation
  const projectDetails = useMemo(() => {
    return projects.map((project) => {
      const timelineTasks = Array.isArray(project.timelineTasks) ? project.timelineTasks : [];
      const scopeItems = Array.isArray(project.scope) ? project.scope : [];
      const kanbanData = project.kanbanData || {
        inProgress: { tasks: [] },
        todo: { tasks: [] },
        done: { tasks: [] },
      };

      const inProgressTasks = Array.isArray(kanbanData.inProgress.tasks)
        ? kanbanData.inProgress.tasks.filter((task) => !task.isComplete).length
        : 0;

      const currentScopeItem = scopeItems.find((item) => !item.isComplete) || null;

      const invoices = Array.isArray(project.invoices) ? project.invoices : [];
      const collectedInvoices = invoices.filter((inv) => inv.status === 'paid').length;
      const pendingInvoices = invoices.filter((inv) => inv.status !== 'paid').length;

      const totalTasks = timelineTasks.length;
      const completedTasks = timelineTasks.filter((task) => task.isComplete).length;
      const overdueTasks = timelineTasks.filter(
        (task) => !task.isComplete && new Date(task.end) < new Date()
      ).length;

      const upcomingMilestones = timelineTasks
        .filter((task) => !task.isComplete && new Date(task.end) > new Date())
        .sort((a, b) => new Date(a.end) - new Date(b.end))
        .slice(0, 3);

      const upcomingScopeMilestones = scopeItems
        .filter((item) => !item.isComplete && new Date(item.end) > new Date())
        .sort((a, b) => new Date(a.end) - new Date(b.end))
        .slice(0, 3);

      const collected = invoices
        .filter((inv) => inv.status === 'paid')
        .reduce((sum, invoice) => sum + (invoice.amount || 0), 0);
      const pendingCollected = invoices
        .filter((inv) => inv.status !== 'paid')
        .reduce((sum, invoice) => sum + (invoice.amount || 0), 0);

      const changeRequests = Array.isArray(project.changeRequests) ? project.changeRequests : [];
      const risks = Array.isArray(project.risks) ? project.risks : [];
      const remaining = (parseFloat(project.projectValue) || 0) - collected;

      const delayDuration = calculateDelayDuration([...timelineTasks, ...scopeItems]);

      const getHealthFlagColor = () => {
        if (risks.length > 2 || overdueTasks > 5 || pendingInvoices.length > 3) return 'red';
        if (risks.length > 0 || overdueTasks > 0 || pendingInvoices.length > 0) return 'yellow';
        return 'green';
      };

      return {
        id: project.id,
        name: project.name || 'Unnamed Project',
        value: parseFloat(project.projectValue) || 0,
        collected,
        remaining,
        totalTasks,
        completedTasks,
        overdueTasks,
        progressPercentage: totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0,
        upcomingMilestones,
        upcomingScopeMilestones,
        pendingCollected,
        changeRequests,
        risks,
        healthFlagColor: getHealthFlagColor(),
        delayDuration,
        inProgressTasks,
        currentScopeItem: currentScopeItem ? currentScopeItem.name : 'All scopes completed',
        collectedInvoices,
        pendingInvoices,
      };
    });
  }, [projects]);

  const totalCollected = useMemo(
    () => projectDetails.reduce((sum, project) => sum + project.collected, 0),
    [projectDetails]
  );

  const totalRemaining = useMemo(
    () => projectDetails.reduce((sum, project) => sum + project.remaining, 0),
    [projectDetails]
  );

  const { totalTasks, completedTasks, overdueTasks } = useMemo(() => {
    let total = 0;
    let completed = 0;
    let overdue = 0;

    projects.forEach((project) => {
      const timelineTasks = Array.isArray(project.timelineTasks) ? project.timelineTasks : [];
      total += timelineTasks.length;
      completed += timelineTasks.filter((task) => task.isComplete).length;
      overdue += timelineTasks.filter(
        (task) => !task.isComplete && new Date(task.end) < new Date()
      ).length;
    });

    return { totalTasks: total, completedTasks: completed, overdueTasks: overdue };
  }, [projects]);

  // Debugging logs for project details
  useEffect(() => {
    console.debug('Projects:', projects);
    console.debug('Project Details:', projectDetails);
  }, [projects, projectDetails]);

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      {/* Welcome Message and Sign Out Button */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          Welcome, {userName}!
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate('/signin')}
          sx={{
            backgroundColor: 'black',
            color: '#eeeeee',
            '&:hover': { backgroundColor: '#333' },
          }}
        >
          Sign Out
        </Button>
      </Box>

      {/* Project Table */}
      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Project Name</TableCell>
              <TableCell align="center">Progress (%)</TableCell>
              <TableCell align="center">Total Value</TableCell>
              <TableCell align="center">Collected</TableCell>
              <TableCell align="center">Pending Invoices</TableCell>
              <TableCell align="center">Remaining</TableCell>
              <TableCell align="center">Total Tasks</TableCell>
              <TableCell align="center">Completed Tasks</TableCell>
              <TableCell align="center">Overdue Tasks</TableCell>
              <TableCell align="center">Upcoming Milestones</TableCell>
              <TableCell align="center">Change Requests</TableCell>
              <TableCell align="center">Risks</TableCell>
              <TableCell align="center">Delay (Days)</TableCell>
              <TableCell align="center">Health</TableCell>
              <TableCell align="center">In-Progress Tasks</TableCell>
              <TableCell align="center">Current Scope Item</TableCell>
              <TableCell align="center">Collected Invoices</TableCell>
              <TableCell align="center">Pending Invoices</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectDetails.map((project) => (
              <ProjectRow key={project.id} project={project} currency={currency} navigate={navigate} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Financial Overview and Overall Progress Charts */}
      <Grid container spacing={4} sx={{ mb: 4 }}>
        {showFinancialOverview && (
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                Total Projects Financial Overview
              </Typography>
              <Box sx={{ height: 300, width: '100%' }}>
                <Bar
                  data={{
                    labels: ['Total Collected', 'Total Remaining'],
                    datasets: [
                      {
                        label: 'Amount',
                        data: [totalCollected, totalRemaining],
                        backgroundColor: ['rgba(54, 162, 235, 0.6)', 'rgba(255, 159, 64, 0.6)'],
                        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 159, 64, 1)'],
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                        ticks: {
                          callback: (value) => currency + ' ' + value.toFixed(2),
                        },
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        callbacks: {
                          label: (context) => {
                            let label = context.dataset.label || '';
                            if (label) {
                              label += ': ';
                            }
                            if (context.parsed.y !== null) {
                              label += currency + ' ' + context.parsed.y.toFixed(2);
                            }
                            return label;
                          },
                        },
                      },
                    },
                  }}
                />
              </Box>
            </Paper>
          </Grid>
        )}

        {showOverallProgressPie && (
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                Overall Project Progress
              </Typography>
              <Box sx={{ height: 300, width: '100%' }}>
                <Pie
                  data={{
                    labels: ['Completed Tasks', 'Remaining Tasks'],
                    datasets: [
                      {
                        data: [completedTasks, totalTasks - completedTasks],
                        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 206, 86, 0.6)'],
                        borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 206, 86, 1)'],
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'bottom',
                      },
                      tooltip: {
                        callbacks: {
                          label: (context) => {
                            const label = context.label || '';
                            const value = context.parsed || 0;
                            return `${label}: ${value} tasks`;
                          },
                        },
                      },
                    },
                  }}
                />
              </Box>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

DashboardOverview.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      projectValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      timelineTasks: PropTypes.arrayOf(taskPropType),
      scope: PropTypes.arrayOf(taskPropType),
      invoices: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          status: PropTypes.string,
          amount: PropTypes.number,
        })
      ),
      changeRequests: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          text: PropTypes.string,
        })
      ),
      risks: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          description: PropTypes.string,
        })
      ),
      kanbanData: PropTypes.shape({
        todo: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          tasks: PropTypes.arrayOf(taskPropType),
        }),
        inProgress: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          tasks: PropTypes.arrayOf(taskPropType),
        }),
        done: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          tasks: PropTypes.arrayOf(taskPropType),
        }),
      }),
    })
  ).isRequired,
  currency: PropTypes.string,
  userName: PropTypes.string,
  upcomingDeadlines: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      projectName: PropTypes.string.isRequired,
      deadline: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
        PropTypes.object,
      ]).isRequired,
    })
  ),
  recentActivities: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      timestamp: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
        PropTypes.object,
      ]).isRequired,
    })
  ),
};

export default DashboardOverview;