// src/contexts/NotificationContext.jsx

import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { db } from "../firebase"; // Ensure correct import path for Firebase
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  doc,
  updateDoc,
  deleteDoc,
  getDocs,
  writeBatch,
} from "firebase/firestore";
import { useAuth } from "./AuthContext";

export const NotificationContext = createContext(); // Ensure NotificationContext is exported

export const useNotification = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  const { user } = useAuth(); // Access authenticated user

  const [notifications, setNotifications] = useState([]);
  const [notificationPrefs, setNotificationPrefs] = useState({
    pendingInvoices: true,
    weeklyReportReminders: true,
    riskAdded: true,
    delayedTasks: true,
  });
  const [weeklyReportSchedule, setWeeklyReportSchedule] = useState({
    day: 'Monday',
    time: '12:00',
  });

  /**
   * Fetch notifications from Firestore in real-time
   */
  useEffect(() => {
    if (!user) {
      setNotifications([]);
      return;
    }

    const notificationsRef = collection(db, "users", user.uid, "notifications");
    const q = query(notificationsRef, orderBy("timestamp", "desc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const notifs = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotifications(notifs);
    }, (error) => {
      console.error("Error fetching notifications:", error);
    });

    return () => unsubscribe();
  }, [user]);

  /**
   * Add a new notification to Firestore
   */
  const addNotification = async (title, message, type) => {
    if (!user) return;

    try {
      const notificationsRef = collection(db, "users", user.uid, "notifications");
      await addDoc(notificationsRef, {
        title,
        message,
        type,
        timestamp: new Date(),
        read: false,
      });
    } catch (error) {
      console.error("Error adding notification:", error);
    }
  };

  /**
   * Remove a notification by its ID
   */
  const removeNotification = async (id) => {
    if (!user) return;

    try {
      const notifRef = doc(db, "users", user.uid, "notifications", id);
      await deleteDoc(notifRef);
    } catch (error) {
      console.error("Error removing notification:", error);
    }
  };

  /**
   * Clear all notifications
   */
  const clearNotifications = async () => {
    if (!user) return;

    try {
      const notificationsRef = collection(db, "users", user.uid, "notifications");
      const q = query(notificationsRef);
      const snapshot = await getDocs(q);

      const batch = writeBatch(db);
      snapshot.forEach(doc => {
        batch.delete(doc.ref);
      });
      await batch.commit();
    } catch (error) {
      console.error("Error clearing notifications:", error);
    }
  };

  /**
   * Toggle a notification preference
   */
  const toggleNotificationPref = (prefKey) => {
    setNotificationPrefs((prev) => ({
      ...prev,
      [prefKey]: !prev[prefKey],
    }));
  };

  /**
   * Toggle the read status of a notification
   */
  const toggleNotificationReadStatus = async (id, currentStatus) => {
    if (!user) return;

    try {
      const notifRef = doc(db, "users", user.uid, "notifications", id);
      await updateDoc(notifRef, { read: !currentStatus });
    } catch (error) {
      console.error("Error toggling read status:", error);
    }
  };

  /**
   * Update the weekly report schedule
   */
  const updateWeeklyReportSchedule = (schedule) => {
    setWeeklyReportSchedule(schedule);
  };

  /**
   * Checks and triggers weekly report notifications based on the schedule.
   */
  useEffect(() => {
    if (!user) return;

    // Request permission for browser notifications
    if (
      typeof window !== "undefined" &&
      window.Notification &&
      window.Notification.requestPermission
    ) {
      if (window.Notification.permission !== "granted") {
        window.Notification.requestPermission();
      }
    }

    const checkWeeklyReport = () => {
      const now = new Date();
      const currentDay = now.toLocaleString('en-US', { weekday: 'long' });
      const currentTime = now.toTimeString().split(' ')[0].substring(0, 5); // "HH:MM"

      if (
        notificationPrefs.weeklyReportReminders &&
        weeklyReportSchedule.day === currentDay &&
        weeklyReportSchedule.time === currentTime
      ) {
        // Trigger browser notification
        if (
          typeof window !== "undefined" &&
          window.Notification &&
          window.Notification.permission === "granted"
        ) {
          new window.Notification("Weekly Report", {
            body: "It's time for your weekly report!",
            icon: "/vite.svg", // Ensure this path is correct or replace with a valid icon
          });
        }

        // Add to in-app notifications
        addNotification(
          "Weekly Report",
          "It's time for your weekly report!",
          "weeklyReportReminders",
        );
      }
    };

    // Check every minute
    const interval = setInterval(checkWeeklyReport, 60000);

    return () => clearInterval(interval);
  }, [notificationPrefs.weeklyReportReminders, weeklyReportSchedule, user, addNotification]);

  const value = useMemo(
    () => ({
      notifications,
      addNotification,
      removeNotification,
      clearNotifications,
      notificationPrefs,
      toggleNotificationPref,
      toggleNotificationReadStatus,
      weeklyReportSchedule,
      updateWeeklyReportSchedule,
    }),
    [notifications, notificationPrefs, weeklyReportSchedule],
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};
