// src/components/shadcn-ui/Table.jsx

import React from "react";
import PropTypes from "prop-types";

// Table Component
const Table = ({ children, className = "" }) => {
  return (
    <table className={`min-w-full divide-y divide-gray-200 ${className}`}>
      {children}
    </table>
  );
};

Table.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

// TableCaption Component
const TableCaption = ({ children, className = "" }) => {
  return (
    <caption className={`caption-top text-sm text-gray-500 ${className}`}>
      {children}
    </caption>
  );
};

TableCaption.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

// TableHeader Component
const TableHeader = ({ children, className = "" }) => {
  return <thead className={`bg-gray-50 ${className}`}>{children}</thead>;
};

TableHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

// TableBody Component
const TableBody = ({ children, className = "" }) => {
  return (
    <tbody className={`bg-white divide-y divide-gray-200 ${className}`}>
      {children}
    </tbody>
  );
};

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

// TableFooter Component
const TableFooter = ({ children, className = "" }) => {
  return <tfoot className={`bg-gray-50 ${className}`}>{children}</tfoot>;
};

TableFooter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

// TableRow Component
const TableRow = ({ children, className = "" }) => {
  return <tr className={className}>{children}</tr>;
};

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

// TableHead Component
const TableHead = ({ children, className = "" }) => {
  return (
    <th
      className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${className}`}
    >
      {children}
    </th>
  );
};

TableHead.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

// TableCell Component
const TableCell = ({ children, className = "" }) => {
  return (
    <td
      className={`px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100 ${className}`}
    >
      {children}
    </td>
  );
};

TableCell.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

// Exporting all components
export {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHeader,
  TableHead,
  TableRow,
};
