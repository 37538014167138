// src/components/LandingPage.jsx

import React, { useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  Card,
  CardContent,
  CardActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AppBar,
  Toolbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled, keyframes } from "@mui/system";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { motion } from "framer-motion";
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import PeopleIcon from '@mui/icons-material/People';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LanguageIcon from '@mui/icons-material/Language';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import FolderSharedIcon from '@mui/icons-material/FolderShared';

const features = [
  {
    title: "AI Assistant for Smarter Decisions",
    description: "Get smart suggestions and reminders to stay on top of deadlines.",
    icon: <ImportantDevicesIcon sx={{ fontSize: 40, color: '#FF6F61' }} />
  },
  {
    title: "Seamless Team Collaboration",
    description: "Work together, share files, and stay informed without switching tools.",
    icon: <PeopleIcon sx={{ fontSize: 40, color: '#FF6F61' }} />
  },
  {
    title: "Dashboard Overview",
    description: "Understand project health in seconds, not hours.",
    icon: <DashboardIcon sx={{ fontSize: 40, color: '#FF6F61' }} />
  },
  {
    title: "Localized for MENA Region",
    description: "Experience tailored workflows for the MENA region, including Etimad and more, available in Arabic.",
    icon: <LanguageIcon sx={{ fontSize: 40, color: '#FF6F61' }} />
  },
  {
    title: "Smart Notifications",
    description: "Get real-time notifications about pending tasks and critical workflows to stay ahead of deadlines.",
    icon: <NotificationsActiveIcon sx={{ fontSize: 40, color: '#FF6F61' }} />
  },
  {
    title: "Integrated Document Management",
    description: "Upload, manage, and share documents across projects securely, with seamless integration.",
    icon: <FolderSharedIcon sx={{ fontSize: 40, color: '#FF6F61' }} />
  },
];

const plans = [
  {
    title: "Basic",
    price: "$10/mo",
    features: [
      "Up to 5 projects",
      "Basic task management",
      "Email support",
    ],
    buttonText: "Get Started",
    buttonVariant: "contained",
  },
  {
    title: "Pro",
    price: "$20/mo",
    features: [
      "Up to 20 projects",
      "Advanced analytics",
      "Priority support",
      "Voice Note AI Assistant",
    ],
    buttonText: "Get Started",
    buttonVariant: "contained",
  },
  {
    title: "Ultimate",
    price: "$50/mo",
    features: [
      "Unlimited projects",
      "All Pro features",
      "Dedicated account manager",
      "MENA region localization",
    ],
    buttonText: "Get Started",
    buttonVariant: "contained",
  },
];

const BackgroundBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  minHeight: "100vh",
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Poppins, sans-serif',
}));

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const AnimatedButton = styled(Button)(({ theme }) => ({
  animation: `${pulseAnimation} 2s infinite ease-in-out`,
  borderRadius: '30px',
  fontWeight: 'bold',
  padding: '10px 20px',
  textTransform: 'none',
  backgroundColor: '#A8E6CF',
  color: '#000',
  '&:hover': {
    backgroundColor: '#81C784',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#000',
  border: '2px solid #000',
  borderRadius: '16px',
  padding: '16px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  color: '#000',
}));

const FeatureCard = styled(Paper)(({ theme }) => ({
  padding: '24px',
  textAlign: 'center',
  backgroundColor: '#FFEBEE',
  height: '280px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  color: '#000',
  border: '2px solid #000',
  borderRadius: '16px',
  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
  },
}));

const LandingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const videoElement = document.querySelector("video");
    if (videoElement) {
      videoElement.addEventListener("error", (e) => {
        console.error("Video error: ", e);
      });
    }
  }, []);

  const scrollToPricing = () => {
    const pricingSection = document.getElementById('pricing-section');
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <BackgroundBox>
      <Container maxWidth="lg">
        {/* Header Section */}
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#000' }}>
              CtrlPM
            </Typography>
            <Box>
              <Button
                variant="outlined"
                sx={{ color: '#000', borderColor: '#000', borderRadius: '30px', textTransform: 'none', mr: 2 }}
                onClick={() => navigate("/login")}
              >
                Log In
              </Button>
              <Button
                variant="contained"
                sx={{ backgroundColor: '#A8E6CF', color: '#000', borderRadius: '30px', textTransform: 'none' }}
                onClick={() => navigate("/login?tab=signup")}
              >
                Sign Up
              </Button>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Hero Section */}
        <motion.div initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, mt: 8, mb: 8, alignItems: 'center' }}>
            <Box sx={{ flex: 1, textAlign: { xs: 'center', md: 'left' }, pr: { md: 4 } }}>
              <StyledTypography variant="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
                Take Control of Your Projects with Ease—Join the Future of Project Management Today!
              </StyledTypography>
              <StyledTypography variant="h5" gutterBottom>
                Manage projects effortlessly, collaborate seamlessly, and keep everything you need in one place. Discover why professionals are switching to CtrlPM and how you can transform your workflow.
              </StyledTypography>
              <StyledTypography variant="subtitle1" gutterBottom sx={{ maxWidth: 700, mt: 2 }}>
                Achieve your goals effortlessly with a tool designed to simplify your workflow and keep you focused.
              </StyledTypography>
              <Box sx={{ display: 'flex', gap: 2, mt: 4 }}>
                <AnimatedButton
                  variant="contained"
                  size="large"
                  endIcon={<ArrowForwardIcon />}
                  onClick={scrollToPricing}
                >
                  Get Started Today – Don’t Miss Out!
                </AnimatedButton>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{ color: '#000', borderColor: '#000', borderRadius: '30px', textTransform: 'none', padding: '10px 20px' }}
                  onClick={() => navigate("/demo")}
                >
                  Watch a Demo
                </Button>
              </Box>
            </Box>
            <Box sx={{ flex: 1, mt: { xs: 4, md: 0 }, textAlign: 'center' }}>
              <Box>
                <video width="100%" height="100%" controls autoPlay loop playsInline>
                  <source src="https://firebasestorage.googleapis.com/v0/b/ctrlpm.appspot.com/o/CtrlPM%202.mp4?alt=media&token=2bf6411e-2eab-4e3d-bc66-ad00077a9ffb" type="video/mp4" />

                  Your browser does not support the video tag.
                </video>
              </Box>
            </Box>
          </Box>
        </motion.div>

        {/* Problem Section */}
        <Box sx={{ mt: 8 }}>
          <StyledTypography variant="h5" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#FF6F61' }}>
            Struggling with Overwhelming Project Complexity?
          </StyledTypography>
          <StyledTypography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
            Does This Sound Familiar?
          </StyledTypography>
          <StyledTypography
            variant="body1"
            align="center"
            sx={{
              maxWidth: 800,
              mx: "auto",
              mb: 6,
              p: 4,
              backgroundColor: '#FFF8E1',
              borderRadius: '16px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            Managing projects shouldn’t feel like a second job. Tracking timelines, keeping team members aligned, and ensuring you have all your documents at your fingertips—it can get chaotic fast. Without the right tools, staying organized can become impossible, and you end up spending more time chasing information than actually getting work done.
          </StyledTypography>
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <FeatureCard>
                  {feature.icon}
                  <StyledTypography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                    {feature.title}
                  </StyledTypography>
                  <StyledTypography variant="body2">
                    {feature.description}
                  </StyledTypography>
                </FeatureCard>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Pricing Section */}
        <Box id="pricing-section" sx={{ mt: 12 }}>
          <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <StyledTypography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
              Pricing Plans
            </StyledTypography>
            <StyledTypography variant="h6" align="center" gutterBottom sx={{ maxWidth: 700, mx: "auto", mb: 6 }}>
              Choose a plan that fits your needs. Upgrade or cancel anytime.
            </StyledTypography>
          </motion.div>
          <Grid container spacing={4}>
            {plans.map((plan, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: index * 0.2 }}>
                  <StyledCard sx={{ height: 400 }}>
                    <CardContent sx={{ flexGrow: 1 }}>
                      <StyledTypography variant="h5" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                        {plan.title}
                      </StyledTypography>
                      <StyledTypography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
                        {plan.price}
                      </StyledTypography>
                      <ul>
                        {plan.features.map((feature, idx) => (
                          <StyledTypography component="li" key={idx} variant="subtitle1" sx={{ mb: 1 }}>
                            {feature}
                          </StyledTypography>
                        ))}
                      </ul>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center', mb: 2 }}>
                      <Button
                        variant={plan.buttonVariant}
                        size="large"
                        sx={{
                          backgroundColor: '#A8E6CF',
                          color: '#000',
                          borderColor: '#000',
                          borderRadius: '30px',
                          width: '80%',
                          textTransform: 'none',
                          '&:hover': {
                            backgroundColor: '#81C784',
                          },
                        }}
                        onClick={() => navigate("/login?tab=signup")}
                      >
                        {plan.buttonText}
                      </Button>
                    </CardActions>
                  </StyledCard>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* FAQ Section */}
        <Box sx={{ mt: 12 }}>
          <StyledTypography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
            FAQs
          </StyledTypography>
          <Box sx={{ maxWidth: 800, mx: "auto", mt: 4 }}>
            {[
              {
                question: "What is CtrlPM?",
                answer: "CtrlPM is a project management web app that helps you organize and manage your projects efficiently. It provides tools like an AI Assistant, Kanban boards, risk management, file sharing, and more to streamline your workflow.",
              },
              {
                question: "Who can use CtrlPM?",
                answer: "CtrlPM is suitable for project managers, teams, freelancers, and anyone looking to effectively manage their projects. It has features designed for both individuals and collaborative teams.",
              },
              {
                question: "How does the AI Assistant work?",
                answer: "The built-in AI Assistant helps you organize tasks, provides suggestions, and offers assistance to keep your projects on track. It serves as a virtual project manager, making task management much simpler.",
              },
              {
                question: "What features does CtrlPM offer for managing tasks?",
                answer: "CtrlPM offers features like Kanban boards, sticky notes, and timeline management to help you organize tasks, track progress, and manage project scopes in one convenient platform.",
              },
              {
                question: "Can I collaborate with my team using CtrlPM?",
                answer: "Yes, CtrlPM enables real-time collaboration, allowing team members to share files, leave notes, and communicate within the app. This helps everyone stay informed and aligned.",
              },
              {
                question: "How does CtrlPM help with risk management?",
                answer: "CtrlPM has a dedicated risk management feature that allows you to identify, assess, and track potential risks within your projects. This helps you proactively address issues before they become major problems.",
              },
              {
                question: "Is there a dark mode option?",
                answer: "Yes, CtrlPM offers a dark mode option for users who prefer a darker interface. You can easily switch between light and dark modes according to your preference.",
              },
              {
                question: "Is CtrlPM available in multiple languages?",
                answer: "CtrlPM supports both English and Arabic languages, making it accessible for users in the MENA region. Switching between languages is simple and seamless.",
              },
            ].map((faq, index) => (
              <Accordion key={index} sx={{ backgroundColor: '#fff', border: '1px solid #000', borderRadius: '16px', mb: 2 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <StyledTypography>{faq.question}</StyledTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <StyledTypography>
                    {faq.answer}
                  </StyledTypography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>

        {/* Footer Section */}
        <Box sx={{ mt: 12, pb: 4, textAlign: "center", backgroundColor: '#f5f5f5', pt: 4 }}>
          <StyledTypography variant="body2" gutterBottom>
            Content Library | Before/After | Trusted Partners | FAQs
          </StyledTypography>
          <StyledTypography variant="body2">
            &copy; {new Date().getFullYear()} CtrlPM. All rights reserved.
          </StyledTypography>
        </Box>
      </Container>
    </BackgroundBox>
  );
};

export default LandingPage;