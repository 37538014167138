// src/contexts/ProjectsContext.jsx

import React, { createContext, useContext, useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  where,
  onSnapshot,
  addDoc,
  setDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "@/firebase"; // Use alias '@' for '@/firebase'
import { useAuth } from "./AuthContext";
import { useNotification } from "./NotificationContext";

const ProjectsContext = createContext(); // Do not export ProjectsContext directly

export const useProjects = () => useContext(ProjectsContext);

export const ProjectsProvider = ({ children }) => {
  const { user } = useAuth();
  const { addNotification } = useNotification();

  const [projects, setProjects] = useState([]);
  const [archivedProjects, setArchivedProjects] = useState([]);

  useEffect(() => {
    if (!user) {
      setProjects([]);
      setArchivedProjects([]);
      return;
    }

    const userProjectsRef = collection(db, "users", user.uid, "projects");
    const activeProjectsQuery = query(
      userProjectsRef,
      where("archived", "==", false),
      orderBy("createdAt", "desc")
    );
    const archivedProjectsQuery = query(
      userProjectsRef,
      where("archived", "==", true),
      orderBy("createdAt", "desc")
    );

    // Set up real-time listener for active projects
    const unsubscribeActive = onSnapshot(
      activeProjectsQuery,
      (snapshot) => {
        const fetchedProjects = snapshot.docs.map((docSnapshot) => {
          const data = docSnapshot.data();

          return {
            id: docSnapshot.id,
            name: typeof data.name === "string" ? data.name : "Unnamed Project",
            archived: data.archived || false,
            createdAt: data.createdAt?.toDate() || new Date(),
            projectValue: data.projectValue || 0,
            timelineTasks: Array.isArray(data.timelineTasks)
              ? data.timelineTasks
              : [],
            scope: Array.isArray(data.scope) ? data.scope : [],
            invoices: Array.isArray(data.invoices) ? data.invoices : [],
            changeRequests: Array.isArray(data.changeRequests)
              ? data.changeRequests
              : [],
            risks: Array.isArray(data.risks) ? data.risks : [],
            kanbanData: data.kanbanData || {
              todo: { id: "todo", title: "To Do", tasks: [] },
              inProgress: { id: "inProgress", title: "In Progress", tasks: [] },
              done: { id: "done", title: "Done", tasks: [] },
            },
            // Add other properties as needed, ensuring validation
          };
        });
        setProjects(fetchedProjects);
      },
      (error) => {
        console.error("Error fetching active projects: ", error);
        addNotification(
          `Error fetching active projects: ${error.message}`,
          "error"
        );
      }
    );

    // Set up real-time listener for archived projects
    const unsubscribeArchived = onSnapshot(
      archivedProjectsQuery,
      (snapshot) => {
        const fetchedArchivedProjects = snapshot.docs.map((docSnapshot) => {
          const data = docSnapshot.data();

          return {
            id: docSnapshot.id,
            name: typeof data.name === "string" ? data.name : "Unnamed Project",
            archived: data.archived || true,
            createdAt: data.createdAt?.toDate() || new Date(),
            projectValue: data.projectValue || 0,
            timelineTasks: Array.isArray(data.timelineTasks)
              ? data.timelineTasks
              : [],
            scope: Array.isArray(data.scope) ? data.scope : [],
            invoices: Array.isArray(data.invoices) ? data.invoices : [],
            changeRequests: Array.isArray(data.changeRequests)
              ? data.changeRequests
              : [],
            risks: Array.isArray(data.risks) ? data.risks : [],
            kanbanData: data.kanbanData || {
              todo: { id: "todo", title: "To Do", tasks: [] },
              inProgress: { id: "inProgress", title: "In Progress", tasks: [] },
              done: { id: "done", title: "Done", tasks: [] },
            },
            // Add other properties as needed, ensuring validation
          };
        });
        setArchivedProjects(fetchedArchivedProjects);
      },
      (error) => {
        console.error("Error fetching archived projects: ", error);
        addNotification(
          `Error fetching archived projects: ${error.message}`,
          "error"
        );
      }
    );

    // Clean up listeners on unmount
    return () => {
      unsubscribeActive();
      unsubscribeArchived();
    };
  }, [user, addNotification]);

  /**
   * Add a new project
   */
  const addProject = async (projectData) => {
    if (!user) {
      addNotification("User not authenticated.", "error");
      return;
    }

    try {
      const userProjectsRef = collection(db, "users", user.uid, "projects");
      const newProjectData = {
        name:
          typeof projectData.name === "string"
            ? projectData.name
            : "Unnamed Project",
        archived: false,
        createdAt: new Date(),
        projectValue: 0,
        timelineTasks: [],
        scope: [],
        invoices: [],
        changeRequests: [],
        risks: [],
        kanbanData: {
          todo: { id: "todo", title: "To Do", tasks: [] },
          inProgress: { id: "inProgress", title: "In Progress", tasks: [] },
          done: { id: "done", title: "Done", tasks: [] },
        },
        // Do not spread projectData to avoid issues
      };

      const docRef = await addDoc(userProjectsRef, newProjectData);
      const newProject = { id: docRef.id, ...newProjectData };
      setProjects((prevProjects) => [newProject, ...prevProjects]); // Add new project to state
      addNotification("Project added successfully!", "success");
      return newProject;
    } catch (error) {
      console.error("Error adding project:", error);
      addNotification("Error adding project.", "error");
    }
  };

  /**
   * Update an existing project
   */
  const updateProject = async (projectId, updatedData) => {
    if (!user) {
      addNotification("User not authenticated.", "error");
      return;
    }

    try {
      const projectRef = doc(db, "users", user.uid, "projects", projectId);
      await setDoc(projectRef, updatedData, { merge: true });

      // Update project in the state without requiring a refresh
      setProjects((prevProjects) =>
        prevProjects.map((project) =>
          project.id === projectId ? { ...project, ...updatedData } : project
        )
      );

      setArchivedProjects((prevArchivedProjects) =>
        prevArchivedProjects.map((project) =>
          project.id === projectId ? { ...project, ...updatedData } : project
        )
      );

      addNotification("Project updated successfully!", "success");
    } catch (error) {
      console.error("Error updating project:", error);
      addNotification("Error updating project.", "error");
    }
  };

  /**
   * Archive a project
   */
  const archiveProject = async (projectId) => {
    await updateProject(projectId, { archived: true });
    addNotification("Project archived successfully.", "info");
  };

  /**
   * Restore an archived project
   */
  const restoreProject = async (projectId) => {
    await updateProject(projectId, { archived: false });
    addNotification("Project restored successfully.", "success");
  };

  /**
   * Delete a project
   */
  const deleteProject = async (projectId) => {
    if (!user) {
      addNotification("User not authenticated.", "error");
      return;
    }

    try {
      const projectRef = doc(db, "users", user.uid, "projects", projectId);
      await deleteDoc(projectRef);

      // Remove the project from state
      setProjects((prevProjects) =>
        prevProjects.filter((project) => project.id !== projectId)
      );
      setArchivedProjects((prevArchivedProjects) =>
        prevArchivedProjects.filter((project) => project.id !== projectId)
      );

      addNotification("Project deleted successfully.", "info");
    } catch (error) {
      console.error("Error deleting project:", error);
      addNotification("Error deleting project.", "error");
    }
  };

  // Provide context value
  const value = {
    projects,
    archivedProjects,
    addProject,
    updateProject,
    archiveProject,
    restoreProject,
    deleteProject,
  };

  return (
    <ProjectsContext.Provider value={value}>
      {children}
    </ProjectsContext.Provider>
  );
};
