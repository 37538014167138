// src/components/ArchivedTasks.jsx

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Card,
  CardContent,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { formatDate } from '../utils/dateUtils';

const priorityColors = {
  low: '#8BC34A',
  medium: '#FFC107',
  high: '#F44336',
};

const ArchivedTasks = ({ projectId, updateProject }) => {
  const [archivedTasks, setArchivedTasks] = useState([]);

  useEffect(() => {
    const fetchArchivedTasks = async () => {
      const archivedTasksRef = collection(
        db,
        "users",
        auth.currentUser.uid,
        "projects",
        projectId,
        "archivedTasks"
      );
      const snapshot = await getDocs(archivedTasksRef);
      const tasks = snapshot.docs.map(docSnap => ({ id: docSnap.id, ...docSnap.data() }));
      setArchivedTasks(tasks);
    };

    fetchArchivedTasks();
  }, [projectId]);

  const restoreTask = async (task) => {
    try {
      const updatedColumns = {
        ...updateProject.kanbanData,
        done: {
          ...updateProject.kanbanData.done,
          tasks: [...updateProject.kanbanData.done.tasks, task],
        },
      };
      updateProject({ ...updateProject, kanbanData: updatedColumns });

      const taskRef = doc(db, "users", auth.currentUser.uid, "projects", projectId, "archivedTasks", task.id);
      await deleteDoc(taskRef);

      setArchivedTasks(prev => prev.filter(t => t.id !== task.id));
    } catch (error) {
      console.error("Error restoring task:", error);
    }
  };

  const deleteArchivedTask = async (taskId) => {
    try {
      const taskRef = doc(db, "users", auth.currentUser.uid, "projects", projectId, "archivedTasks", taskId);
      await deleteDoc(taskRef);
      setArchivedTasks(prev => prev.filter(t => t.id !== taskId));
    } catch (error) {
      console.error("Error deleting archived task:", error);
    }
  };

  return (
    <Box>
      {archivedTasks.length === 0 ? (
        <Typography variant="body2" color="text.secondary">
          No archived tasks.
        </Typography>
      ) : (
        archivedTasks.map(task => (
          <Card key={task.id} sx={{ mb: 1, borderLeft: `5px solid ${priorityColors[task.priority]}` }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="subtitle1">
                  {task.content}
                </Typography>
                <Box>
                  <IconButton size="small" onClick={() => restoreTask(task)}>
                    <RestoreIcon fontSize="small" />
                  </IconButton>
                  <IconButton size="small" onClick={() => deleteArchivedTask(task.id)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
              <Typography variant="body2" color="text.secondary">
                {task.description}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Created: {formatDate(task.createdAt)}
              </Typography>
              {task.dueDate && (
                <Typography variant="caption" color="error">
                  Due: {formatDate(task.dueDate)}
                </Typography>
              )}
            </CardContent>
          </Card>
        ))
      )}
    </Box>
  );
};

export default ArchivedTasks;
