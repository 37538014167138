// src/components/shadcn-ui/Snackbar.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { MdCheckCircle, MdError, MdInfo, MdWarning } from 'react-icons/md';

const Snackbar = ({ open, message, severity, onClose }) => {
  const iconMap = {
    success: <MdCheckCircle size={24} className="text-green-500" />,
    error: <MdError size={24} className="text-red-500" />,
    info: <MdInfo size={24} className="text-blue-500" />,
    warning: <MdWarning size={24} className="text-yellow-500" />,
  };

  if (!open) return null;

  return (
    <div className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-white dark:bg-gray-800 shadow-lg rounded-lg flex items-center p-4 space-x-4 transition-all duration-300 z-50`}>
      {iconMap[severity] || iconMap.info}
      <span className={`text-gray-800 dark:text-gray-200`}>{message}</span>
      <button onClick={onClose} className="ml-4 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300">
        &times;
      </button>
    </div>
  );
};

Snackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
  onClose: PropTypes.func.isRequired,
};

export default Snackbar;
