// src/App.jsx

import React, { useState, useEffect, useMemo } from "react";
import "./App.css";

// Material-UI Components
import {
  Box,
  CssBaseline,
  useMediaQuery,
  ThemeProvider,
  createTheme,
  Typography,
  Drawer,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Fade,
  Container,
  Paper,
} from "@mui/material";

// React Router Components
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
  Outlet,
  useParams, // Import useParams
} from "react-router-dom";

// Material-UI Icons
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

// Firebase Imports
import { signOut } from "firebase/auth";
import { auth, storage } from "@/firebase"; // Use alias '@' for '@/firebase'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Import Components Using Aliases
import Sidebar from "@components/Sidebar";
import ScopeList from "@components/ScopeList";
import Invoices from "@components/Invoices";
import FileUpload from "@components/FileUpload";
import StickyNotesBoard from "@components/StickyNotesBoard";
import ProjectHeader from "@components/ProjectHeader";
import DashboardOverview from "@components/DashboardOverview";
import RiskManagement from "@components/RiskManagement";
import KanbanPage from "@components/KanbanPage";
import TimelineImport from "@components/TimelineImport";
import ErrorBoundary from "@components/ErrorBoundary";
import Notification from "@components/Notification";
import Loading from "@components/Loading";
import PublicPage from "@components/PublicPage";
import LandingPage from "@components/LandingPage";
import LoginPage from "@components/LoginPage";
import AIAssistant from "@components/AIAssistant"; // Import the AI Assistant component

// Import Contexts Using Aliases
import { AuthProvider, useAuth } from "@contexts/AuthContext";
import {
  NotificationProvider,
  useNotification,
} from "@contexts/NotificationContext";
import { ProjectsProvider, useProjects } from "@contexts/ProjectsContext";

// Import Constants Using Aliases
import { initialKanbanColumns } from "@constants/kanban";

/**
 * ThemeProviderWrapper Component
 * Handles theming based on dark mode and language direction.
 */
const ThemeProviderWrapper = ({
  children,
  isDarkMode,
  setIsDarkMode,
  isArabic,
  setIsArabic,
  isMobile,
}) => {
  const customTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: isDarkMode ? "dark" : "light",
          primary: {
            main: "#00ADB5", // Primary accent color
          },
          secondary: {
            main: "#393E46", // Secondary color
          },
          background: {
            default: isDarkMode ? "#121212" : "#EEEEEE", // Background color
            paper: isDarkMode ? "#1e1e1e" : "#FFFFFF", // Paper elements background
          },
          text: {
            primary: isDarkMode ? "#FFFFFF" : "#222831", // Text color
            secondary: "#00ADB5",
          },
        },
        direction: isArabic ? "rtl" : "ltr",
        typography: {
          fontFamily: "Roboto, Arial, sans-serif",
          h1: { fontWeight: 700 },
          h2: { fontWeight: 600 },
          h3: { fontWeight: 600 },
          h4: { fontWeight: 600 },
          h5: { fontWeight: 500 },
          h6: { fontWeight: 500 },
          body1: {
            fontSize: "1rem",
            lineHeight: 1.5,
            textAlign: isArabic ? "right" : "left",
          },
          body2: {
            fontSize: "0.875rem",
            lineHeight: 1.43,
            textAlign: isArabic ? "right" : "left",
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: "8px",
                textTransform: "none",
                fontWeight: 500,
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                borderRadius: "12px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              },
            },
          },
        },
      }),
    [isDarkMode, isArabic]
  );

  return (
    <ThemeProvider theme={customTheme}>
      {React.cloneElement(children, {
        isDarkMode,
        setIsDarkMode,
        isArabic,
        setIsArabic,
        isMobile,
      })}
    </ThemeProvider>
  );
};

/**
 * ProtectedRoutes Component
 * Guards routes that require authentication.
 */
const ProtectedRoutes = () => {
  const { user, authLoading } = useAuth();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isArabic, setIsArabic] = useState(false);
  const defaultTheme = createTheme();
  const isMobile = useMediaQuery(defaultTheme.breakpoints.down("sm"));

  if (authLoading) {
    return <Loading />;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return (
    <ProjectsProvider>
      <ThemeProviderWrapper
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
        isArabic={isArabic}
        setIsArabic={setIsArabic}
        isMobile={isMobile}
      >
        <Outlet />
      </ThemeProviderWrapper>
    </ProjectsProvider>
  );
};

/**
 * MainApp Component
 * Represents the main content area of the application.
 */
const MainApp = ({
  isDarkMode,
  setIsDarkMode,
  isArabic,
  setIsArabic,
  isMobile,
}) => {
  const { user } = useAuth();
  const { addNotification } = useNotification(); // Using Notification Context
  const {
    projects,
    archivedProjects,
    addProject,
    updateProject,
    archiveProject,
    restoreProject,
    deleteProject,
  } = useProjects();
  const navigate = useNavigate();
  const { projectId } = useParams(); // Get projectId from URL

  // Find the selected project based on projectId
  const selectedProject = projects.find((proj) => proj.id === projectId);

  // Currency states
  const [currency, setCurrency] = useState("USD");
  const [customCurrency, setCustomCurrency] = useState("");

  // Sidebar state
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile);

  // Fade-in effect for selected project
  const [fadeIn, setFadeIn] = useState(false);

  // Dashboard visibility
  const [showDashboard, setShowDashboard] = useState(true);

  // Confirmation dialog state
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    type: "",
    projectId: null,
  });

  // Search states
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);

  /**
   * Function to calculate project progress based on scope completion.
   */
  const calculateProgress = (scope) => {
    if (!scope || scope.length === 0) return 0;
    const completedItems = scope.filter((item) => item.completed).length;
    return (completedItems / scope.length) * 100;
  };

  /**
   * Handler for importing tasks into a project.
   */
  const handleImportTasks = (importedTasks) => {
    if (!selectedProject) return;

    const updatedProject = {
      ...selectedProject,
      timelineTasks: [
        ...(selectedProject.timelineTasks || []),
        ...importedTasks.map((task) => ({
          ...task,
          isComplete: task.completionPercentage === 100,
        })),
      ],
    };
    updateProject(selectedProject.id, updatedProject);
    addNotification("Tasks imported successfully!", "success");
  };

  /**
   * Logout handler
   */
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
      addNotification("Logged out successfully.", "success");
    } catch (error) {
      console.error("Error logging out:", error);
      addNotification("Error logging out.", "error");
    }
  };

  /**
   * Handle file upload to Firebase Storage
   */
  const handleFileUpload = async (file) => {
    if (!user || !selectedProject) return;

    const storageRef = ref(
      storage,
      `users/${user.uid}/projects/${selectedProject.id}/files/${file.name}_${Date.now()}`
    );

    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      console.log("File uploaded:", downloadURL);

      // Update the project files in Firestore
      const updatedFiles = [
        ...(selectedProject.files || []),
        { name: file.name, url: downloadURL },
      ];
      updateProject(selectedProject.id, { files: updatedFiles });
      addNotification("File uploaded successfully!", "success");
    } catch (error) {
      console.error("Error uploading file:", error);
      addNotification("Error uploading file.", "error");
    }
  };

  /**
   * Implementing Debouncing for Project Search
   */
  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm.trim() === "") {
        setFilteredProjects(projects);
      } else {
        const lowercased = searchTerm.toLowerCase();
        setFilteredProjects(
          projects.filter((project) =>
            project.name.toLowerCase().includes(lowercased)
          )
        );
      }
    }, 300); // 300ms debounce

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, projects]);

  /**
   * Initialize filteredProjects when projects change and there's no search term
   */
  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredProjects(projects);
    }
  }, [projects, searchTerm]);

  // Initialize filteredProjects on component mount
  useEffect(() => {
    setFilteredProjects(projects);
  }, [projects]);

  /**
   * Fade-in effect when a project is selected.
   */
  useEffect(() => {
    setFadeIn(true);
  }, [selectedProject]);

  /**
   * Handler for confirmation dialogs (archive/delete).
   */
  const handleConfirmAction = () => {
    if (confirmDialog.type === "archive") {
      archiveProject(confirmDialog.projectId);
      addNotification("Project archived successfully.", "success");
    } else if (confirmDialog.type === "delete") {
      deleteProject(confirmDialog.projectId);
      addNotification("Project deleted successfully.", "success");
    }
    setConfirmDialog({ open: false, type: "", projectId: null });
  };

  // Main rendering logic
  return (
    <Box
      className={`app ${isDarkMode ? "dark-mode" : ""} ${
        isArabic ? "rtl" : ""
      }`}
      sx={{
        display: "flex",
        minHeight: "100vh",
        backgroundColor: "background.default",
      }}
    >
      <CssBaseline />

      {/* Mobile Sidebar Toggle Button */}
      {isMobile && (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => setSidebarOpen(true)}
          sx={{ position: "fixed", top: 16, left: 16, zIndex: 1300 }}
        >
          <MenuIcon />
        </IconButton>
      )}

      {/* Sidebar */}
      <Drawer
        variant={isMobile ? "temporary" : "persistent"}
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        ModalProps={{ keepMounted: true }}
        sx={{
          "& .MuiDrawer-paper": {
            width: 250,
            boxSizing: "border-box",
            transition: "width 0.3s ease",
            zIndex: 1201,
            backgroundColor: "background.paper",
            color: "text.primary",
          },
        }}
      >
        {isMobile && (
          <IconButton
            edge="end"
            color="inherit"
            aria-label="close"
            onClick={() => setSidebarOpen(false)}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
        )}

        <Sidebar
          projects={filteredProjects}
          archivedProjects={archivedProjects}
          addProject={addProject}
          archiveProject={(projectId) =>
            setConfirmDialog({ open: true, type: "archive", projectId })
          }
          deleteProject={(projectId) =>
            setConfirmDialog({ open: true, type: "delete", projectId })
          }
          restoreProject={restoreProject}
          isOpen={sidebarOpen}
          isDarkMode={isDarkMode}
          toggleDarkMode={() => setIsDarkMode((prev) => !prev)}
          isArabic={isArabic}
          toggleLanguage={() => setIsArabic((prev) => !prev)}
          toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
          showDashboard={showDashboard}
          setShowDashboard={setShowDashboard}
          handleLogout={handleLogout}
        />
      </Drawer>

      {/* Main Content Area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: isMobile ? 0 : "250px",
          transition: "margin 0.3s ease",
          textAlign: isArabic ? "right" : "left",
          color: "text.primary",
          backgroundColor: "background.default",
        }}
      >
        <Container maxWidth="xl">
          {/* Search Input (Implementing Debouncing) */}
          <Box sx={{ mb: 3 }}>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={
                isArabic ? "بحث عن مشاريع..." : "Search projects..."
              }
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "8px",
                border: "1px solid #ccc",
                fontSize: "1rem",
                textAlign: isArabic ? "right" : "left",
              }}
            />
          </Box>

          {showDashboard ? (
            <DashboardOverview
              projects={filteredProjects}
              currency={currency}
              userName={user ? user.displayName || user.email : "User"}
              // ... other props as needed
            />
          ) : selectedProject ? (
            <Fade in={fadeIn} timeout={1000}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                {/* Project Header */}
                <Paper elevation={3} sx={{ p: 3 }}>
                  <ProjectHeader
                    selectedProject={selectedProject}
                    updateProject={(updatedData) =>
                      updateProject(selectedProject.id, updatedData)
                    }
                    calculateProgress={calculateProgress}
                    currency={currency}
                    customCurrency={customCurrency}
                    setCurrency={setCurrency}
                    setCustomCurrency={setCustomCurrency}
                    onDelete={(projectId) =>
                      setConfirmDialog({
                        open: true,
                        type: "delete",
                        projectId,
                      })
                    }
                    onArchive={(projectId) =>
                      setConfirmDialog({
                        open: true,
                        type: "archive",
                        projectId,
                      })
                    }
                  />
                </Paper>

                {/* Sticky Notes Board */}
                <Paper elevation={3} sx={{ p: 3 }}>
                  <StickyNotesBoard projectId={selectedProject.id} />
                </Paper>

                {/* Kanban Board */}
                <Paper elevation={3} sx={{ p: 3 }}>
                  <KanbanPage
                    kanbanData={
                      selectedProject.kanbanData || initialKanbanColumns
                    }
                    updateKanbanData={(updatedKanbanData) =>
                      updateProject(selectedProject.id, {
                        kanbanData: updatedKanbanData,
                      })
                    }
                    selectedProject={selectedProject}
                    updateProject={updateProject}
                  />
                </Paper>

                {/* Scope List */}
                <Paper elevation={3} sx={{ p: 3 }}>
                  <ScopeList
                    scope={selectedProject.scope || []}
                    updateScope={(scope) =>
                      updateProject(selectedProject.id, { scope })
                    }
                    paymentTerms={selectedProject.paymentTerms || []}
                    updatePaymentTerms={(paymentTerms) =>
                      updateProject(selectedProject.id, {
                        paymentTerms,
                      })
                    }
                    changeRequests={selectedProject.changeRequests || []}
                    updateChangeRequests={(changeRequests) =>
                      updateProject(selectedProject.id, {
                        changeRequests,
                      })
                    }
                  />
                </Paper>

                {/* Risk Management */}
                <Paper elevation={3} sx={{ p: 3 }}>
                  <RiskManagement
                    risks={selectedProject.risks || []}
                    updateRisks={(risks) =>
                      updateProject(selectedProject.id, { risks })
                    }
                  />
                </Paper>

                {/* Invoices */}
                <Paper elevation={3} sx={{ p: 3 }}>
                  <Invoices
                    invoices={selectedProject.invoices || []}
                    updateInvoices={(invoices) =>
                      updateProject(selectedProject.id, { invoices })
                    }
                    currency={currency === "custom" ? customCurrency : currency}
                    customCurrency={customCurrency}
                  />
                </Paper>

                {/* Timeline Import */}
                <Paper elevation={3} sx={{ p: 3 }}>
                  <TimelineImport onImport={handleImportTasks} />
                </Paper>

                {/* File Upload */}
                <Paper elevation={3} sx={{ p: 3 }}>
                  <FileUpload
                    files={selectedProject.files || []}
                    updateFiles={(files) =>
                      updateProject(selectedProject.id, { files })
                    }
                    handleFileUpload={handleFileUpload}
                  />
                </Paper>
              </Box>
            </Fade>
          ) : (
            <Typography variant="h5" sx={{ textAlign: "center", mt: 4 }}>
              {isArabic
                ? "الرجاء اختيار مشروع أو إنشاء مشروع جديد"
                : "Please select a project or create a new one"}
            </Typography>
          )}
        </Container>
      </Box>

      {/* Confirmation Dialog for Archive/Delete */}
      <Dialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ ...confirmDialog, open: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {confirmDialog.type === "archive"
            ? isArabic
              ? "أرشفة المشروع"
              : "Archive Project"
            : isArabic
            ? "حذف المشروع"
            : "Delete Project"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmDialog.type === "archive"
              ? isArabic
                ? "هل أنت متأكد أنك تريد أرشفة هذا المشروع؟ يمكنك استعادته لاحقًا من قائمة المشاريع المؤرشفة."
                : "Are you sure you want to archive this project? You can restore it later from the archived projects list."
              : isArabic
              ? "هل أنت متأكد أنك تريد حذف هذا المشروع؟ هذا الإجراء لا يمكن التراجع عنه."
              : "Are you sure you want to delete this project? This action cannot be undone."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setConfirmDialog({
                ...confirmDialog,
                open: false,
              })
            }
          >
            {isArabic ? "إلغاء" : "Cancel"}
          </Button>
          <Button onClick={handleConfirmAction} autoFocus>
            {isArabic ? "تأكيد" : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

/**
 * Main App Component
 * Sets up routing and context providers.
 */
const App = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <AuthProvider>
          <NotificationProvider>
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/public" element={<PublicPage />} />
              <Route path="/notifications" element={<Notification />} />

              {/* Protected Routes */}
              <Route path="/app/*" element={<ProtectedRoutes />}>
                <Route path="" element={<MainApp />} />
                <Route path="projects/:projectId" element={<MainApp />} />
                <Route path="ai-assistant" element={<AIAssistant />} />
                {/* Add other protected child routes here */}
              </Route>

              {/* Catch-all Route */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </NotificationProvider>
        </AuthProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
