import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  IconButton,
  TextField,
  Tooltip,
  Divider,
  Button,
  Grid,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"; // For download actions
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // For completion indicator
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import UploadFileIcon from "@mui/icons-material/UploadFile"; // For approval file upload

const ScopeList = () => {
  const [scope, setScope] = useState([]);
  const [newScopeItem, setNewScopeItem] = useState("");
  const [editingItem, setEditingItem] = useState(null);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [newPaymentTerm, setNewPaymentTerm] = useState("");
  const [editingTerm, setEditingTerm] = useState(null);
  const [changeRequests, setChangeRequests] = useState([]);
  const [newCRItem, setNewCRItem] = useState("");
  const [editingCRItem, setEditingCRItem] = useState(null);

  // Alert states
  const [alert, setAlert] = useState({ type: "", message: "", open: false });

  // Utility function to safely create object URLs
  const safeCreateObjectURL = (file) => {
    if (file && file instanceof Blob) {
      return URL.createObjectURL(file);
    }
    return null;
  };

  // Cleanup object URLs on component unmount
  useEffect(() => {
    return () => {
      // Revoke URLs for scope items
      scope.forEach((item) => {
        if (item.supportDocuments) {
          Object.values(item.supportDocuments).forEach((doc) => {
            if (doc.file && doc.url) {
              URL.revokeObjectURL(doc.url);
            }
          });
        }
        // Revoke approval files
        if (item.approvalFile && item.approvalFile.url) {
          URL.revokeObjectURL(item.approvalFile.url);
        }
      });

      // Revoke URLs for payment terms
      paymentTerms.forEach((term) => {
        if (term.invoice && term.invoice.file && term.invoice.url) {
          URL.revokeObjectURL(term.invoice.url);
        }
      });

      // Revoke URLs for change requests
      changeRequests.forEach((item) => {
        if (item.supportDocuments) {
          Object.values(item.supportDocuments).forEach((doc) => {
            if (doc.file && doc.url) {
              URL.revokeObjectURL(doc.url);
            }
          });
        }
      });
    };
  }, [scope, paymentTerms, changeRequests]);

  // ----------------- Alert Handling -----------------

  const showAlert = (type, message) => {
    setAlert({ type, message, open: true });
    setTimeout(() => {
      setAlert({ type: "", message: "", open: false });
    }, 3000);
  };

  // ----------------- Scope Items Functions -----------------

  // Add a new scope item
  const addScopeItem = () => {
    if (newScopeItem.trim()) {
      setScope([
        ...scope,
        {
          id: Date.now(),
          text: newScopeItem,
          supportDocuments: {
            deliveryNote: { file: null, url: null },
          },
          isComplete: false,
          deliveryNoteSent: false,
          deliveryNoteSentDate: null,
          deliveryNoteSentVia: "",
          deliveryNoteSentViaCustom: "",
          isEditingSentMethod: false,
          approved: false,
          approvedDate: null,
          approvedVia: "",
          approvedViaCustom: "",
          isEditingApprovalMethod: false,
          approvalFile: { file: null, url: null },
        },
      ]);
      setNewScopeItem("");
      showAlert("success", "Scope item added successfully.");
    } else {
      showAlert("error", "Scope item cannot be empty.");
    }
  };

  // Edit an existing scope item
  const editScopeItem = (id, newText) => {
    if (newText.trim()) {
      setScope(
        scope.map((item) =>
          item.id === id ? { ...item, text: newText } : item,
        ),
      );
      setEditingItem(null);
      showAlert("success", "Scope item updated successfully.");
    } else {
      showAlert("error", "Scope item cannot be empty.");
    }
  };

  // Remove a scope item
  const removeScopeItem = (id) => {
    if (window.confirm("Are you sure you want to delete this scope item?")) {
      setScope(scope.filter((item) => item.id !== id));
      showAlert("info", "Scope item deleted.");
    }
  };

  // Handle file upload for Delivery Note
  const handleFileUpload = (itemId, docType, file) => {
    setScope(
      scope.map((item) => {
        if (item.id === itemId) {
          const supportDocuments = item.supportDocuments || {
            deliveryNote: { file: null, url: null },
          };
          return {
            ...item,
            supportDocuments: {
              ...supportDocuments,
              [docType]: {
                file: file,
                url: safeCreateObjectURL(file),
              },
            },
            isComplete: true,
          };
        }
        return item;
      }),
    );
    showAlert("success", "File uploaded successfully.");
  };

  // Remove a file from a scope item
  const removeFile = (itemId, docType) => {
    setScope(
      scope.map((item) => {
        if (item.id === itemId) {
          const supportDocuments = item.supportDocuments;
          if (supportDocuments && supportDocuments[docType]) {
            if (supportDocuments[docType].url) {
              URL.revokeObjectURL(supportDocuments[docType].url);
            }
            return {
              ...item,
              supportDocuments: {
                ...supportDocuments,
                [docType]: { file: null, url: null },
              },
              isComplete: false,
            };
          }
        }
        return item;
      }),
    );
    showAlert("info", "File removed.");
  };

  // Start editing the sent method
  const startEditingSentMethod = (itemId) => {
    setScope(
      scope.map((item) =>
        item.id === itemId ? { ...item, isEditingSentMethod: true } : item,
      ),
    );
  };

  // Handle Delivery Note method change
  const handleDeliveryNoteMethodChange = (itemId, method) => {
    setScope(
      scope.map((item) =>
        item.id === itemId
          ? {
              ...item,
              deliveryNoteSentVia: method,
              // Reset custom field if method is not 'Custom'
              deliveryNoteSentViaCustom:
                method === "Custom" ? item.deliveryNoteSentViaCustom : "",
            }
          : item,
      ),
    );
  };

  // Handle Delivery Note custom method change
  const handleDeliveryNoteCustomMethodChange = (itemId, customMethod) => {
    setScope(
      scope.map((item) =>
        item.id === itemId
          ? {
              ...item,
              deliveryNoteSentViaCustom: customMethod,
            }
          : item,
      ),
    );
  };

  // Save the sent method and proceed to file upload
  const saveSentMethod = (itemId) => {
    const item = scope.find((item) => item.id === itemId);
    if (
      item.deliveryNoteSentVia &&
      (item.deliveryNoteSentVia !== "Custom" ||
        (item.deliveryNoteSentVia === "Custom" &&
          item.deliveryNoteSentViaCustom.trim()))
    ) {
      setScope(
        scope.map((item) =>
          item.id === itemId
            ? {
                ...item,
                deliveryNoteSent: true,
                deliveryNoteSentDate: new Date().toLocaleDateString(),
                isEditingSentMethod: false,
              }
            : item,
        ),
      );
      showAlert(
        "success",
        "Delivery Note method saved successfully. Please upload the Delivery Note file.",
      );
    } else {
      showAlert(
        "error",
        "Please select a method or provide a custom method if 'Custom' is selected.",
      );
    }
  };

  // Start editing the approval method
  const startEditingApprovalMethod = (itemId) => {
    setScope(
      scope.map((item) =>
        item.id === itemId ? { ...item, isEditingApprovalMethod: true } : item,
      ),
    );
  };

  // Handle Approval method change
  const handleApprovalMethodChange = (itemId, method) => {
    setScope(
      scope.map((item) =>
        item.id === itemId
          ? {
              ...item,
              approvedVia: method,
              // Reset custom field if method is not 'Custom'
              approvedViaCustom:
                method === "Custom" ? item.approvedViaCustom : "",
            }
          : item,
      ),
    );
  };

  // Handle Approval custom method change
  const handleApprovalCustomMethodChange = (itemId, customMethod) => {
    setScope(
      scope.map((item) =>
        item.id === itemId
          ? {
              ...item,
              approvedViaCustom: customMethod,
            }
          : item,
      ),
    );
  };

  // Save the approval method and proceed to file upload
  const saveApprovalMethod = (itemId) => {
    const item = scope.find((item) => item.id === itemId);
    if (
      item.approvedVia &&
      (item.approvedVia !== "Custom" ||
        (item.approvedVia === "Custom" && item.approvedViaCustom.trim()))
    ) {
      setScope(
        scope.map((item) =>
          item.id === itemId
            ? {
                ...item,
                approved: true,
                approvedDate: new Date().toLocaleDateString(),
                isEditingApprovalMethod: false,
              }
            : item,
        ),
      );
      showAlert(
        "success",
        "Approval method saved successfully. Please upload the Approval Document.",
      );
    } else {
      showAlert(
        "error",
        "Please select a method or provide a custom method if 'Custom' is selected.",
      );
    }
  };

  // Mark Delivery Note as sent and start editing method
  const markDeliveryNoteSent = (itemId) => {
    startEditingSentMethod(itemId);
  };

  // Mark Approval and start editing method
  const markApproved = (itemId) => {
    startEditingApprovalMethod(itemId);
  };

  // ----------------- Approval File Upload -----------------

  // Handle approval file upload
  const handleApprovalFileUpload = (itemId, file) => {
    setScope(
      scope.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            approvalFile: {
              file: file,
              url: safeCreateObjectURL(file),
            },
          };
        }
        return item;
      }),
    );
    showAlert("success", "Approval document uploaded successfully.");
  };

  // Remove approval file
  const removeApprovalFile = (itemId) => {
    setScope(
      scope.map((item) => {
        if (item.id === itemId && item.approvalFile.url) {
          URL.revokeObjectURL(item.approvalFile.url);
          return {
            ...item,
            approvalFile: { file: null, url: null },
          };
        }
        return item;
      }),
    );
    showAlert("info", "Approval document removed.");
  };

  // ----------------- Payment Terms Functions -----------------

  // Add a new payment term
  const addPaymentTerm = () => {
    if (newPaymentTerm.trim()) {
      setPaymentTerms([
        ...paymentTerms,
        {
          id: Date.now(),
          text: newPaymentTerm,
          invoice: { file: null, url: null },
          isComplete: false,
        },
      ]);
      setNewPaymentTerm("");
      showAlert("success", "Payment term added successfully.");
    } else {
      showAlert("error", "Payment term cannot be empty.");
    }
  };

  // Edit an existing payment term
  const editPaymentTerm = (id, newText) => {
    if (newText.trim()) {
      setPaymentTerms(
        paymentTerms.map((term) =>
          term.id === id ? { ...term, text: newText } : term,
        ),
      );
      setEditingTerm(null);
      showAlert("success", "Payment term updated successfully.");
    } else {
      showAlert("error", "Payment term cannot be empty.");
    }
  };

  // Remove a payment term
  const removePaymentTerm = (id) => {
    if (window.confirm("Are you sure you want to delete this payment term?")) {
      setPaymentTerms(paymentTerms.filter((term) => term.id !== id));
      showAlert("info", "Payment term deleted.");
    }
  };

  // Handle invoice upload for payment term
  const handleInvoiceUpload = (termId, file) => {
    setPaymentTerms(
      paymentTerms.map((term) => {
        if (term.id === termId) {
          return {
            ...term,
            invoice: {
              file: file,
              url: safeCreateObjectURL(file),
            },
            isComplete: true,
          };
        }
        return term;
      }),
    );
    showAlert("success", "Invoice uploaded successfully.");
  };

  // Remove invoice from payment term
  const removeInvoice = (termId) => {
    setPaymentTerms(
      paymentTerms.map((term) => {
        if (term.id === termId && term.invoice.url) {
          URL.revokeObjectURL(term.invoice.url);
          return {
            ...term,
            invoice: { file: null, url: null },
            isComplete: false,
          };
        }
        return term;
      }),
    );
    showAlert("info", "Invoice removed.");
  };

  // ----------------- Change Requests Functions -----------------

  // Add a new change request
  const addCRItem = () => {
    if (newCRItem.trim()) {
      setChangeRequests([
        ...changeRequests,
        {
          id: Date.now(),
          text: newCRItem,
          supportDocuments: {
            po: { file: null, url: null },
            deliveryNote: { file: null, url: null },
            invoice: { file: null, url: null },
          },
          isComplete: false,
        },
      ]);
      setNewCRItem("");
      showAlert("success", "Change request added successfully.");
    } else {
      showAlert("error", "Change request cannot be empty.");
    }
  };

  // Edit an existing change request
  const editCRItem = (id, newText) => {
    if (newText.trim()) {
      setChangeRequests(
        changeRequests.map((item) =>
          item.id === id ? { ...item, text: newText } : item,
        ),
      );
      setEditingCRItem(null);
      showAlert("success", "Change request updated successfully.");
    } else {
      showAlert("error", "Change request cannot be empty.");
    }
  };

  // Remove a change request
  const removeCRItem = (id) => {
    if (
      window.confirm("Are you sure you want to delete this change request?")
    ) {
      setChangeRequests(changeRequests.filter((item) => item.id !== id));
      showAlert("info", "Change request deleted.");
    }
  };

  // Handle file upload for change request documents
  const handleCRFileUpload = (itemId, docType, file) => {
    setChangeRequests(
      changeRequests.map((item) => {
        if (item.id === itemId) {
          const supportDocuments = item.supportDocuments || {
            po: { file: null, url: null },
            deliveryNote: { file: null, url: null },
            invoice: { file: null, url: null },
          };
          return {
            ...item,
            supportDocuments: {
              ...supportDocuments,
              [docType]: {
                file: file,
                url: safeCreateObjectURL(file),
              },
            },
          };
        }
        return item;
      }),
    );
    showAlert("success", "File uploaded successfully.");
  };

  // Remove a file from a change request
  const removeCRFile = (itemId, docType) => {
    setChangeRequests(
      changeRequests.map((item) => {
        if (item.id === itemId && item.supportDocuments[docType].url) {
          URL.revokeObjectURL(item.supportDocuments[docType].url);
          return {
            ...item,
            supportDocuments: {
              ...item.supportDocuments,
              [docType]: { file: null, url: null },
            },
          };
        }
        return item;
      }),
    );
    showAlert("info", "File removed.");
  };

  // Toggle completion status of a change request
  const toggleCompleteCRItem = (id) => {
    setChangeRequests(
      changeRequests.map((item) =>
        item.id === id ? { ...item, isComplete: !item.isComplete } : item,
      ),
    );
    showAlert("info", "Change request status updated.");
  };

  return (
    <Paper sx={{ padding: 3, marginBottom: 3, borderRadius: "8px" }}>
      {/* Alert Section */}
      {alert.open && (
        <Alert severity={alert.type} sx={{ marginBottom: 2 }}>
          {alert.message}
        </Alert>
      )}

      {/* Project Deliverables & Payment Terms Section */}
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: "#232323", fontWeight: "bold", marginBottom: 4 }}
      >
        Project Deliverables & Payment Terms
      </Typography>

      <Grid container spacing={4}>
        {/* Scope Items */}
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: "#232323", fontWeight: "bold" }}
          >
            Scope Items
          </Typography>
          <List>
            {scope.map((item) => (
              <ListItem
                key={item.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  borderBottom: "1px solid #ccc",
                  paddingBottom: 2,
                  marginBottom: 2,
                }}
              >
                {/* Editable Scope Item Text */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: 1,
                  }}
                >
                  {editingItem === item.id ? (
                    <TextField
                      fullWidth
                      value={item.text}
                      onChange={(e) => editScopeItem(item.id, e.target.value)}
                      size="small"
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        textDecoration: item.isComplete
                          ? "line-through"
                          : "none",
                      }}
                    >
                      {item.text}
                    </Typography>
                  )}
                  {/* Edit/Save Button */}
                  {editingItem === item.id ? (
                    <Tooltip title="Save the item">
                      <IconButton onClick={() => setEditingItem(null)}>
                        <SaveIcon sx={{ color: "#00796b" }} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Edit the item">
                      <IconButton onClick={() => setEditingItem(item.id)}>
                        <EditIcon sx={{ color: "#00796b" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {/* Delete Button */}
                  <Tooltip title="Delete the item">
                    <IconButton onClick={() => removeScopeItem(item.id)}>
                      <DeleteIcon sx={{ color: "#d32f2f" }} />
                    </IconButton>
                  </Tooltip>
                  {/* Completion Indicator */}
                  {item.isComplete && (
                    <Tooltip title="Completed">
                      <CheckCircleIcon
                        color="success"
                        sx={{ marginLeft: "auto" }}
                      />
                    </Tooltip>
                  )}
                </Box>

                {/* Support Documents for Scope Item */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    marginTop: 1,
                    flexWrap: "wrap",
                  }}
                >
                  {/* Removed the entire "Delivery Note:" line including label and upload button */}
                </Box>

                {/* Status Section */}
                <Box sx={{ marginTop: 2, width: "100%" }}>
                  {/* Delivery Note Sent Status */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      flexWrap: "wrap",
                    }}
                  >
                    {/* Reintroduced Typography label "Delivery Note Sent:" */}
                    <Typography variant="body2">Delivery Note Sent:</Typography>
                    {item.deliveryNoteSent ? (
                      <>
                        <CheckCircleIcon color="success" />
                        <Typography variant="body2">
                          {item.deliveryNoteSentDate}
                        </Typography>
                        <Typography variant="body2">
                          Via:{" "}
                          {item.deliveryNoteSentVia === "Custom"
                            ? item.deliveryNoteSentViaCustom
                            : item.deliveryNoteSentVia}
                        </Typography>
                        {/* Upload Delivery Note File after Sent */}
                        {item.supportDocuments.deliveryNote.file ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Typography variant="body2">
                              Delivery Note File:
                            </Typography>
                            <Tooltip title="Download Delivery Note File">
                              <IconButton
                                href={item.supportDocuments.deliveryNote.url}
                                download={
                                  item.supportDocuments.deliveryNote.file.name
                                }
                                target="_blank"
                                aria-label="Download Delivery Note File"
                              >
                                <CloudDownloadIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                            {/* Added Remove Button beside Download */}
                            <Tooltip title="Remove Delivery Note File">
                              <IconButton
                                onClick={() =>
                                  removeFile(item.id, "deliveryNote")
                                }
                              >
                                <CancelIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        ) : (
                          <Button
                            variant="outlined"
                            component="label"
                            startIcon={<AttachFileIcon />}
                            size="small"
                            sx={{ marginTop: 1 }}
                          >
                            Upload Delivery Note
                            <input
                              type="file"
                              hidden
                              onChange={(e) => {
                                if (e.target.files.length > 0) {
                                  handleFileUpload(
                                    item.id,
                                    "deliveryNote",
                                    e.target.files[0],
                                  );
                                }
                              }}
                            />
                          </Button>
                        )}
                      </>
                    ) : (
                      <Tooltip title="Mark Delivery Note as sent">
                        <IconButton
                          onClick={() => markDeliveryNoteSent(item.id)}
                        >
                          <SendIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>

                  {/* Delivery Note Sent Method Editing */}
                  {item.isEditingSentMethod && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        flexWrap: "wrap",
                        marginTop: 1,
                      }}
                    >
                      <FormControl size="small" sx={{ minWidth: 150 }}>
                        <InputLabel
                          id={`delivery-note-method-label-${item.id}`}
                        >
                          Method
                        </InputLabel>
                        <Select
                          labelId={`delivery-note-method-label-${item.id}`}
                          value={item.deliveryNoteSentVia}
                          label="Method"
                          onChange={(e) =>
                            handleDeliveryNoteMethodChange(
                              item.id,
                              e.target.value,
                            )
                          }
                        >
                          <MenuItem value="Email">Email</MenuItem>
                          <MenuItem value="Platform">Platform</MenuItem>
                          <MenuItem value="Custom">Custom</MenuItem>
                        </Select>
                      </FormControl>
                      {item.deliveryNoteSentVia === "Custom" && (
                        <TextField
                          label="Custom Method"
                          value={item.deliveryNoteSentViaCustom}
                          onChange={(e) =>
                            handleDeliveryNoteCustomMethodChange(
                              item.id,
                              e.target.value,
                            )
                          }
                          size="small"
                        />
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => saveSentMethod(item.id)}
                        size="small"
                      >
                        Save
                      </Button>
                    </Box>
                  )}

                  {/* Approved Status */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      flexWrap: "wrap",
                      marginTop: 1,
                    }}
                  >
                    <Typography variant="body2">Approved:</Typography>
                    {item.approved ? (
                      <>
                        <CheckCircleIcon color="success" />
                        <Typography variant="body2">
                          {item.approvedDate}
                        </Typography>
                        <Typography variant="body2">
                          Via:{" "}
                          {item.approvedVia === "Custom"
                            ? item.approvedViaCustom
                            : item.approvedVia}
                        </Typography>
                        {/* Upload Approval Document */}
                        {item.approvalFile.file ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Typography variant="body2">
                              Approval Document:
                            </Typography>
                            <Tooltip title="Download Approval Document">
                              <IconButton
                                href={item.approvalFile.url}
                                download={item.approvalFile.file.name}
                                target="_blank"
                                aria-label="Download Approval Document"
                              >
                                <CloudDownloadIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                            {/* Added Remove Button beside Download */}
                            <Tooltip title="Remove Approval Document">
                              <IconButton
                                onClick={() => removeApprovalFile(item.id)}
                              >
                                <CancelIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              marginTop: 1,
                            }}
                          >
                            <Typography variant="body2">
                              Approval Document:
                            </Typography>
                            <Button
                              variant="outlined"
                              component="label"
                              startIcon={<UploadFileIcon />}
                              size="small"
                            >
                              Upload Approval
                              <input
                                type="file"
                                hidden
                                onChange={(e) => {
                                  if (e.target.files.length > 0) {
                                    handleApprovalFileUpload(
                                      item.id,
                                      e.target.files[0],
                                    );
                                  }
                                }}
                              />
                            </Button>
                            <Tooltip
                              title="* Client-signed document required"
                              arrow
                            >
                              <Typography
                                variant="caption"
                                sx={{ color: "#d32f2f" }}
                              >
                                * Client-signed document required
                              </Typography>
                            </Tooltip>
                          </Box>
                        )}
                      </>
                    ) : (
                      <Tooltip title="Mark as approved">
                        <IconButton
                          onClick={() => markApproved(item.id)}
                          disabled={
                            !item.deliveryNoteSent || !item.deliveryNoteSentVia
                          }
                        >
                          <ThumbUpIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>

                  {/* Approval Method Editing */}
                  {item.isEditingApprovalMethod && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        flexWrap: "wrap",
                        marginTop: 1,
                      }}
                    >
                      <FormControl size="small" sx={{ minWidth: 150 }}>
                        <InputLabel id={`approval-method-label-${item.id}`}>
                          Method
                        </InputLabel>
                        <Select
                          labelId={`approval-method-label-${item.id}`}
                          value={item.approvedVia}
                          label="Method"
                          onChange={(e) =>
                            handleApprovalMethodChange(item.id, e.target.value)
                          }
                        >
                          <MenuItem value="Email">Email</MenuItem>
                          <MenuItem value="Platform">Platform</MenuItem>
                          <MenuItem value="Custom">Custom</MenuItem>
                        </Select>
                      </FormControl>
                      {item.approvedVia === "Custom" && (
                        <TextField
                          label="Custom Method"
                          value={item.approvedViaCustom}
                          onChange={(e) =>
                            handleApprovalCustomMethodChange(
                              item.id,
                              e.target.value,
                            )
                          }
                          size="small"
                        />
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => saveApprovalMethod(item.id)}
                        size="small"
                      >
                        Save
                      </Button>
                    </Box>
                  )}
                </Box>
              </ListItem>
            ))}
          </List>
          {/* Add New Scope Item */}
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <TextField
              fullWidth
              label="New scope item"
              value={newScopeItem}
              onChange={(e) => setNewScopeItem(e.target.value)}
              variant="outlined"
              size="small"
            />
            <Tooltip title="Add a new scope item">
              <IconButton
                color="primary"
                onClick={addScopeItem}
                sx={{
                  backgroundColor: "#00a99d",
                  color: "#fff",
                  borderRadius: "50%",
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: "#00796b",
                  },
                }}
              >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>

        {/* Payment Terms */}
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: "#232323", fontWeight: "bold" }}
          >
            Payment Terms
          </Typography>
          <List>
            {paymentTerms.map((term) => (
              <ListItem
                key={term.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  borderBottom: "1px solid #ccc",
                  paddingBottom: 2,
                  marginBottom: 2,
                }}
              >
                {/* Editable Payment Term Text */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: 1,
                  }}
                >
                  {editingTerm === term.id ? (
                    <TextField
                      fullWidth
                      value={term.text}
                      onChange={(e) => editPaymentTerm(term.id, e.target.value)}
                      size="small"
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        textDecoration: term.isComplete
                          ? "line-through"
                          : "none",
                      }}
                    >
                      {term.text}
                    </Typography>
                  )}
                  {/* Edit/Save Button */}
                  {editingTerm === term.id ? (
                    <Tooltip title="Save the payment term">
                      <IconButton onClick={() => setEditingTerm(null)}>
                        <SaveIcon sx={{ color: "#00796b" }} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Edit the payment term">
                      <IconButton onClick={() => setEditingTerm(term.id)}>
                        <EditIcon sx={{ color: "#00796b" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {/* Delete Button */}
                  <Tooltip title="Delete the payment term">
                    <IconButton onClick={() => removePaymentTerm(term.id)}>
                      <DeleteIcon sx={{ color: "#d32f2f" }} />
                    </IconButton>
                  </Tooltip>
                  {/* Completion Indicator */}
                  {term.isComplete && (
                    <Tooltip title="Completed">
                      <CheckCircleIcon
                        color="success"
                        sx={{ marginLeft: "auto" }}
                      />
                    </Tooltip>
                  )}
                </Box>

                {/* Invoice Upload */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    marginTop: 1,
                    flexWrap: "wrap",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography variant="body2">Invoice:</Typography>
                    {term.invoice && term.invoice.file ? (
                      <>
                        <Tooltip title="Download Invoice">
                          <IconButton
                            href={term.invoice.url}
                            download={term.invoice.file.name}
                            target="_blank"
                            aria-label="Download Invoice"
                          >
                            <CloudDownloadIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Remove Invoice">
                          <IconButton onClick={() => removeInvoice(term.id)}>
                            <CancelIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      <Button
                        variant="outlined"
                        component="label"
                        startIcon={<AttachFileIcon />}
                        size="small"
                      >
                        Upload
                        <input
                          type="file"
                          hidden
                          onChange={(e) => {
                            if (e.target.files.length > 0) {
                              handleInvoiceUpload(term.id, e.target.files[0]);
                            }
                          }}
                        />
                      </Button>
                    )}
                  </Box>
                  <Tooltip
                    title="Make sure to add the client-signed document"
                    arrow
                  >
                    <Typography variant="caption" sx={{ color: "#d32f2f" }}>
                      * Client-signed document required
                    </Typography>
                  </Tooltip>
                </Box>
              </ListItem>
            ))}
          </List>
          {/* Add New Payment Term */}
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <TextField
              fullWidth
              label="New payment term"
              value={newPaymentTerm}
              onChange={(e) => setNewPaymentTerm(e.target.value)}
              variant="outlined"
              size="small"
            />
            <Tooltip title="Add a new payment term">
              <IconButton
                color="primary"
                onClick={addPaymentTerm}
                sx={{
                  backgroundColor: "#00a99d",
                  color: "#fff",
                  borderRadius: "50%",
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: "#00796b",
                  },
                }}
              >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>

      {/* Change Requests Section */}
      <Divider sx={{ my: 4 }} />
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: "#232323", fontWeight: "bold" }}
      >
        Change Requests
      </Typography>
      <List>
        {changeRequests.map((item) => (
          <ListItem
            key={item.id}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              borderBottom: "1px solid #ccc",
              paddingBottom: 2,
              marginBottom: 2,
            }}
          >
            {/* Editable Change Request Text */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                gap: 1,
              }}
            >
              {editingCRItem === item.id ? (
                <TextField
                  fullWidth
                  value={item.text}
                  onChange={(e) => editCRItem(item.id, e.target.value)}
                  size="small"
                />
              ) : (
                <Typography
                  variant="body1"
                  sx={{
                    textDecoration: item.isComplete ? "line-through" : "none",
                  }}
                >
                  {item.text}
                </Typography>
              )}
              {/* Edit/Save Button */}
              {editingCRItem === item.id ? (
                <Tooltip title="Save the change request">
                  <IconButton onClick={() => setEditingCRItem(null)}>
                    <SaveIcon sx={{ color: "#00796b" }} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Edit the change request">
                  <IconButton onClick={() => setEditingCRItem(item.id)}>
                    <EditIcon sx={{ color: "#00796b" }} />
                  </IconButton>
                </Tooltip>
              )}
              {/* Delete Button */}
              <Tooltip title="Delete the change request">
                <IconButton onClick={() => removeCRItem(item.id)}>
                  <DeleteIcon sx={{ color: "#d32f2f" }} />
                </IconButton>
              </Tooltip>
              {/* Completion Checkbox */}
              <Checkbox
                checked={item.isComplete}
                onChange={() => toggleCompleteCRItem(item.id)}
                color="success"
              />
            </Box>

            {/* Support Documents for Change Request */}
            <Box
              sx={{ display: "flex", gap: 2, marginTop: 1, flexWrap: "wrap" }}
            >
              {/* PO Document */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography variant="body2">PO:</Typography>
                {item.supportDocuments.po.file ? (
                  <>
                    <Tooltip title="Download PO">
                      <IconButton
                        href={item.supportDocuments.po.url}
                        download={item.supportDocuments.po.file.name}
                        target="_blank"
                        aria-label="Download PO"
                      >
                        <CloudDownloadIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove PO">
                      <IconButton onClick={() => removeCRFile(item.id, "po")}>
                        <CancelIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    component="label"
                    startIcon={<AttachFileIcon />}
                    size="small"
                  >
                    Upload
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        if (e.target.files.length > 0) {
                          handleCRFileUpload(item.id, "po", e.target.files[0]);
                        }
                      }}
                    />
                  </Button>
                )}
              </Box>

              {/* Delivery Note Document */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography variant="body2">Delivery Note:</Typography>
                {item.supportDocuments.deliveryNote.file ? (
                  <>
                    <Tooltip title="Download Delivery Note">
                      <IconButton
                        href={item.supportDocuments.deliveryNote.url}
                        download={item.supportDocuments.deliveryNote.file.name}
                        target="_blank"
                        aria-label="Download Delivery Note"
                      >
                        <CloudDownloadIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove Delivery Note">
                      <IconButton
                        onClick={() => removeCRFile(item.id, "deliveryNote")}
                      >
                        <CancelIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    component="label"
                    startIcon={<AttachFileIcon />}
                    size="small"
                  >
                    Upload
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        if (e.target.files.length > 0) {
                          handleCRFileUpload(
                            item.id,
                            "deliveryNote",
                            e.target.files[0],
                          );
                        }
                      }}
                    />
                  </Button>
                )}
              </Box>

              {/* Invoice Document */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography variant="body2">Invoice:</Typography>
                {item.supportDocuments.invoice.file ? (
                  <>
                    <Tooltip title="Download Invoice">
                      <IconButton
                        href={item.supportDocuments.invoice.url}
                        download={item.supportDocuments.invoice.file.name}
                        target="_blank"
                        aria-label="Download Invoice"
                      >
                        <CloudDownloadIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove Invoice">
                      <IconButton
                        onClick={() => removeCRFile(item.id, "invoice")}
                      >
                        <CancelIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    component="label"
                    startIcon={<AttachFileIcon />}
                    size="small"
                  >
                    Upload
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        if (e.target.files.length > 0) {
                          handleCRFileUpload(
                            item.id,
                            "invoice",
                            e.target.files[0],
                          );
                        }
                      }}
                    />
                  </Button>
                )}
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
      {/* Add New Change Request */}
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <TextField
          fullWidth
          label="New change request"
          value={newCRItem}
          onChange={(e) => setNewCRItem(e.target.value)}
          variant="outlined"
          size="small"
        />
        <Tooltip title="Add a new change request">
          <IconButton
            color="primary"
            onClick={addCRItem}
            sx={{
              backgroundColor: "#00a99d",
              color: "#fff",
              borderRadius: "50%",
              padding: "10px",
              "&:hover": {
                backgroundColor: "#00796b",
              },
            }}
          >
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Paper>
  );
};

export default ScopeList;
