// src/contexts/AuthContext.jsx

import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '@/firebase'; // Updated to use alias '@'

// Create the AuthContext
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);

// AuthProvider component to wrap around the app
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Holds the current user
  const [authLoading, setAuthLoading] = useState(true); // Loading state for auth status
  const [authError, setAuthError] = useState(null); // Optionally store any auth error

  useEffect(() => {
    // Set up Firebase auth state listener
    const unsubscribe = onAuthStateChanged(
      auth,
      (currentUser) => {
        setUser(currentUser); // Set the user if authenticated
        setAuthLoading(false); // Set loading to false after auth state is determined
        setAuthError(null); // Clear any previous errors
      },
      (error) => {
        console.error('Authentication error:', error);
        setAuthError(error); // Optionally track auth errors
        setUser(null); // Clear user state in case of an error
        setAuthLoading(false); // Stop loading even if there is an error
      },
    );

    // Clean up the listener on unmount
    return () => unsubscribe();
  }, []);

  // Function to log out the user
  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null); // Clear user state after successful logout
    } catch (error) {
      console.error('Error logging out:', error);
      setAuthError(error); // Optionally handle logout error
    }
  };

  const value = {
    user,
    authLoading,
    authError, // Expose auth errors (optional)
    logout, // Provide logout function for easy access
  };

  return (
    <AuthContext.Provider value={value}>
      {!authLoading && children}
    </AuthContext.Provider>
  );
};
