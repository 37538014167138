// src/components/Loading.jsx

import React from "react";
import { CircularProgress, Box } from "@mui/material";

/**
 * Loading Component
 *
 * Displays a centered loading spinner.
 */
const Loading = () => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f5f5f5",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Loading;
