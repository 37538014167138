// src/utils/dateUtils.js

/**
 * Checks if a given due date is within the next 24 hours.
 * @param {string} dueDate - The due date in ISO string format.
 * @returns {boolean} - True if due within 24 hours, else false.
 */
export const isDueSoon = (dueDate) => {
  if (!dueDate) return false;
  const now = new Date();
  const due = new Date(dueDate);
  const timeDifference = due - now;
  const oneDay = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  return timeDifference > 0 && timeDifference <= oneDay;
};

/**
 * Formats a date string into Day-Month-Year format.
 * @param {string} dateStr - The date string in ISO format.
 * @returns {string} - Formatted date string.
 */
export const formatDate = (dateStr) => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
