// src/constants/kanban.js

export const initialKanbanColumns = {
  todo: {
    id: 'todo',
    title: "To Do",
    tasks: [],
  },
  inProgress: {
    id: 'inProgress',
    title: "In Progress",
    tasks: [],
  },
  done: {
    id: 'done',
    title: "Done",
    tasks: [],
  },
};
