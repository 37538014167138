import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Paper,
  CircularProgress,
  FormControl,
  InputLabel,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { auth, db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useNavigate } from "react-router-dom";

const AIAssistant = ({ isArabic }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const chatEndRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      if (!auth.currentUser) return;
      const userProjectsRef = collection(
        db,
        "users",
        auth.currentUser.uid,
        "projects"
      );
      const snapshot = await getDocs(userProjectsRef);
      const projectsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setProjects(projectsData);
    };

    fetchProjects();
  }, []);

  const handleProjectSelect = (event) => {
    setSelectedProjects(event.target.value);
  };

  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };

  const handleSubmit = async () => {
    if (!userInput.trim()) return;
    setLoading(true);

    const newConversation = [...conversation, { sender: "user", text: userInput }];
    setConversation(newConversation);
    setUserInput("");

    try {
      const user = auth.currentUser;
      if (!user) throw new Error("No authenticated user.");

      const idToken = await user.getIdToken();

      // Prepare FormData for file upload
      const formData = new FormData();
      formData.append("prompt", userInput);
      selectedProjects.forEach((projectId) => formData.append("projectIds[]", projectId));
      selectedFiles.forEach((fileWrapper) => formData.append("files", fileWrapper.file));

      // Make a POST request to the backend server with Authorization header
      const response = await axios.post(
        "https://5b24f64f-9dc1-486a-9efb-82d3e35835c7-00-1bx9hicy49isj.picard.repl.co/api/ai-assistant",
        formData,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 404) {
        throw new Error(isArabic ? "الصفحة غير موجودة." : "Page not found.");
      }

      if (response.data && response.data.text) {
        setConversation([...newConversation, { sender: "assistant", text: response.data.text }]);
      } else {
        setConversation([...newConversation, { sender: "assistant", text: "No response received from the assistant." }]);
      }
    } catch (error) {
      console.error("❌ Error communicating with AI Assistant:", error);
      let errorMessage = isArabic
        ? "حدث خطأ أثناء معالجة طلبك."
        : "An error occurred while processing your request.";

      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      } else if (error.message) {
        errorMessage = error.message;
      }

      setConversation([...newConversation, { sender: "assistant", text: errorMessage }]);
    } finally {
      setLoading(false);
      scrollToBottom();
    }
  };

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleBack = () => {
    navigate("/app");
  };

  const predefinedReports = [
    {
      id: 1,
      title: isArabic ? "تقرير ملخص المشروع" : "Project Summary Report",
      prompt: isArabic
        ? "قم بإنشاء تقرير ملخص لمشروعي الحالي."
        : "Generate a summary report for my current project.",
    },
    {
      id: 2,
      title: isArabic ? "تقرير تحليل المخاطر" : "Risk Analysis Report",
      prompt: isArabic
        ? "قم بإنشاء تقرير تحليل المخاطر لمشروعي الحالي."
        : "Generate a risk analysis report for my current project.",
    },
  ];

  const handleFileChange = (event) => {
    const files = event.target.files;
    const filesArray = Array.from(files).map((file) => ({
      file,
      url: URL.createObjectURL(file),
    }));
    setSelectedFiles(filesArray);
  };

  return (
    <Box
      sx={{
        p: 3,
        maxWidth: 800,
        margin: "0 auto",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <IconButton onClick={handleBack} aria-label="back">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={{ ml: 1 }}>
          {isArabic ? "مساعد المشروع الذكي" : "AI Project Assistant"}
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          {isArabic ? "تقارير مُعرفة مسبقًا" : "Predefined Reports"}
        </Typography>
        <List>
          {predefinedReports.map((report) => (
            <ListItem button key={report.id} onClick={() => setUserInput(report.prompt)}>
              <ListItemText primary={report.title} />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box sx={{ mb: 3 }}>
        <FormControl fullWidth>
          <InputLabel id="project-select-label">
            {isArabic ? "اختر المشاريع (اختياري)" : "Select Projects (Optional)"}
          </InputLabel>
          <Select
            labelId="project-select-label"
            multiple
            value={selectedProjects}
            onChange={handleProjectSelect}
            label={isArabic ? "اختر المشاريع (اختياري)" : "Select Projects (Optional)"}
            renderValue={(selected) =>
              projects
                .filter((project) => selected.includes(project.id))
                .map((project) => project.name)
                .join(", ")
            }
          >
            {projects.map((project) => (
              <MenuItem key={project.id} value={project.id}>
                {project.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {isArabic
              ? "اختر المشاريع التي ترغب في تضمينها في التقرير. يمكنك تخطي هذا الاختيار لبدء محادثة بدون مشاريع محددة."
              : "Select the projects you want to include in the report. You can skip this to start a chat without specific projects."}
          </FormHelperText>
        </FormControl>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          {isArabic ? "تحميل المستندات (اختياري)" : "Upload Documents (Optional)"}
        </Typography>
        <input
          accept=".pdf,.doc,.docx,.txt"
          style={{ display: "none" }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="raised-button-file">
          <Button
            variant="contained"
            component="span"
            startIcon={<UploadFileIcon />}
          >
            {isArabic ? "اختيار المستندات" : "Choose Files"}
          </Button>
        </label>
        {selectedFiles.length > 0 && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            {selectedFiles.length} {isArabic ? "ملف" : "files"} selected
          </Typography>
        )}
      </Box>

      <Box sx={{ display: "flex", gap: 1 }}>
        <TextField
          fullWidth
          multiline
          rows={2}
          value={userInput}
          onChange={handleUserInput}
          variant="outlined"
          placeholder={
            isArabic
              ? "اكتب سؤالك أو التقرير الذي تحتاجه..."
              : "Type your question or the report you need..."
          }
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSubmit();
            }
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading
            ? isArabic
              ? "جاري المعالجة..."
              : "Processing..."
            : isArabic
            ? "إرسال"
            : "Send"}
        </Button>
      </Box>

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress />
        </Box>
      )}

      <Box sx={{ flexGrow: 1, overflowY: "auto", mb: 2 }}>
        {conversation.map((msg, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: msg.sender === "user" ? "flex-end" : "flex-start",
              mb: 1,
            }}
          >
            <Paper
              sx={{
                p: 2,
                maxWidth: "80%",
                backgroundColor: msg.sender === "user" ? "#DCF8C6" : "#FFFFFF",
                borderRadius: "10px",
              }}
            >
              <Typography variant="body1">{msg.text}</Typography>
            </Paper>
          </Box>
        ))}
        <div ref={chatEndRef} />
      </Box>
    </Box>
  );
};

export default AIAssistant;
