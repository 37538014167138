// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#222831", // Primary color
    },
    secondary: {
      main: "#00ADB5", // Secondary color
    },
    background: {
      default: "#EEEEEE", // Default background color
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    h1: { fontSize: "2.5rem", fontWeight: 500 },
    h2: { fontSize: "2rem", fontWeight: 500 },
    body1: { fontSize: "1rem" },
  },
});

export default theme;
