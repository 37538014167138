// src/components/KanbanPage.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  Alert,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import { isDueSoon, formatDate } from '../utils/dateUtils';
import { db, auth } from '../firebase';
import { collection, addDoc, getDocs, query, where, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import ArchivedTasks from './ArchivedTasks';
import { v4 as uuidv4 } from 'uuid';

const priorityColors = {
  low: '#8BC34A',
  medium: '#FFC107',
  high: '#F44336',
};

const KanbanPage = ({ kanbanData, updateKanbanData, selectedProject, updateProject }) => {
  const [columns, setColumns] = useState(kanbanData || {
    todo: { id: 'todo', title: 'To Do', tasks: [] },
    inProgress: { id: 'inProgress', title: 'In Progress', tasks: [] },
    done: { id: 'done', title: 'Done', tasks: [] },
  });

  const [newTask, setNewTask] = useState({ content: '', description: '', priority: 'medium', dueDate: '' });
  const [editingTask, setEditingTask] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filter, setFilter] = useState({ priority: 'all', dueDate: 'all' });
  const [isArchivedAccordionOpen, setIsArchivedAccordionOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    setColumns(kanbanData || {
      todo: { id: 'todo', title: 'To Do', tasks: [] },
      inProgress: { id: 'inProgress', title: 'In Progress', tasks: [] },
      done: { id: 'done', title: 'Done', tasks: [] },
    });
  }, [kanbanData]);

  // Function to show snackbar notifications
  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  // Close snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleDragStart = (e, taskId, sourceColumnId) => {
    e.dataTransfer.setData('taskId', taskId);
    e.dataTransfer.setData('sourceColumnId', sourceColumnId);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e, targetColumnId) => {
    e.preventDefault();
    const taskId = e.dataTransfer.getData('taskId');
    const sourceColumnId = e.dataTransfer.getData('sourceColumnId');

    if (sourceColumnId !== targetColumnId) {
      const sourceColumn = columns[sourceColumnId];
      const targetColumn = columns[targetColumnId];
      const taskToMove = sourceColumn.tasks.find(task => task.id === taskId);

      if (!taskToMove) {
        console.error("Task not found in source column");
        showSnackbar("Task not found.", "error");
        return;
      }

      // Update Firestore: Change the columnId of the task
      try {
        const tasksRef = collection(
          db,
          "users",
          auth.currentUser.uid,
          "projects",
          selectedProject.id,
          "tasks"
        );

        const q = query(tasksRef, where("id", "==", taskId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const taskDoc = querySnapshot.docs[0];
          await updateDoc(doc(db, taskDoc.ref.path), { columnId: targetColumnId });
        } else {
          console.error("Task document not found in Firestore");
          showSnackbar("Task not found in Firestore.", "error");
          return;
        }

        // Update local state
        const updatedSourceTasks = sourceColumn.tasks.filter(task => task.id !== taskId);
        const updatedTargetTasks = [...(targetColumn.tasks || []), { ...taskToMove, columnId: targetColumnId }];

        const updatedColumns = {
          ...columns,
          [sourceColumnId]: {
            ...sourceColumn,
            tasks: updatedSourceTasks,
          },
          [targetColumnId]: {
            ...targetColumn,
            tasks: updatedTargetTasks,
          },
        };
        setColumns(updatedColumns);
        updateKanbanData(updatedColumns);
        showSnackbar("Task moved successfully.");
      } catch (error) {
        console.error("Error moving task:", error);
        showSnackbar("Failed to move task.", "error");
      }
    }
  };

  const addTask = async () => {
    if (newTask.content.trim() === '') {
      showSnackbar("Task title cannot be empty.", "warning");
      return;
    }

    const task = {
      id: uuidv4(), // Use UUID for unique IDs
      ...newTask,
      name: newTask.content, // Ensure name is defined
      columnId: 'todo', // Assign to 'To Do' column
      createdAt: new Date().toISOString(),
      dueDate: newTask.dueDate || null,
      userId: auth.currentUser.uid, // Associate task with user
      projectId: selectedProject.id, // Associate task with project
    };

    try {
      const tasksRef = collection(
        db,
        "users",
        auth.currentUser.uid,
        "projects",
        selectedProject.id,
        "tasks"
      );
      await addDoc(tasksRef, task);

      // Update local state
      const updatedColumns = {
        ...columns,
        todo: {
          ...columns.todo,
          tasks: [...columns.todo.tasks, task],
        },
      };
      setColumns(updatedColumns);
      updateKanbanData(updatedColumns);
      setNewTask({ content: '', description: '', priority: 'medium', dueDate: '' });
      showSnackbar("Task added successfully.");
    } catch (error) {
      console.error("Error adding task:", error);
      showSnackbar("Failed to add task.", "error");
    }
  };

  const deleteTask = async (columnId, taskId) => {
    try {
      const tasksRef = collection(
        db,
        "users",
        auth.currentUser.uid,
        "projects",
        selectedProject.id,
        "tasks"
      );
      const q = query(tasksRef, where("id", "==", taskId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const taskDoc = querySnapshot.docs[0];
        await deleteDoc(doc(db, taskDoc.ref.path));

        // Update local state
        const updatedTasks = columns[columnId].tasks.filter(task => task.id !== taskId);
        const updatedColumns = {
          ...columns,
          [columnId]: {
            ...columns[columnId],
            tasks: updatedTasks,
          },
        };
        setColumns(updatedColumns);
        updateKanbanData(updatedColumns);
        showSnackbar("Task deleted successfully.");
      } else {
        console.error("Task document not found in Firestore");
        showSnackbar("Task not found in Firestore.", "error");
      }
    } catch (error) {
      console.error("Error deleting task:", error);
      showSnackbar("Failed to delete task.", "error");
    }
  };

  const openEditDialog = (task, columnId) => {
    setEditingTask({ ...task, columnId });
    setIsDialogOpen(true);
  };

  const closeEditDialog = () => {
    setEditingTask(null);
    setIsDialogOpen(false);
  };

  const saveEditedTask = async () => {
    if (!editingTask) return;

    if (editingTask.content.trim() === '') {
      showSnackbar("Task title cannot be empty.", "warning");
      return;
    }

    const { columnId, ...updatedTask } = editingTask;

    try {
      const tasksRef = collection(
        db,
        "users",
        auth.currentUser.uid,
        "projects",
        selectedProject.id,
        "tasks"
      );
      const q = query(tasksRef, where("id", "==", updatedTask.id));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const taskDoc = querySnapshot.docs[0];
        await updateDoc(doc(db, taskDoc.ref.path), updatedTask);

        // Update local state
        const updatedTasks = columns[columnId].tasks.map(task =>
          task.id === updatedTask.id ? { ...task, ...updatedTask } : task
        );

        const updatedColumns = {
          ...columns,
          [columnId]: {
            ...columns[columnId],
            tasks: updatedTasks,
          },
        };
        setColumns(updatedColumns);
        updateKanbanData(updatedColumns);
        closeEditDialog();
        showSnackbar("Task updated successfully.");
      } else {
        console.error("Task document not found in Firestore");
        showSnackbar("Task not found in Firestore.", "error");
      }
    } catch (error) {
      console.error("Error updating task:", error);
      showSnackbar("Failed to update task.", "error");
    }
  };

  const archiveTask = async (task, columnId) => {
    try {
      // Remove task from current column in Firestore
      const tasksRef = collection(
        db,
        "users",
        auth.currentUser.uid,
        "projects",
        selectedProject.id,
        "tasks"
      );
      const q = query(tasksRef, where("id", "==", task.id));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const taskDoc = querySnapshot.docs[0];
        await deleteDoc(doc(db, taskDoc.ref.path));

        // Add task to archivedTasks in Firestore
        const archivedTasksRef = collection(
          db,
          "users",
          auth.currentUser.uid,
          "projects",
          selectedProject.id,
          "archivedTasks"
        );
        await addDoc(archivedTasksRef, task);

        // Update local state
        const updatedSourceTasks = columns[columnId].tasks.filter(t => t.id !== task.id);
        const updatedColumns = {
          ...columns,
          [columnId]: {
            ...columns[columnId],
            tasks: updatedSourceTasks,
          },
        };
        setColumns(updatedColumns);
        updateKanbanData(updatedColumns);
        showSnackbar("Task archived successfully.");
      } else {
        console.error("Task document not found in Firestore");
        showSnackbar("Task not found in Firestore.", "error");
      }
    } catch (error) {
      console.error("Error archiving task:", error);
      showSnackbar("Failed to archive task.", "error");
    }
  };

  const filteredTasks = (tasks = []) => {
    let filtered = tasks;
    if (filter.priority !== 'all') {
      filtered = filtered.filter(task => task.priority === filter.priority);
    }
    if (filter.dueDate !== 'all') {
      const now = new Date();
      if (filter.dueDate === 'overdue') {
        filtered = filtered.filter(task => task.dueDate && new Date(task.dueDate) < now);
      } else if (filter.dueDate === 'today') {
        filtered = filtered.filter(task => {
          if (!task.dueDate) return false;
          const due = new Date(task.dueDate);
          return due.toDateString() === now.toDateString();
        });
      } else if (filter.dueDate === 'upcoming') {
        filtered = filtered.filter(task => task.dueDate && new Date(task.dueDate) > now);
      }
    }
    return filtered;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        p: 2,
        fontFamily: 'Arial, sans-serif',
      }}
    >
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Typography variant="h5" gutterBottom>
        Kanban Board
      </Typography>

      {/* Task Creation Section */}
      <Stack direction="row" spacing={2} sx={{ mb: 2, flexWrap: 'wrap' }}>
        <TextField
          value={newTask.content}
          onChange={(e) => setNewTask({ ...newTask, content: e.target.value })}
          placeholder="Enter task title"
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: 150, fontFamily: 'Arial, sans-serif' }}
        />
        <TextField
          value={newTask.description}
          onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
          placeholder="Enter task description"
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: 150, fontFamily: 'Arial, sans-serif' }}
        />
        <FormControl size="small" sx={{ minWidth: 120, fontFamily: 'Arial, sans-serif' }}>
          <InputLabel>Priority</InputLabel>
          <Select
            value={newTask.priority}
            onChange={(e) => setNewTask({ ...newTask, priority: e.target.value })}
            label="Priority"
            sx={{ fontFamily: 'Arial, sans-serif' }}
          >
            <MenuItem value="low">Low</MenuItem>
            <MenuItem value="medium">Medium</MenuItem>
            <MenuItem value="high">High</MenuItem>
          </Select>
        </FormControl>
        <TextField
          type="date"
          value={newTask.dueDate}
          onChange={(e) => setNewTask({ ...newTask, dueDate: e.target.value })}
          label="Due Date"
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          sx={{ minWidth: 150, fontFamily: 'Arial, sans-serif' }}
        />
        <Button variant="contained" onClick={addTask} sx={{ fontFamily: 'Arial, sans-serif' }}>
          Add Task
        </Button>
        <Button
          variant="outlined"
          onClick={() => setIsArchivedAccordionOpen(!isArchivedAccordionOpen)}
          sx={{ fontFamily: 'Arial, sans-serif' }}
        >
          {isArchivedAccordionOpen ? 'Hide Archived Tasks' : 'View Archived Tasks'}
        </Button>
      </Stack>

      {/* Task Filters */}
      <Stack direction="row" spacing={2} sx={{ mb: 2, flexWrap: 'wrap' }}>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Filter Priority</InputLabel>
          <Select
            value={filter.priority}
            onChange={(e) => setFilter({ ...filter, priority: e.target.value })}
            label="Filter Priority"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="low">Low</MenuItem>
            <MenuItem value="medium">Medium</MenuItem>
            <MenuItem value="high">High</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 150 }}>
          <InputLabel>Filter Due Date</InputLabel>
          <Select
            value={filter.dueDate}
            onChange={(e) => setFilter({ ...filter, dueDate: e.target.value })}
            label="Filter Due Date"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="overdue">Overdue</MenuItem>
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="upcoming">Upcoming</MenuItem>
          </Select>
        </FormControl>
        <Button variant="text" onClick={() => setFilter({ priority: 'all', dueDate: 'all' })}>
          Clear Filters
        </Button>
      </Stack>

      {/* Kanban Columns */}
      <Grid container spacing={2}>
        {['todo', 'inProgress', 'done'].map((columnId) => {
          const column = columns[columnId];
          const tasksToDisplay = filteredTasks(column.tasks || []); // Ensure tasks exist
          return (
            <Grid item xs={12} sm={4} key={column.id}>
              <Paper
                sx={{
                  p: 2,
                  bgcolor: 'grey.100',
                  minHeight: 400,
                  fontFamily: 'Arial, sans-serif',
                }}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, column.id)}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {column.title}
                </Typography>
                {tasksToDisplay.map((task) => (
                  <Card
                    key={task.id}
                    sx={{
                      mb: 1,
                      cursor: 'move',
                      borderLeft: `5px solid ${priorityColors[task.priority]}`,
                      backgroundColor: isDueSoon(task.dueDate) ? '#FFCDD2' : 'white',
                      fontFamily: 'Arial, sans-serif',
                    }}
                    draggable
                    onDragStart={(e) => handleDragStart(e, task.id, column.id)}
                  >
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle1">
                          {task.content}
                        </Typography>
                        <Box>
                          <IconButton size="small" onClick={() => openEditDialog(task, column.id)}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" onClick={() => deleteTask(column.id, task.id)}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                          {column.id === 'done' && (
                            <IconButton size="small" onClick={() => archiveTask(task, column.id)}>
                              <ArchiveIcon fontSize="small" />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        {task.description}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        Created: {formatDate(task.createdAt)}
                      </Typography>
                      {task.dueDate && (
                        <Typography variant="caption" color="error">
                          Due: {formatDate(task.dueDate)}
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                ))}
              </Paper>
            </Grid>
          );
        })}
      </Grid>

      {/* Archived Tasks Accordion */}
      <Accordion expanded={isArchivedAccordionOpen} onChange={() => setIsArchivedAccordionOpen(!isArchivedAccordionOpen)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Archived Tasks</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ArchivedTasks projectId={selectedProject.id} updateProject={updateProject} />
        </AccordionDetails>
      </Accordion>

      {/* Edit Task Dialog */}
      <Dialog open={isDialogOpen} onClose={closeEditDialog}>
        <DialogTitle>Edit Task</DialogTitle>
        <DialogContent>
          {editingTask && (
            <>
              <TextField
                autoFocus
                margin="dense"
                label="Task Title"
                type="text"
                fullWidth
                value={editingTask.content}
                onChange={(e) => setEditingTask({ ...editingTask, content: e.target.value })}
                sx={{ fontFamily: 'Arial, sans-serif' }}
              />
              <TextField
                margin="dense"
                label="Task Description"
                type="text"
                fullWidth
                value={editingTask.description}
                onChange={(e) => setEditingTask({ ...editingTask, description: e.target.value })}
                sx={{ fontFamily: 'Arial, sans-serif' }}
              />
              <FormControl fullWidth margin="dense" sx={{ fontFamily: 'Arial, sans-serif' }}>
                <InputLabel>Priority</InputLabel>
                <Select
                  value={editingTask.priority}
                  onChange={(e) => setEditingTask({ ...editingTask, priority: e.target.value })}
                  label="Priority"
                >
                  <MenuItem value="low">Low</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="high">High</MenuItem>
                </Select>
              </FormControl>
              <TextField
                type="date"
                margin="dense"
                label="Due Date"
                fullWidth
                value={editingTask.dueDate || ''}
                onChange={(e) => setEditingTask({ ...editingTask, dueDate: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ fontFamily: 'Arial, sans-serif' }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditDialog} sx={{ fontFamily: 'Arial, sans-serif' }}>Cancel</Button>
          <Button onClick={saveEditedTask} sx={{ fontFamily: 'Arial, sans-serif' }}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default KanbanPage;
